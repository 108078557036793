<template>
  <div class="property-wrap">
    <!-- banner 开始 -->
    <div class="page-banner" :style="{background: '#1D212E url('+require('@/assets/images/property/banner.jpg')+') no-repeat', backgroundSize: 'cover'}">
      <div class="banner-mask"></div>
      <div class="valid-area">
        <div class="align-center">
          <p class="page-banner-title">智慧物业解决方案</p>
          <p class="page-banner-description">解决日益繁重的抄表难题，提高抄收效率，</p>
          <p class="page-banner-description">有图为证，避免账单纠纷</p>
        </div>
      </div>
    </div>
    <!-- banner 结束 -->
    <!-- 智慧水务 开始 -->
    <div class="part part-light-bg letter-space">
      <div class="valid-area">
        <!-- 标题 -->
        <p class="part-title">智慧物业</p>
        <!-- 装饰线 -->
        <p class="decoration-line"></p>
        <!-- 主内容区 -->
        <div class="main-content layout-flex reslove-case-main" v-if="widOver768">
          <div class="resolve-case align-right">
            <div class="resolve-case-item">
              <p class="resolve-case-title">安全</p>
              <p class="resolve-case-description">掌握设备用能趋势，合理调度控制</p>
              <p class="resolve-case-description">发现故障仪表，排查安全隐患</p>
            </div>
            <div class="resolve-case-item">
              <p class="resolve-case-title">省力</p>
              <p class="resolve-case-description">解决人工现场抄表强度大、误差大、不实时、效率低等问题</p>
            </div>
          </div>
          <div class="resolve-case-pic">
            <img src="@/assets/images/property/property.png">
          </div>
          <div class="resolve-case align-left">
            <div class="resolve-case-item">
              <p class="resolve-case-title">降本</p>
              <p class="resolve-case-description">快速实现机械表远程数据采集</p>
              <p class="resolve-case-description">实现用能精细化管理，降低经济损失</p>
            </div>
            <div class="resolve-case-item">
              <p class="resolve-case-title">省心</p>
              <p class="resolve-case-description">水电气用量一目了然，仪表照片留底，减少账单纠纷</p>
              <p class="resolve-case-description">改变租赁双方抄表模式，建立客户信任关系</p>
            </div>
          </div>
        </div>
        <div class="main-content reslove-case-main-768" v-if="!widOver768">
          <div class="reslove-img">
            <img src="@/assets/images/property/property-res.png">
          </div>
          <div class="resolve-case-768-item">
            <p class="resolve-case-768-title">安全</p>
            <p class="resolve-case-768-description">发现故障仪表，排查安全隐患</p>
            <p class="resolve-case-768-description">掌握设备用能趋势，合理调度控制</p>
          </div>
          <div class="resolve-case-768-item">
            <p class="resolve-case-768-title">降本</p>
            <p class="resolve-case-768-description">快速实现机械表远程数据采集</p>
            <p class="resolve-case-768-description">实现用能精细化管理，降低经济损失</p>
          </div>
          <div class="resolve-case-768-item">
            <p class="resolve-case-768-title">省力</p>
            <p class="resolve-case-768-description">解决人工现场抄表强度大、</p>
            <p class="resolve-case-768-description">误差大、不实时、效率低等问题</p>
          </div>
          <div class="resolve-case-768-item">
            <p class="resolve-case-768-title">省心</p>
            <p class="resolve-case-768-description">改变租赁双方抄表模式，建立客户信任关系</p>
            <p class="resolve-case-768-description">水电气用量一目了然，仪表照片留底，减少账单纠纷</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 智慧水务 结束 -->
    <!-- 应用案例 开始 -->
    <div class="part part-gray-bg letter-space">
      <div class="valid-area">
        <!-- 标题 -->
        <p class="part-title">应用案例</p>
        <!-- 装饰线 -->
        <p class="decoration-line"></p>
        <!-- 主内容区 -->
        <div class="main-content">
          <div class="case">
            <div class="case-info">
              <p><span>客户&nbsp;&nbsp;—&nbsp;&nbsp;</span>北京某物业</p>
              <p><span>时间&nbsp;&nbsp;—&nbsp;&nbsp;</span>2018年10月</p>
              <p><span>表具&nbsp;&nbsp;—&nbsp;&nbsp;</span>办公楼内电表、水表、燃气表（千台）</p>
              <p><span>效果&nbsp;&nbsp;—&nbsp;&nbsp;</span>实现各类仪表同一平台统一管理，自动生成费用账单，诊断仪表异常工作状态，摸清管线设计问题，降低运营成本</p>
            </div>
            <div class="case-pic">
              <img src="@/assets/images/property/case-img.png">
            </div>
          </div>
          <div class="case-app">
            <div class="case-app-box">
              <div class="case-data">
                <p style="width: 40%">（仪表详细数据）</p>
                <p style="width: 40%">（仪表费用账单）</p>
                <p style="width: 33%">（仪表读数变化趋势）</p>
              </div>
              <img class="case-data-pic" src="@/assets/images/property/case.png">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 应用案例 结束 -->
    <!-- 安装照片 开始 -->
    <div class="part letter-space" :style="{background:'#2B2E46 url('+require('@/assets/images/home/advantage-bg.png')+') no-repeat 90px 90px / 90% 90%'}">
      <div class="valid-area">
        <!-- 标题 -->
        <p class="part-title dark-bg-text-white">安装照片</p>
        <!-- 装饰线 -->
        <p class="decoration-line"></p>
        <div class="main-content">
          <img src="@/assets/images/property/install.png" style="width: 100%;display: block;margin:0 auto;">
        </div>
      </div>
    </div>
    <!-- 安装照片 结束 -->
  </div>
</template>

<script>
  import $ from 'jquery'
  export default {
    name: 'property',
    data(){
      return {
        widOver768: false,
      }
    },
    methods: {
      appear(){
        if(this.widOver768){
          $('.resolve-case-item').eq(0).delay(200).animate({"right":0,"opacity":1},600);
          $('.resolve-case-item').eq(2).delay(400).animate({"left":0,"opacity":1},600);
          $('.resolve-case-item').eq(1).delay(600).animate({"right":0,"opacity":1},600);
          $('.resolve-case-item').eq(3).delay(800).animate({"left":0,"opacity":1},600);
        }else{
          $('.resolve-case-768-item').eq(0).delay(200).animate({"right":0,"opacity":1},600);
          $('.resolve-case-768-item').eq(2).delay(400).animate({"left":0,"opacity":1},600);
          $('.resolve-case-768-item').eq(1).delay(600).animate({"right":0,"opacity":1},600);
          $('.resolve-case-768-item').eq(3).delay(800).animate({"left":0,"opacity":1},600);
        }
      },
      handleScroll(){
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        let offsetTop = document.querySelector('.case-app').parentElement.parentElement.offsetTop - 300
        if(scrollTop >= offsetTop){
          $('.case-app').animate({'top':0,'opacity':1},300)
        }
      },
      getWid(){
        let wid = document.documentElement.clientWidth || document.body.clientWidth
        this.widOver768 = wid <= 768 ? false : true;
      },
    },
    mounted(){
      this.getWid();
      this.appear();
      window.addEventListener('scroll',()=>{
        if(this.$route.name=='property'){
          this.handleScroll()
        }
      });
      window.addEventListener('resize',this.getWid);
    },
    updated(){
      this.appear();
    }
  }
</script>

<style lang="less" scoped>
  .page-banner{
    .valid-area{
      height: 100%;
    }
    .align-center{
      width: 100%;
      left: 0;
      transform: translate(0,-50%);
      @media screen and (max-width:768px){
        left: 50%;
        transform: translate(-50%,-50%);
      }
    }
  }
  .reslove-case-main .resolve-case .resolve-case-item:nth-child(2n){
    margin-top: 232px;
  }
</style>
