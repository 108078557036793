import Home from "@/components/views/Home"
import Company from '@/components/views/Company'
import IIot from '@/components/views/IIot'
import LanwairHome from '@/components/views/LanwairHome'
import Device from "@/components/views/Device"
import Algorithm from "@/components/views/Algorithm"
import Platform from '@/components/views/Platform'
import Geoscience from '@/components/views/Geoscience'
import AcousticSensor from '@/components/views/product/AcousticSensor'
import BoreholeSeismograph from '@/components/views/product/BoreholeSeismograph'
import BroadbandSeismograph from '@/components/views/product/BroadbandSeismograph'
import ElectricalExplorationEquipment from '@/components/views/product/ElectricalExplorationEquipment'
import Gravimeter from '@/components/views/product/Gravimeter'
import GroundPenetratingRadar from '@/components/views/product/GroundPenetratingRadar'
import MagneticExplorationEquipment from '@/components/views/product/MagneticExplorationEquipment'
import Radioactivity from '@/components/views/product/Radioactivity'
import StrongMotionAccelerometer from '@/components/views/product/StrongMotionAccelerometer'
import SubmarineSeismograph from '@/components/views/product/SubmarineSeismograph'
import FuelGas from "@/components/views/businessCase/FuelGas"
import Water from "@/components/views/businessCase/Water"
import OilGas from "@/components/views/businessCase/OilGas"
import Substations from "@/components/views/businessCase/Substations"
import Property from '@/components/views/businessCase/Property'
import Factory from '@/components/views/businessCase/Factory'
import IntegratedDigitiser from '@/components/views/product/IntegratedDigitiser'
// import NotFoundComponent from '@/components/layout/NotFoundComponent'

const routes = [
    {
      path: "/",
      redirect: "/home"
    },
    {
      path: "/home",
      name: "home",
      component: Home
    },
    {
      path: "/company",
      name: "company",
      component: Company
    },
    {
      path: "/IIot",
      name: "IIot",
      component: IIot
    },
    {
      path: "/lanwairHome",
      name: "lanwairHome",
      component: LanwairHome
    },
    {
      path: "/device",
      name: "device",
      component: Device
    },
    {
      path: "/algorithm",
      name: "algorithm",
      component: Algorithm
    },
    {
      path: "/platform",
      name: "platform",
      component: Platform
    },
    {
      path: "/geoscience",
      name: "geoscience",
      component: Geoscience,
      props: true
    },
    {
      path: "/acousticSensor",
      name: "acousticSensor",
      component: AcousticSensor
    },
    {
      path: "/BoreholeSeismograph",
      name: "boreholeSeismograph",
      component: BoreholeSeismograph
    },
    {
      path: "/BroadbandSeismograph",
      name: "broadbandSeismograph",
      component: BroadbandSeismograph
    },
    {
      path: "/electricalExplorationEquipment",
      name: "electricalExplorationEquipment",
      component: ElectricalExplorationEquipment
    },
    {
      path: "/gravimeter",
      name: "gravimeter",
      component: Gravimeter
    },
    {
      path: "/groundPenetratingRadar",
      name: "groundPenetratingRadar",
      component: GroundPenetratingRadar
    },
    {
      path: "/magneticExplorationEquipment",
      name: "magneticExplorationEquipment",
      component: MagneticExplorationEquipment
    },
    {
      path: "/radioactivity",
      name: "radioactivity",
      component: Radioactivity
    },
    {
      path: "/integratedDigitiser",
      name: "integratedDigitiser",
      component: IntegratedDigitiser
    },
    {
      path: "/strongMotionAccelerometer",
      name: "strongMotionAccelerometer",
      component: StrongMotionAccelerometer
    },
    {
      path: "/submarineSeismograph",
      name: "submarineSeismograph",
      component: SubmarineSeismograph
    },
    {
      path: "/water",
      name: "water",
      component: Water
    },
    {
      path: "/fuelGas",
      name: "fuelGas",
      component: FuelGas
    },
    {
      path: "/substations",
      name: "substations",
      component: Substations
    },
    {
      path: "/oilGas",
      name: "oilGas",
      component: OilGas
    },
    {
      path: "/property",
      name: "property",
      component: Property
    },
    {
      path: "/factory",
      name: "factory",
      component: Factory
    },
    // { path: '*', component: NotFoundComponent, meta: { status: 404 } }
  ];
  
  export default routes