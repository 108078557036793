var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"water-wrap"},[_c('div',{staticClass:"page-banner",style:({background: '#1D212E url('+require('@/assets/images/water/banner.jpg')+') no-repeat', backgroundSize: 'cover'})},[_c('div',{staticClass:"banner-mask"}),_c('div',{staticClass:"valid-area"},[_c('div',{staticClass:"align-center"},[_c('p',{staticClass:"page-banner-title"},[_vm._v("智慧水务解决方案")]),(_vm.widOver768)?_c('p',{staticClass:"page-banner-description"},[_vm._v("解决高危高难场景抄表难题，避免估抄、漏抄、错抄，")]):_vm._e(),(_vm.widOver768)?_c('p',{staticClass:"page-banner-description"},[_vm._v("降低产销差，提升运营管控能力")]):_vm._e(),(!_vm.widOver768)?_c('p',{staticClass:"page-banner-description"},[_vm._v("解决高危高难场景抄表难题，")]):_vm._e(),(!_vm.widOver768)?_c('p',{staticClass:"page-banner-description"},[_vm._v("避免估抄、漏抄、错抄，降低产销差，")]):_vm._e(),(!_vm.widOver768)?_c('p',{staticClass:"page-banner-description"},[_vm._v("提升运营管控能力")]):_vm._e()])])]),_c('div',{staticClass:"part part-light-bg letter-space"},[_c('div',{staticClass:"valid-area"},[_c('p',{staticClass:"part-title"},[_vm._v("智慧水务")]),_c('p',{staticClass:"decoration-line"}),(_vm.widOver768)?_c('div',{staticClass:"main-content layout-flex reslove-case-main"},[_vm._m(0),_vm._m(1),_vm._m(2)]):_vm._e(),(!_vm.widOver768)?_c('div',{staticClass:"main-content reslove-case-main-768"},[_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7)]):_vm._e()])]),_vm._m(8),_c('div',{staticClass:"part letter-space",style:({background:'#2B2E46 url('+require('@/assets/images/home/advantage-bg.png')+') no-repeat 90px 90px / 90% 90%'})},[_vm._m(9)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resolve-case align-right"},[_c('div',{staticClass:"resolve-case-item"},[_c('p',{staticClass:"resolve-case-title"},[_vm._v("便捷")]),_c('p',{staticClass:"resolve-case-description"},[_vm._v("居民线上便捷缴费")]),_c('p',{staticClass:"resolve-case-description"},[_vm._v("替代高难度抄表，如深水井下、隧道内、狭小空间、居民住宅内等")])]),_c('div',{staticClass:"resolve-case-item"},[_c('p',{staticClass:"resolve-case-title"},[_vm._v("降本")]),_c('p',{staticClass:"resolve-case-description"},[_vm._v("节约智能化改造成本，无需断水施工")]),_c('p',{staticClass:"resolve-case-description"},[_vm._v("用水量监控，避免估抄、漏抄、人情水费，经济损失有据可查")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resolve-case-pic"},[_c('img',{attrs:{"src":require("@/assets/images/water/water.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resolve-case align-left"},[_c('div',{staticClass:"resolve-case-item"},[_c('p',{staticClass:"resolve-case-title"},[_vm._v("安全")]),_c('p',{staticClass:"resolve-case-description"},[_vm._v("替代高危环境抄表，确保人员生命安全")]),_c('p',{staticClass:"resolve-case-description"},[_vm._v("供水管网监控，监测水表工作状态与流量，预防爆管引发的安全事故")])]),_c('div',{staticClass:"resolve-case-item"},[_c('p',{staticClass:"resolve-case-title"},[_vm._v("增收")]),_c('p',{staticClass:"resolve-case-description"},[_vm._v("分析产销差率，及时发现跑冒滴漏区域，提出整改建议，最终实现水费增收")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"reslove-img"},[_c('img',{attrs:{"src":require("@/assets/images/water/water-res.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resolve-case-768-item"},[_c('p',{staticClass:"resolve-case-768-title"},[_vm._v("便捷")]),_c('p',{staticClass:"resolve-case-768-description"},[_vm._v("居民线上便捷缴费")]),_c('p',{staticClass:"resolve-case-768-description"},[_vm._v("替代高难度抄表，如深水井下、隧道内、狭小空间、居民住宅内等")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resolve-case-768-item"},[_c('p',{staticClass:"resolve-case-768-title"},[_vm._v("安全")]),_c('p',{staticClass:"resolve-case-768-description"},[_vm._v("替代高危环境抄表，确保人员生命安全")]),_c('p',{staticClass:"resolve-case-768-description"},[_vm._v("供水管网监控，监测水表工作状态与流量，预防爆管引发的安全事故")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resolve-case-768-item"},[_c('p',{staticClass:"resolve-case-768-title"},[_vm._v("降本")]),_c('p',{staticClass:"resolve-case-768-description"},[_vm._v("节约智能化改造成本，无需断水施工")]),_c('p',{staticClass:"resolve-case-768-description"},[_vm._v("用水量监控，避免估抄、漏抄、人情水费，经济损失有据可查")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resolve-case-768-item"},[_c('p',{staticClass:"resolve-case-768-title"},[_vm._v("增收")]),_c('p',{staticClass:"resolve-case-768-description"},[_vm._v("分析产销差率，及时发现跑冒滴漏区域，提出整改建议，最终实现水费增收")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"part part-gray-bg letter-space"},[_c('div',{staticClass:"valid-area"},[_c('p',{staticClass:"part-title"},[_vm._v("应用案例")]),_c('p',{staticClass:"decoration-line"}),_c('div',{staticClass:"main-content"},[_c('div',{staticClass:"case"},[_c('div',{staticClass:"case-info"},[_c('p',[_c('span',[_vm._v("客户  —  ")]),_vm._v("吉林省某自来水")]),_c('p',[_c('span',[_vm._v("时间  —  ")]),_vm._v("2018年12月")]),_c('p',[_c('span',[_vm._v("表具  —  ")]),_vm._v("供水管网水表、工商业水表、居民水表（千余台）")]),_c('p',[_c('span',[_vm._v("效果  —  ")]),_vm._v("解决高难度和高危场景下的抄表难题，实现用水大户重点监测，一键导出用水月报，及时发现偷漏损等异常用水行为，经济损失有据可查。")])]),_c('div',{staticClass:"case-pic"},[_c('img',{attrs:{"src":require("@/assets/images/water/case-img.png")}})])]),_c('div',{staticClass:"case-app"},[_c('div',{staticClass:"case-app-box"},[_c('div',{staticClass:"case-data"},[_c('p',{staticStyle:{"width":"34%"}},[_vm._v("（水表详细数据）")]),_c('p',{staticStyle:{"width":"32%"}},[_vm._v("（水表用量报表）")]),_c('p',{staticStyle:{"width":"27%"}},[_vm._v("（水表历史用量）")])]),_c('img',{staticClass:"case-data-pic",attrs:{"src":require("@/assets/images/water/case.png")}})])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"valid-area"},[_c('p',{staticClass:"part-title dark-bg-text-white"},[_vm._v("安装照片")]),_c('p',{staticClass:"decoration-line"}),_c('div',{staticClass:"main-content"},[_c('img',{staticStyle:{"width":"100%","display":"block","margin":"0 auto"},attrs:{"src":require("@/assets/images/water/install.png")}})])])
}]

export { render, staticRenderFns }