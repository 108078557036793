var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"property-wrap"},[_c('div',{staticClass:"page-banner",style:({background: '#1D212E url('+require('@/assets/images/property/banner.jpg')+') no-repeat', backgroundSize: 'cover'})},[_c('div',{staticClass:"banner-mask"}),_vm._m(0)]),_c('div',{staticClass:"part part-light-bg letter-space"},[_c('div',{staticClass:"valid-area"},[_c('p',{staticClass:"part-title"},[_vm._v("智慧物业")]),_c('p',{staticClass:"decoration-line"}),(_vm.widOver768)?_c('div',{staticClass:"main-content layout-flex reslove-case-main"},[_vm._m(1),_vm._m(2),_vm._m(3)]):_vm._e(),(!_vm.widOver768)?_c('div',{staticClass:"main-content reslove-case-main-768"},[_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8)]):_vm._e()])]),_vm._m(9),_c('div',{staticClass:"part letter-space",style:({background:'#2B2E46 url('+require('@/assets/images/home/advantage-bg.png')+') no-repeat 90px 90px / 90% 90%'})},[_vm._m(10)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"valid-area"},[_c('div',{staticClass:"align-center"},[_c('p',{staticClass:"page-banner-title"},[_vm._v("智慧物业解决方案")]),_c('p',{staticClass:"page-banner-description"},[_vm._v("解决日益繁重的抄表难题，提高抄收效率，")]),_c('p',{staticClass:"page-banner-description"},[_vm._v("有图为证，避免账单纠纷")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resolve-case align-right"},[_c('div',{staticClass:"resolve-case-item"},[_c('p',{staticClass:"resolve-case-title"},[_vm._v("安全")]),_c('p',{staticClass:"resolve-case-description"},[_vm._v("掌握设备用能趋势，合理调度控制")]),_c('p',{staticClass:"resolve-case-description"},[_vm._v("发现故障仪表，排查安全隐患")])]),_c('div',{staticClass:"resolve-case-item"},[_c('p',{staticClass:"resolve-case-title"},[_vm._v("省力")]),_c('p',{staticClass:"resolve-case-description"},[_vm._v("解决人工现场抄表强度大、误差大、不实时、效率低等问题")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resolve-case-pic"},[_c('img',{attrs:{"src":require("@/assets/images/property/property.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resolve-case align-left"},[_c('div',{staticClass:"resolve-case-item"},[_c('p',{staticClass:"resolve-case-title"},[_vm._v("降本")]),_c('p',{staticClass:"resolve-case-description"},[_vm._v("快速实现机械表远程数据采集")]),_c('p',{staticClass:"resolve-case-description"},[_vm._v("实现用能精细化管理，降低经济损失")])]),_c('div',{staticClass:"resolve-case-item"},[_c('p',{staticClass:"resolve-case-title"},[_vm._v("省心")]),_c('p',{staticClass:"resolve-case-description"},[_vm._v("水电气用量一目了然，仪表照片留底，减少账单纠纷")]),_c('p',{staticClass:"resolve-case-description"},[_vm._v("改变租赁双方抄表模式，建立客户信任关系")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"reslove-img"},[_c('img',{attrs:{"src":require("@/assets/images/property/property-res.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resolve-case-768-item"},[_c('p',{staticClass:"resolve-case-768-title"},[_vm._v("安全")]),_c('p',{staticClass:"resolve-case-768-description"},[_vm._v("发现故障仪表，排查安全隐患")]),_c('p',{staticClass:"resolve-case-768-description"},[_vm._v("掌握设备用能趋势，合理调度控制")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resolve-case-768-item"},[_c('p',{staticClass:"resolve-case-768-title"},[_vm._v("降本")]),_c('p',{staticClass:"resolve-case-768-description"},[_vm._v("快速实现机械表远程数据采集")]),_c('p',{staticClass:"resolve-case-768-description"},[_vm._v("实现用能精细化管理，降低经济损失")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resolve-case-768-item"},[_c('p',{staticClass:"resolve-case-768-title"},[_vm._v("省力")]),_c('p',{staticClass:"resolve-case-768-description"},[_vm._v("解决人工现场抄表强度大、")]),_c('p',{staticClass:"resolve-case-768-description"},[_vm._v("误差大、不实时、效率低等问题")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resolve-case-768-item"},[_c('p',{staticClass:"resolve-case-768-title"},[_vm._v("省心")]),_c('p',{staticClass:"resolve-case-768-description"},[_vm._v("改变租赁双方抄表模式，建立客户信任关系")]),_c('p',{staticClass:"resolve-case-768-description"},[_vm._v("水电气用量一目了然，仪表照片留底，减少账单纠纷")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"part part-gray-bg letter-space"},[_c('div',{staticClass:"valid-area"},[_c('p',{staticClass:"part-title"},[_vm._v("应用案例")]),_c('p',{staticClass:"decoration-line"}),_c('div',{staticClass:"main-content"},[_c('div',{staticClass:"case"},[_c('div',{staticClass:"case-info"},[_c('p',[_c('span',[_vm._v("客户  —  ")]),_vm._v("北京某物业")]),_c('p',[_c('span',[_vm._v("时间  —  ")]),_vm._v("2018年10月")]),_c('p',[_c('span',[_vm._v("表具  —  ")]),_vm._v("办公楼内电表、水表、燃气表（千台）")]),_c('p',[_c('span',[_vm._v("效果  —  ")]),_vm._v("实现各类仪表同一平台统一管理，自动生成费用账单，诊断仪表异常工作状态，摸清管线设计问题，降低运营成本")])]),_c('div',{staticClass:"case-pic"},[_c('img',{attrs:{"src":require("@/assets/images/property/case-img.png")}})])]),_c('div',{staticClass:"case-app"},[_c('div',{staticClass:"case-app-box"},[_c('div',{staticClass:"case-data"},[_c('p',{staticStyle:{"width":"40%"}},[_vm._v("（仪表详细数据）")]),_c('p',{staticStyle:{"width":"40%"}},[_vm._v("（仪表费用账单）")]),_c('p',{staticStyle:{"width":"33%"}},[_vm._v("（仪表读数变化趋势）")])]),_c('img',{staticClass:"case-data-pic",attrs:{"src":require("@/assets/images/property/case.png")}})])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"valid-area"},[_c('p',{staticClass:"part-title dark-bg-text-white"},[_vm._v("安装照片")]),_c('p',{staticClass:"decoration-line"}),_c('div',{staticClass:"main-content"},[_c('img',{staticStyle:{"width":"100%","display":"block","margin":"0 auto"},attrs:{"src":require("@/assets/images/property/install.png")}})])])
}]

export { render, staticRenderFns }