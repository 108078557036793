var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"property-wrap"},[_c('div',{staticClass:"page-banner",style:({background: '#1D212E url('+require('@/assets/images/fuelGas/banner.png')+') no-repeat', backgroundSize: 'cover'})},[_c('div',{staticClass:"banner-mask"}),_vm._m(0)]),_c('div',{staticClass:"part part-light-bg letter-space"},[_c('div',{staticClass:"valid-area"},[_c('p',{staticClass:"part-title"},[_vm._v("智慧燃气")]),_c('p',{staticClass:"decoration-line"}),(_vm.widOver768)?_c('div',{staticClass:"main-content layout-flex reslove-case-main"},[_vm._m(1),_vm._m(2),_vm._m(3)]):_vm._e(),(!_vm.widOver768)?_c('div',{staticClass:"main-content reslove-case-main-768"},[_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8)]):_vm._e()])]),_vm._m(9),_c('div',{staticClass:"part letter-space",style:({background:'#2B2E46 url('+require('@/assets/images/home/advantage-bg.png')+') no-repeat 90px 90px / 90% 90%'})},[_vm._m(10)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"valid-area"},[_c('div',{staticClass:"align-center"},[_c('p',{staticClass:"page-banner-title"},[_vm._v("智慧燃气解决方案")]),_c('p',{staticClass:"page-banner-description"},[_vm._v("解决燃气公司在采购天然气时面临采气量难以精准预估的痛点")]),_c('p',{staticClass:"page-banner-description"},[_vm._v("精准调度工商业大用户的用气量，及时发现计量异常")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resolve-case align-right"},[_c('div',{staticClass:"resolve-case-item"},[_c('p',{staticClass:"resolve-case-title"},[_vm._v("安全")]),_c('p',{staticClass:"resolve-case-description"},[_vm._v("掌握设备用能趋势，合理调度控制，及时发现用气异常")]),_c('p',{staticClass:"resolve-case-description"},[_vm._v("远程统抄运行稳定、数据采集率高，后期免维护")])]),_c('div',{staticClass:"resolve-case-item"},[_c('p',{staticClass:"resolve-case-title"},[_vm._v("省力")]),_c('p',{staticClass:"resolve-case-description"},[_vm._v("适用于各类型号仪器仪表，无需协议对接，实现同一平台统一管理")]),_c('p',{staticClass:"resolve-case-description"},[_vm._v("解决人工现场抄表强度大、误差大、不实时、效率低等问题")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resolve-case-pic"},[_c('img',{attrs:{"src":require("@/assets/images/property/property.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resolve-case align-left"},[_c('div',{staticClass:"resolve-case-item"},[_c('p',{staticClass:"resolve-case-title"},[_vm._v("降本")]),_c('p',{staticClass:"resolve-case-description"},[_vm._v("避免管道拆卸，停气停工，后装优势可快速实现各类流量计远程数据采集")]),_c('p',{staticClass:"resolve-case-description"},[_vm._v("方便用户进行设备管理和能耗分析，实现用能精细化管理，降低经济损失")])]),_c('div',{staticClass:"resolve-case-item"},[_c('p',{staticClass:"resolve-case-title"},[_vm._v("省心")]),_c('p',{staticClass:"resolve-case-description"},[_vm._v("用气量一目了然，仪表照片留底，减少账单纠纷")]),_c('p',{staticClass:"resolve-case-description"},[_vm._v("减少现场巡视，燃气公司、工商业大用户可使用小程序便捷查询每日用量")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"reslove-img"},[_c('img',{attrs:{"src":require("@/assets/images/property/property-res.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resolve-case-768-item"},[_c('p',{staticClass:"resolve-case-768-title"},[_vm._v("安全")]),_c('p',{staticClass:"resolve-case-768-description"},[_vm._v("发现故障仪表，排查安全隐患")]),_c('p',{staticClass:"resolve-case-768-description"},[_vm._v("掌握设备用能趋势，合理调度控制")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resolve-case-768-item"},[_c('p',{staticClass:"resolve-case-768-title"},[_vm._v("降本")]),_c('p',{staticClass:"resolve-case-768-description"},[_vm._v("快速实现机械表远程数据采集")]),_c('p',{staticClass:"resolve-case-768-description"},[_vm._v("实现用能精细化管理，降低经济损失")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resolve-case-768-item"},[_c('p',{staticClass:"resolve-case-768-title"},[_vm._v("省力")]),_c('p',{staticClass:"resolve-case-768-description"},[_vm._v("解决人工现场抄表强度大、")]),_c('p',{staticClass:"resolve-case-768-description"},[_vm._v("误差大、不实时、效率低等问题")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resolve-case-768-item"},[_c('p',{staticClass:"resolve-case-768-title"},[_vm._v("省心")]),_c('p',{staticClass:"resolve-case-768-description"},[_vm._v("改变租赁双方抄表模式，建立客户信任关系")]),_c('p',{staticClass:"resolve-case-768-description"},[_vm._v("水电气用量一目了然，仪表照片留底，减少账单纠纷")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"part part-gray-bg letter-space"},[_c('div',{staticClass:"valid-area"},[_c('p',{staticClass:"part-title"},[_vm._v("应用案例")]),_c('p',{staticClass:"decoration-line"}),_c('div',{staticClass:"main-content"},[_c('div',{staticClass:"case"},[_c('div',{staticClass:"case-info"},[_c('p',[_c('span',[_vm._v("客户  —  ")]),_vm._v("某城市燃气公司 ")]),_c('p',[_c('span',[_vm._v("时间  —  ")]),_vm._v("2022年03月 ")]),_c('p',[_c('span',[_vm._v("表具  —  ")]),_vm._v("工商业大用户燃气表、楼宇电表 ")]),_c('p',[_c('span',[_vm._v("效果  —  ")]),_vm._v("智能抄表终端持续正常运行近4个月，数据上传率和准确率均达到100%，为精准调度和及时发现计量异常提供了坚实的技术保障。通过及时传回大工业用户和商业采暖锅炉用户的用气数据，节省了数千万元的气量采购费用，为公司调度气源和保证供气提供了重要的数据支撑和决策依据 ")])]),_c('div',{staticClass:"case-pic"},[_c('img',{attrs:{"src":require("@/assets/images/fuelGas/case-img.png")}})])]),_c('div',{staticClass:"case-app"},[_c('div',{staticClass:"case-app-box"},[_c('div',{staticClass:"case-data"},[_c('p',{staticStyle:{"width":"34%"}},[_vm._v("（仪表抄录详细数据）")]),_c('p',{staticStyle:{"width":"40%"}},[_vm._v("（仪表读数变化趋势）")]),_c('p',{staticStyle:{"width":"33%"}},[_vm._v("（仪表用量变化趋势）")])]),_c('img',{staticClass:"case-data-pic",attrs:{"src":require("@/assets/images/fuelGas/case.png")}})])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"valid-area"},[_c('p',{staticClass:"part-title dark-bg-text-white"},[_vm._v("安装照片")]),_c('p',{staticClass:"decoration-line"}),_c('div',{staticClass:"main-content"},[_c('img',{staticStyle:{"width":"100%","display":"block","margin":"0 auto"},attrs:{"src":require("@/assets/images/fuelGas/aztp.png")}})])])
}]

export { render, staticRenderFns }