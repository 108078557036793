<template>
  <div class="wrap">
    <!-- banner 开始 -->
    <div
      class="home-banner"
      :style="{'background': 'url('+require('@/assets/images/IIot/banner.png')+') no-repeat 50% 0 / cover'}"
      v-if="widOver768===false"
    >
      <div class="banner-mask"></div>
      <div class="align-center banner-text">
        <!-- <p class="hints">通知：羿娲科技已更名为北京三合智能科技有限公司，感谢关注！</p> -->
        <p class="banner-title">仪表识别服务累计调用量</p>
        <div class="banner-sub-title clearfix"></div>
      </div>
    </div>
    <div class="home-banner" v-if="widOver768===true" style="background:#1D212E">
      <!-- loading -->
      <!-- <div class="align-center" v-if="isLoading===true" style="z-index:1">
        <lottie
          :options="loadingOptions"
          :height="200"
          :width="400"
          v-on:animCreated="loadingAnimation"
        />
      </div> -->
      <div id="index-banner">
        <video
          id="video"
          src="@/assets/images/IIot/iiot.mp4"
          loop
          autoplay
          muted
          @play="isReadyVideo"
        >您的浏览器不支持视频播放，请更换浏览器</video>
      </div>
      <div class="banner-mask" v-if="isLoading===false"></div>
      <div class="align-center banner-text">
        <p class="banner-title">仪表识别服务累计调用量</p>
        <div class="banner-sub-title clearfix"></div>
      </div>
    </div>
    <!-- banner 结束 -->

    <!-- 企业宣传视频 开始 -->
    <!--    <div class="part part-light-bg" :style="{background: 'url('+require('../../assets/images/home/com_video_bg.png')+') no-repeat',backgroundSize: '100% 100%'}">-->
    <!--      <div class="valid-area">-->
    <!--        <p class="part-title">企业宣传视频</p>-->
    <!--        <p class="decoration-line"></p>-->
    <!--        <div class="com-video">-->
    <!--          <video-player class="video-player vjs-custom-skin" ref="videoPlayer" :playsinline="true" :options="playerOptions"></video-player>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!-- 企业宣传视频 结束 -->

    <!-- 仪表智能识别系统 开始 -->
    <div class="part part-light-bg part-sys letter-space">
      <div class="valid-area">
        <!-- 标题 -->
        <p class="part-title">
          <span class="light-bg-text">羿读</span>
          <span class="point-box">
            <span class="split-point align-center"></span>
          </span>仪表智能识别系统
        </p>
        <!-- 装饰线 -->
        <p class="decoration-line"></p>
        <!-- 描述 -->
        <div class="part-description" v-if="widOver768">
          <p>解决传统仪表数据采集瓶颈, 降低数字化、智能化成本，</p>
          <p>提供低成本，高质量，持续稳定的数据源与可视化数据及分析结果。</p>
        </div>
        <div class="part-description" v-if="!widOver768">
          <p>解决传统仪表数据采集瓶颈, 降低数字化、</p>
          <p>智能化成本，提供低成本，高质量，持续稳定</p>
          <p>的数据源与可视化数据及分析结果。</p>
        </div>
        <!-- 图示 -->
        <div class="main-content sys-graphic" v-if="widOver768">
          <div class="graphic-item">
            <div class="graphic-pic">
              <img class="align-center" src="@/assets/images/IIot/sys-meter.png" />
            </div>
            <div class="light-bg-text">图像采集</div>
          </div>
          <div class="graphic-item">
            <div class="graphic-pic" style="margin-left:30px;">
              <lottie
                :options="photoOptions"
                :height="200"
                :width="200"
                v-on:animCreated="photoAnimation"
              />
            </div>
            <div class="light-bg-text"></div>
          </div>
          <div class="graphic-item">
            <div class="graphic-pic">
              <img
                class="align-center"
                src="@/assets/images/IIot/sys-trans.png"
                style="height: 80%"
              />
            </div>
            <div class="light-bg-text">传输</div>
          </div>
          <div class="graphic-item">
            <div class="graphic-pic" style="margin-left:-30px;">
              <lottie
                :options="photoOptions"
                :height="200"
                :width="200"
                v-on:animCreated="photoAnimation"
              />
            </div>
            <div class="light-bg-text"></div>
          </div>
          <div class="graphic-item">
            <div class="graphic-pic">
              <img
                class="align-center"
                src="@/assets/images/IIot/sys-server.png"
                style="height: 80%"
              />
            </div>
            <div class="light-bg-text">识别分析</div>
          </div>
          <div class="graphic-item">
            <div class="graphic-pic">
              <lottie
                :options="readingOptions"
                :height="200"
                :width="200"
                v-on:animCreated="readingAnimation"
              />
            </div>
            <div class="light-bg-text"></div>
          </div>
          <div class="graphic-item">
            <div class="graphic-pic">
              <img class="align-center" src="@/assets/images/IIot/sys-service.png" />
            </div>
            <div class="light-bg-text">应用与服务</div>
          </div>
        </div>
        <div class="main-content sys-img" v-if="!widOver768">
          <img src="@/assets/images/IIot/flow.png" style="width: 100%" />
        </div>
      </div>
    </div>
    <!-- 仪表智能识别系统 结束 -->
    <!-- 核心优势 开始 -->
    <div class="part part-dark-bg part-advantage letter-space">
      <div class="valid-area">
        <!-- 标题 -->
        <p class="part-title dark-bg-text-white">
          <span class="dark-bg-text">羿读</span>
          <span class="point-box">
            <span class="split-point split-point-white align-center"></span>
          </span>核心优势
        </p>
        <div
          class="main-bg"
          :style="{backgroundImage:'url('+require('../../assets/images/IIot/advantage-bg.png')+')'}"
        >
          <!-- 装饰线 -->
          <p class="decoration-line"></p>
          <!-- 描述 -->
          <div class="part-description dark-bg-text-white" v-if="widOver768">
            <p>全仪表类型图像识别算法 + 端云一体全链路自研</p>
          </div>
          <div class="part-description dark-bg-text-white" v-if="!widOver768">
            <p>全仪表类型图像识别算法</p>
            <p>端云一体全链路自研</p>
          </div>
          <!-- 优势轮播 -->
          <div class="main-content advantage">
            <swiper :options="advantageOption" ref="advantageSwiper">
              <swiper-slide v-for="(item,ind) in advantageList" :key="ind">
                <div class="banner-item advantage-item">
                  <img :src="require('@/assets/images/IIot/' + item.img)" />
                  <div class="banner-mask"></div>
                  <div class="flex-col-align align-center" style="width:100%">
                    <img :src="require('@/assets/images/IIot/' + item.icon)" class="item-icon" />
                    <p class="base-title">{{item.title}}</p>
                    <p class="base-description">{{item.desc1}}</p>
                    <p class="base-description base-description-line2">{{item.desc2}}</p>
                  </div>
                </div>
              </swiper-slide>
              <div class="advantage-pagination" slot="pagination"></div>
            </swiper>
          </div>
        </div>
      </div>
    </div>
    <!-- 核心优势 结束 -->
    <!-- 智能AI相机 开始 -->
    <div class="part part-light-bg part-device letter-space">
      <div class="valid-area">
        <!-- 标题 -->
        <p class="part-title">
          <span class="light-bg-text">羿读</span>
          <span class="point-box">
            <span class="split-point align-center"></span>
          </span>智能AI相机
        </p>
        <!-- 装饰线 -->
        <p class="decoration-line"></p>
        <div class="main-content layout-flex device">
          <div class="device-img" v-if="widOver768">
            <swiper :options="deviceOption" ref="deviceSwiper">
              <swiper-slide>
                <img src="@/assets/images/IIot/device/argus2.png" />
              </swiper-slide>
              <swiper-slide>
                <img src="@/assets/images/IIot/device/doris2.png" />
              </swiper-slide>
              <swiper-slide>
                <img src="@/assets/images/IIot/device/horus2.png" />
              </swiper-slide>
              <div class="device-pagination" slot="pagination"></div>
            </swiper>
          </div>
          <div class="device-charactor">
            <div class="main-content-item" v-for="(item,ind) in deviceList" :key="ind">
              <div class="item-pic-box">
                <img class="align-center" :src="require('@/assets/images/IIot/' + item.img)" />
              </div>
              <div class="main-content-text">
                <div class="main-content-title">{{item.title}}</div>
                <div class="main-content-description">{{item.desc}}</div>
              </div>
            </div>
            <div class="see-detail">
              <img class="align-center" src="@/assets/images/btn.png" />
              <p>
                <router-link to="/device">了解详情</router-link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 智能AI相机 结束 -->
    <!-- 图像识别算法 开始 -->
    <div class="part part-dark-bg part-algorithm letter-space">
      <div class="valid-area">
        <!-- 标题 -->
        <p class="part-title dark-bg-text-white">图像识别算法</p>
        <!-- 装饰线 -->
        <p class="decoration-line"></p>
        <!-- 主内容区 -->
        <div class="main-content layout-flex">
          <div class="algorithm-charactor">
            <div class="main-content-item" v-for="(item,ind) in algorithmList" :key="ind">
              <div class="item-pic-box">
                <img class="align-center" :src="require('@/assets/images/IIot/' + item.img)" />
              </div>
              <div class="main-content-text">
                <div class="main-content-title">{{item.title}}</div>
                <div class="main-content-description">
                  <span class="dark-bg-text"></span>
                  {{item.desc}}
                </div>
              </div>
            </div>
            <div class="see-detail">
              <img class="align-center" src="@/assets/images/btn.png" />
              <p>
                <router-link to="/algorithm">了解详情</router-link>
              </p>
            </div>
          </div>
          <div class="algorithm-img" v-if="widOver768">
            <img src="@/assets/images/IIot/algorithm.png" />
          </div>
        </div>
      </div>
    </div>
    <!-- 图像识别算法 结束 -->
    <!-- 云平台 开始 -->
    <div class="part part-light-bg part-platform letter-space">
      <div class="valid-area">
        <!-- 标题 -->
        <p class="part-title">
          <span class="light-bg-text">羿读</span>
          <span class="point-box">
            <span class="split-point align-center"></span>
          </span>云平台
        </p>
        <!-- 装饰线 -->
        <p class="decoration-line"></p>
        <!-- 主内容区 -->
        <div class="main-content">
          <img class="platform-img" src="@/assets/images/IIot/platform.png" />
          <div class="platform layout-flex cloud">
            <div class="platform-item flex-col-align" v-for="(item,ind) in platformList" :key="ind">
              <div class="item-pic-box">
                <img class="align-center" :src="require('@/assets/images/IIot/' + item.img)" />
              </div>
              <p class="main-content-title">{{item.title}}</p>
              <p class="main-content-description">{{item.desc}}</p>
            </div>
          </div>
          <div class="see-detail platform-detail">
            <img class="align-center" src="@/assets/images/btn.png" />
            <p>
              <router-link to="/platform">了解详情</router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- 云平台 结束 -->
    <!-- 技术壁垒 开始 -->
    <div
      class="part part-rampart letter-space"
      :style="{background: widOver768 ? '#f8f9fe url('+require('../../assets/images/IIot/tecnology-bg.png')+')' : '#f8f9fe',backgroundSize: '100% 100%'}"
    >
      <div class="valid-area">
        <!-- 标题 -->
        <p class="part-title">技术壁垒</p>
        <!-- 装饰线 -->
        <p class="decoration-line"></p>
        <!-- 主内容区 -->
        <div class="main-content">
          <div class="platform layout-flex rampart">
            <div class="platform-item flex-col-align" v-for="(item,ind) in rampartList" :key="ind">
              <div class="item-pic-box">
                <img class="align-center" :src="require('@/assets/images/IIot/' + item.img)" />
              </div>
              <p class="main-content-title">{{item.title}}</p>
              <p class="main-content-description">{{item.desc}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 技术壁垒 结束 -->
    <!-- 解决方案 开始 -->
    <div
      class="resolve letter-space"
      :style="{background: '#1D212E url('+require('../../assets/images/IIot/'+currentResolveContent.bg)+') no-repeat right top / cover'}"
      v-if="widOver768"
    >
      <div class="valid-area layout-flex">
        <div class="resolve-menu">
          <div
            class="resolve-menu-item clearfix"
            :class="{'active-menu':item.index==activeResolveMenu}"
            v-for="(item,ind) in resolveContent"
            :key="ind"
            @click="selectResolveMenu(ind)"
          >
            <div class="item-pic-box resolve-pic-box fl">
              <img
                class="align-center"
                v-if="item.index==activeResolveMenu"
                :src="require('@/assets/images/IIot/' + item.iconSelected)"
              />
              <img
                class="align-center"
                v-if="item.index!=activeResolveMenu"
                :src="require('@/assets/images/IIot/' + item.icon)"
              />
            </div>
            <span class="fl">{{item.name}}</span>
          </div>
        </div>
        <div class="resolve-content resolve-wid768">
          <p
            class="part-title dark-bg-text-white"
            style="text-align:left"
          >{{currentResolveContent.name}}解决方案</p>
          <p class="decoration-line" style="margin:30px 0"></p>
          <p class="resolve-description">{{currentResolveContent.description}}</p>
          <ul class="resolve-keyword clearfix">
            <li v-for="(val,ind) in currentResolveContent.keyword" :key="ind">
              <span></span>
              {{val}}
            </li>
          </ul>
          <div class="see-detail">
            <img class="align-center" src="@/assets/images/btn.png" />
            <p>
              <router-link :to="currentResolveContent.route">了解详情</router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="part part-dark-bg letter-space" v-if="!widOver768">
      <div class="valid-area">
        <swiper :options="miniResolveOption" ref="miniResolveSwiper">
          <swiper-slide v-for="(item,ind) in resolveContent" :key="ind">
            <div class="resolve-content">
              <p class="part-title dark-bg-text-white">{{item.name}}解决方案</p>
              <p class="decoration-line"></p>
              <div class="main-content">
                <p class="resolve-description">{{item.description}}</p>
                <ul class="resolve-keyword clearfix">
                  <li v-for="(val,ind) in item.keyword" :key="ind">
                    <span></span>
                    {{val}}
                  </li>
                </ul>
                <div class="see-detail" style="margin-bottom: 0.4rem">
                  <img class="align-center" src="@/assets/images/btn.png" />
                  <p>
                    <router-link :to="item.route">了解详情</router-link>
                  </p>
                </div>
              </div>
            </div>
          </swiper-slide>
          <div class="resolve-pagination" slot="pagination"></div>
        </swiper>
      </div>
    </div>
    <!-- 解决方案 结束 -->
    <!-- 新闻中心 开始 -->
    <!--    <div class="part part-gray-bg news letter-space" v-if="widOver768===true">-->
    <!--      <div class="valid-area">-->
    <!--        &lt;!&ndash; 标题 &ndash;&gt;-->
    <!--        <p class="part-title">新闻中心</p>-->
    <!--        &lt;!&ndash; 装饰线 &ndash;&gt;-->
    <!--        <p class="decoration-line"></p>-->
    <!--        &lt;!&ndash; 主内容区 &ndash;&gt;-->
    <!--        <div class="main-content">-->
    <!--          <swiper :options="newsOption">-->
    <!--            <swiper-slide v-for="(item,ind) in newsList" :key="ind">-->
    <!--              <div class="news-item" @click="routeToNewsDetail(ind)">-->
    <!--                <div class="news-item-pic">-->
    <!--                  <img :src="require('@/assets/images/news-data/' + item.img)">-->
    <!--                </div>-->
    <!--                <div class="news-item-con">-->
    <!--                  <div class="news-item-title">-->
    <!--                    <div class="news-title">{{item.title}}</div>-->
    <!--                    <div class="news-time">{{item.time}}</div>-->
    <!--                  </div>-->
    <!--                  <div class="news-item-summary">{{item.summary}}</div>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--            </swiper-slide>-->
    <!--          </swiper>-->
    <!--          <div class="see-detail" style="margin:40px auto 0;">-->
    <!--            <img class="align-center" src="@/assets/images/btn.png">-->
    <!--            <p>-->
    <!--              <router-link to="/news">查看更多</router-link>-->
    <!--            </p>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!-- 新闻中心 结束 -->
    <!-- 合作伙伴 开始 -->
    <!-- <div class="part part-light-bg">
      <div class="valid-area">
        <p class="part-title">合作伙伴</p>
        <p class="decoration-line"></p>
        <div class="main-content">
          <img src="@/assets/images/home/partner.png" style="display: block;width: 100%" />
        </div>
      </div>
    </div> -->
    <!-- 合作伙伴 结束 -->
    <!-- 获得客户基础信息入口 -->
    <!-- <div class="contact-form-container">
      <img src="@/assets/images/home/gain_background.png" class="background-image" />
      <div class="contact-us">免费获取完整产品介绍资料</div>
      <div class="contact-texth1">高质量、高可信、高效率，助力仪表智能化改造</div>
      <div
        class="contact-texth2"
      >请填写下列表单或发送邮件至partner@evavisdom.com，我们的工作人员将在24小时内与您取得联系（所有关于您的个人信息我们都将保密，请放心填写）</div>
      <div class="contact-form">
        <div class="form-group">
          <input
            type="text"
            id="evaName"
            placeholder="姓名/Full Name*"
            v-model="form.evaName"
            required
          />
        </div>
        <div class="form-group">
          <input
            type="text"
            id="evaPhone"
            placeholder="电话/Phone Number*"
            v-model="form.evaPhone"
            required
          />
        </div>
        <div class="form-group">
          <input type="text" id="evaEmail" placeholder="邮箱/Email*" v-model="form.evaEmail" />
        </div>
        <div class="form-group">
          <input
            type="text"
            id="evaCompany"
            placeholder="公司名称/Company Name*"
            v-model="form.evaCompany"
            required
          />
        </div>
        <div class="form-group">
          <input
            type="text"
            id="evaPosition"
            placeholder="职位/Current Position*"
            v-model="form.evaPosition"
            required
          />
        </div>
        <div class="form-group">
          <input
            type="text"
            id="evaIndustry"
            placeholder="所属行业/Industry*"
            v-model="form.evaIndustry"
            required
          />
        </div>
        <div class="form-group2">
          <textarea
            id="evaRequirements"
            placeholder="具体需求/Specific Needs*"
            rows="6"
            v-model="form.evaRequirements"
            required
          ></textarea>
        </div>
      </div>
      <div class="button-images">
        <img src="@/assets/images/btn_submit.png" alt="Submit Information" @click="submitForm" />
      </div>
    </div> -->
    <!-- 获得客户基础信息结束 -->
  </div>
</template> 
<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
// import {bannerCanvas} from '@/libs/bannerCanvas.js'
import lottie from "vue-lottie";
import * as photoSvg from "@/assets/images/photo.json";
import * as readingSvg from "@/assets/images/reading.json";
import * as loadingSvg from "@/assets/images/loading.json";
import $ from "jquery";
import "../../assets/font/font.css";
import material from "../../libs/homeMaterial.js";
// import axios from "axios";
// let bannerDom = 'banner';
export default {
  name: "IIot",
  components: {
    swiper,
    swiperSlide,
    lottie
  },
  data() {
    return {
      form: {
        evaName: "",
        evaPhone: "",
        evaEmail: "",
        evaCompany: "",
        evaPosition: "",
        evaIndustry: "",
        evaRequirements: ""
      },
      isLoading: true,
      // value: 50181782,
      startVal: 0, //计时开始时间
      endVal: 0, //计时结束时间
      formateValue: "",
      widOver768: null,
      // bannerOption:{touchRatio:0},
      playerOptions: {
        //播放速度
        // playbackRates: [0.5, 1.0, 1.5, 2.0],
        //如果true,浏览器准备好时开始回放。
        autoplay: false,
        // 默认情况下将会消除任何音频。
        muted: false,
        // 导致视频一结束就重新开始。
        loop: false,
        // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        preload: "auto",
        language: "zh-CN",
        // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        aspectRatio: "16:9",
        // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        fluid: true,
        sources: [
          {
            //类型
            type: "video/mp4",
            //url地址
            src:
              this.widOver768 === true
                ? "https://evavisdom-static.oss-cn-beijing.aliyuncs.com/iot/com_video.mp4"
                : "https://evavisdom-static.oss-cn-beijing.aliyuncs.com/iot/com_video_x1.mp4"
          }
        ],
        //你的封面地址
        poster:
          "https://evavisdom-static.oss-cn-beijing.aliyuncs.com/iot/poster.png",
        //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        notSupportedMessage: "此视频暂无法播放，请稍后再试",
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          //全屏按钮
          fullscreenToggle: true
        }
      },
      // 核心优势
      advantageList: material.advantageList,
      advantageOption: {
        autoplay: {
          delay: 2000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        speed: 800,
        initialSlide: 1,
        loop: true,
        effect: "coverflow", //可以实现3D效果的轮播
        slidesPerView: 3,
        centeredSlides: true,
        slideToClickedSlide: true,
        coverflowEffect: {
          rotate: 0, //slide做3d旋转时Y轴的旋转角度。默认50。
          stretch: 500, //每个slide之间的拉伸值，越大slide靠得越紧。 默认0。
          depth: 400, //slide的位置深度。值越大z轴距离越远，看起来越小。 默认100。
          modifier: 2,
          slideShadows: false
        },
        // observer:true,
        // observeParents:true,
        pagination: {
          el: ".advantage-pagination",
          clickable: true
        }
      },
      // 智能AI相机
      deviceList: material.deviceList,
      deviceOption: {
        autoplay: true,
        loop: true,
        pagination: {
          el: ".device-pagination",
          clickable: true
        }
      },
      // 图像识别算法
      algorithmList: material.algorithmList,
      // 云平台
      platformList: material.platformList,
      // 技术壁垒
      rampartList: material.rampartList,
      // 解决方案
      activeResolveMenu: 0,
      resolveContent: material.resolveContent,
      currentResolveContent: material.resolveContent[0],
      miniResolveOption: {
        autoplay: {
          delay: 2000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        speed: 800,
        initialSlide: 1,
        loop: true,
        effect: "coverflow", //可以实现3D效果的轮播
        slidesPerView: 3,
        centeredSlides: true,
        coverflowEffect: {
          rotate: 0, //slide做3d旋转时Y轴的旋转角度。默认50。
          stretch: 500, //每个slide之间的拉伸值，越大slide靠得越紧。 默认0。
          depth: 400, //slide的位置深度。值越大z轴距离越远，看起来越小。 默认100。
          modifier: 2,
          slideShadows: false
        },
        // observer:true,
        // observeParents:true,
        pagination: {
          el: ".resolve-pagination",
          clickable: true
        }
      },
      newsList: material.newsList,
      newsOption: {
        slidesPerView: 3,
        spaceBetween: 60
      },
      // svg
      photoOptions: {
        animationData: photoSvg.default
      },
      anim: {},
      readingOptions: {
        animationData: readingSvg.default
      },
      anim1: {},
      loadingOptions: {
        animationData: loadingSvg.default
      },
      anim2: {}
    };
  },
  methods: {
    validatePhone(phone) {
      // 手机号码和座机号码正则表达式
      const phoneReg = /^1[3-9]\d{9}$|^([0-9]{3,4}-)?[0-9]{7,8}$/;
      return phoneReg.test(phone);
    },
    isValidEmail(email) {
      const emailRegex = /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/;
      return emailRegex.test(email);
    },

    // submitForm() {
    //   // 判断姓名和电话是否为空
    //   if (!this.form.evaName) {
    //     alert("请输入您的姓名");
    //     return;
    //   }
    //   if (!this.form.evaPhone) {
    //     alert("请输入您的电话");
    //     return;
    //   }
    //   if (!this.form.evaEmail) {
    //     alert("请输入您的邮箱");
    //     return;
    //   }
    //   if (!this.isValidEmail(this.form.evaEmail)) {
    //     alert("邮箱格式有误");
    //     return;
    //   }
    //   if (!this.validatePhone(this.form.evaPhone)) {
    //     alert("手机号码格式有误");
    //     return;
    //   }
    //   if (!this.form.evaCompany) {
    //     alert("请输入公司名称");
    //     return;
    //   }
    //   if (!this.form.evaPosition) {
    //     alert("请输入职位");
    //     return;
    //   }
    //   if (!this.form.evaIndustry) {
    //     alert("请输入行业");
    //     return;
    //   }
    //   if (!this.form.evaRequirements) {
    //     alert("请准确输入您的信息以便我们及时与您取得联系");
    //     return;
    //   }
    //   // 将表单数据发送到后端接口
    //   const requestBody = {
    //     sid: "123",
    //     pwd: "85eea08bf23348419cecd44b2b417b85",
    //     accessToken:
    //       "f5d62b06b85d40e2b34edaa1560e755bb6c85dd41b428c1ef2ccc45ab1980a53",
    //     msg: `【姓名】${this.form.evaName}\n【电话】${this.form.evaPhone}\n【邮箱】${this.form.evaEmail}\n【公司】${this.form.evaCompany}\n【职位】${this.form.evaPosition}\n【行业】${this.form.evaIndustry}\n【需求】${this.form.evaRequirements}`
    //   };

    //   axios.post("https://gw.evavision.cn/plat/dingrobot", requestBody, {
    //     headers: {
    //       webver: "v100.0.0"
    //     }
    //   });
    //   alert("提交成功");
    //   // 清空表单信息
    //   this.form.evaName = "";
    //   this.form.evaPhone = "";
    //   this.form.evaEmail = "";
    //   this.form.evaCompany = "";
    //   this.form.evaPosition = "";
    //   this.form.evaIndustry = "";
    //   this.form.evaRequirements = "";
    // },
    isReadyVideo() {
      this.isLoading = false;
      setTimeout(() => {
        let v = document.getElementById("video");
        v.muted = true;
        v.play();
        $(".home-banner .banner-text")
          .delay(300)
          .animate({ top: "45%", opacity: 1 }, 300);
        this.changeNum();
      }, 100);
    },
    // 格式化数字为三位加一逗号
    formateNum(num) {
      $(".banner-sub-title").empty();
      let str = (num + "").replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,");
      for (let i = 0; i < str.length; i++) {
        if (this.widOver768) {
          $(".banner-sub-title").append(`
            <span class="num-${i}" 
            style="float: left;line-height:66px;padding: 10px;margin: 0 15px;background:rgba(43,185,219,0.2);border:1px solid rgba(65,255,255,0.4)"
            >${str[i]}</span>`);
        } else {
          $(".banner-sub-title").append(`
            <span class="num-${i}" 
            style="float: left;line-height:32px;padding: 0.04rem;margin: 0 0.07rem;background:rgba(43,185,219,0.2);border:1px solid rgba(65,255,255,0.4)"
            >${str[i]}</span>`);
        }
        $(".num-0").css("margin-left", 0);
        if (str[i] == ",") {
          $(".num-" + i).css({
            margin: 0,
            background: "transparent",
            border: 0
          });
        }
      }
    },
    animateNum(step) {
      this.formateNum(this.startVal);
      let timer = setInterval(() => {
        this.startVal += step;
        this.formateNum(this.startVal);
        if (this.startVal >= this.endVal) {
          clearInterval(timer);
          this.startVal = this.endVal;
          this.formateNum(this.startVal);
        }
      }, 80);
    },
    selectResolveMenu(ind) {
      $(".resolve-wid768").css("opacity", 0);
      this.activeResolveMenu = ind;
      this.currentResolveContent = this.resolveContent[ind];
      $(".resolve-wid768").animate({ opacity: 1 }, 600);
    },
    photoAnimation: function(anim) {
      this.anim = anim;
    },
    readingAnimation: function(anim) {
      this.anim1 = anim;
    },
    loadingAnimation: function(anim) {
      this.anim2 = anim;
    },
    handleScroll(dom, itemName, direction, distance) {
      // console.log('handleScroll#######')
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      let offsetTop =
        document.querySelector(dom).parentElement.parentElement.offsetTop -
        distance;
        // console.log('scrollTop',scrollTop)
        // console.log('offsetTop',offsetTop)
      if (scrollTop >= offsetTop) {
        switch (direction) {
          case "up":
            this.slideToUp(itemName);
            if (dom == ".cloud") {
              $(".platform-detail")
                .delay(400)
                .animate({ top: 0, opacity: 1 }, 300);
            }
            break;
          case "left":
            this.slideToLeft(dom);
            break;
          case "right":
            this.slideToRight(dom);
            break;
          default:
            break;
        }
      }
    },
    slideToUp(itemName) {
      $.each(itemName, function(ind, item) {
        $(item)
          .delay(100 * (ind + 1))
          .animate({ top: 0, opacity: 1 }, 300);
      });
    },
    slideToRight(dom) {
      $(dom).animate({ right: 0, opacity: 1 }, 300);
    },
    slideToLeft(dom) {
      $(dom).animate({ left: 0, opacity: 1 }, 300);
    },
    getWid() {
      let wid =
        document.documentElement.clientWidth || document.body.clientWidth;
      this.widOver768 = wid <= 768 ? false : true;
      if (this.widOver768 === false) {
        this.changeNum();
        setTimeout(() => {
          $(".home-banner .banner-text")
            .delay(300)
            .animate({ top: "50%", opacity: 1 }, 300);
        }, 0);
      }
    },
    changeNum() {
      let num = localStorage.getItem("num");
      if (!num || (num && Number(num) < 64455428)) {
        localStorage.setItem("num", 64455428);
        this.startVal = 64455428;
        this.endVal = this.startVal + 1875;
        this.animateNum(8);
      } else {
        let storeTime = localStorage.getItem("time", null);
        if (
          storeTime &&
          (new Date().getTime() - storeTime) / 1000 / 3600 / 24 < 1
        ) {
          this.startVal = Number(num);
        } else {
          let nday = Math.floor(
            (new Date() - new Date("2019-11-10")) / 1000 / 3600 / 24
          );
          this.startVal =
            Number(num) + nday * Math.round(Math.random() * 40000);
          localStorage.setItem("num", this.startVal);
          localStorage.setItem("time", new Date().getTime());
        }
        this.endVal = this.startVal + 1875;
        this.animateNum(8);
      }
      setInterval(() => {
        let n = Math.round(Math.random() * 40000);
        this.startVal = Number(localStorage.getItem("num"));
        this.startVal += n;
        localStorage.setItem("num", this.startVal);
        this.endVal = this.startVal + 1875;
        this.animateNum(8);
      }, 24 * 3600 * 1000);
    },
    routeToNewsDetail(id) {
      this.$router.push({ name: "news" + material.newsList[id].index });
    }
  },
  computed: {},
  mounted() {
    this.getWid();
    // this.changeNum();
    window.addEventListener("scroll", () => {
      if (this.$route.name == "IIot") {
        this.widOver768
          ? this.handleScroll(".sys-graphic", $(".graphic-item"), "up", 600)
          : this.handleScroll(".sys-img", $(".sys-img"), "up", 600);
        this.handleScroll(".device-charactor", "", "left", 600);
        this.handleScroll(".algorithm-charactor", "", "right", 600);
        this.handleScroll(".cloud", $(".cloud .platform-item"), "up", 500);
        this.handleScroll(".rampart", $(".rampart .platform-item"), "up", 700);
      }
    });
    window.addEventListener("resize", this.getWid);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  }
};
</script>
<style lang="less" scoped>
@import "../../assets/styles/iiot.less";
</style>
<style>
.advantage .swiper-slide .flex-col-align {
  opacity: 0.5;
}
.advantage .swiper-slide-active .flex-col-align {
  opacity: 1;
}
.advantage-pagination .swiper-pagination-bullet,
.resolve-pagination .swiper-pagination-bullet {
  width: 44px;
  height: 4px;
  opacity: 0.4;
  background: #fff;
  border-radius: 0;
}
@media screen and (max-width: 768px) {
  .advantage-pagination .swiper-pagination-bullet,
  .resolve-pagination .swiper-pagination-bullet {
    width: 0.44rem;
    height: 0.04rem;
  }
}
.advantage-pagination .swiper-pagination-bullet-active,
.resolve-pagination .swiper-pagination-bullet-active {
  opacity: 1;
}
.device-pagination .swiper-pagination-bullet {
  width: 58px;
  height: 4px;
  margin: 0 !important;
  background: #788094;
  border-radius: 0;
}
.device-pagination .swiper-pagination-bullet-active {
  background: #3e86f7;
}

.hints {
  color: #fff;
  font-size: 20px;
  letter-spacing: 12px;
  @media screen and (max-width: 768px) {
    width: 7.5rem;
    font-size: 0.2rem;
    letter-spacing: 0.08rem;
    padding: 0.5rem;
  }
}

.banner-mask{
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0,0,0,.5);
}

/* // 内容分块 */
.part{
  padding: 90px 0;
  overflow: hidden;
  @media screen and (max-width:768px){
    padding: 0.4rem;
  }
}
.part-dark-bg{
  background: #1D212E;
}
.part-light-bg{
  background: #fff;
}
.part-gray-bg{
  background: #F8F9FE;
}
/* // 内容分块title */
.part-title{
  line-height: 32px;
  font-size: 32px;
  color: #383E4A;
  @media screen and (max-width:768px){
    line-height: 18px;
    font-size: 18px; 
  }
}
.point-box{
  height: 42px;
  margin: 0 20px 0 16px;
  position: relative;
  @media screen and (max-width:768px){
    height: 0.42rem;
    margin: 0 0.2rem 0 0.16rem;
  }
  .split-point{
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #383E4A;
    @media screen and (max-width:768px){
      width: 0.05rem;
      height: 0.05rem;
    }
  }
  .split-point-white{
    background: #fff;
  }
}
/* // 内容分块描述 */
.part-description{
  line-height: 20px;
  margin-top: 48px;
  font-size: 20px;
  text-align: center;
  color: #485064;
  @media screen and (max-width:768px){
    line-height: 2;
    margin-top: 0.48rem;
    font-size: 14px;
  }
}
/* // 不同背景下颜色的字 */
.light-bg-text{
  color: #3E86F7;
}
.dark-bg-text{
  color: #24D5FF;
}
.dark-bg-text-white{
  color: #fff;
}
/* // title下装饰线 */
.decoration-line{
  width: 40px;
  height: 5px;
  margin: 30px auto 0;
  background-image: linear-gradient(to right,#3E86F7,#24D5FF);
  @media screen and (max-width:768px){
    width: 0.4rem;
    height: 0.05rem;
    margin: 0.3rem auto 0;
  }
}
/* // 内容分块主内容区 */
.main-content{
  margin-top: 78px;
  @media screen and (max-width:768px){
    margin-top: 0.4rem;
  }
}

.main-content-item{
  display: flex;
  margin-bottom: 50px;
  text-align: left;
  .main-content-text{
    margin-left: 36px;
  }
  @media screen and (max-width:768px){
    margin-bottom: 0.5rem;
    .main-content-text{
      margin-left: 0.36rem;
    }
  }
}

.item-pic-box{
  width: 46px;
  height: 46px;
  position: relative;
  img{
    height: 100%;
  }
  @media screen and (max-width: 768px){
    width: 30px;
    height: 30px;
  }
}

/* // 了解详情按钮 */
.see-detail{
  width: 185px;
  height: 92px;
  line-height: 68px;
  position: relative;
  font-size: 24px;
  cursor: pointer;
  img{
    width: 185px;
  }
  p{
    color: #fff;
    position: relative;
    a{
      color: #fff;
    }
  }
  @media screen and (max-width:768px){
    width: 1.85rem;
    height: 0.92rem;
    line-height: 0.68rem;
    margin: 0 auto;
    font-size: 0.24rem;
    img{
      width: 1.85rem;
    }
  }
}
</style>
