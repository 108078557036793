//热门产品
const hotList = {
    1: {
        categoriesId: 1, name: '人工智能产品', route: 'IIot', list: [
            { id: "yd_10001", name: "羿读智能AI相机 A系- Argus", route: 'device',hash:"argus", image: "/images/IIot/device/argus.png" },
            { id: "yd_10002", name: "羿读智能AI相机 D系- Doris", route: 'device',hash:"doris",  image: "/images/IIot/device/doris.png" },
            { id: "yd_10003", name: "羿读智能AI相机 H系- Horus", route: 'device',hash:"horus",  image: "/images/IIot/device/horus.png" },
            { id: "yd_10004", name: "羿读云平台", route: 'platform',route: 'platform',hash:"",  image: "/images/header/sys-service.png" },
            { id: "yd_10005", name: "图像识别算法", route: 'algorithm',route: 'algorithm',hash:"",  image: "/images/algorithm/sys-theory.png" },
        ]
    },
    2: {
        categoriesId: 2, name: '地球科学仪器', route: 'geoscience', list: [
            { id: "tc_10001", name: "光纤传感器", image: "/images/ESci/AcousticSensor/ULTIMADTS.png" },
            { id: "tc_10002", name: "应急救援装备", image: "/images/ESci/EmergencyRescueequipment/yj1.png" },
            { id: "tc_10003", name: "探地雷达", image: "/images/ESci/GroundPenetratingRadar/SPIDAR.png" },
            { id: "tc_10004", name: "磁法勘探设备", image: "/images/ESci/MagneticExplorationEquipment/GSM-19T.png" },
            { id: "tc_10005", name: "电法勘探设备", image: "/images/ESci/ElectricalExplorationEquipment/GEM-2UAV.png" },
            { id: "tc_10006", name: "宽频带地震仪", image: "/images/ESci/BroadbandSeismograph/BlueSeis.png" },
            { id: "tc_10007", name: "强震动加速度仪", image: "/images/ESci/StrongMotionAccelerometer/FORTIS.png" },
            { id: "tc_10008", name: "数据采集器", image: "/images/ESci/StrongMotionAccelerometer/Affinity.png" },
            { id: "tc_10009", name: "海底地震仪", image: "/images/ESci/SubmarineSeismograph/Maris.png" },
            { id: "tc_10010", name: "井中地震仪", image: "/images/ESci/BoreholeSeismograph/3TBorehole.png" },
            { id: "tc_10011", name: "重力测量仪", image: "/images/ESci/Gravimeter/AQG.png" },
            { id: "tc_10012", name: "放射性测量", image: "/images/ESci/Radioactivity/VARIO.png" },
        ]
    }
}

// 产品清单
const productsList = [
    {
        categoriesId: 1, name: '人工智能产品', list: [
            { subcategoryId: "yd_10001", name: "羿读智能AI相机 A系- Argus", image: "/images/IIot/device/argus2.png" },
            { subcategoryId: "yd_10002", name: "羿读智能AI相机 D系- Doris", image: "/images/IIot/device/doris2.png" },
            { subcategoryId: "yd_10003", name: "羿读智能AI相机 H系- Horus", image: "/images/IIot/device/horus2.png" },

        ]
    },
    {
        categoriesId: 2, name: '地球科学仪器', list: [
            {
                subcategoryId: "tc_10001", name: "光纤传感器", image: "/images/ESci/AcousticSensor/ULTIMADTS.png",route: 'AcousticSensor', list: [
                    { productId: "tc_10001_0001", name: "热脉冲系统", image: "/images/ESci/AcousticSensor/HeatPulseSystem.png" },
                    { productId: "tc_10001_0002", name: "XT-DTS™", image: "/images/ESci/AcousticSensor/XT-DTS.png" },
                    { productId: "tc_10001_0003", name: "ULTIMA™ DTS", image: "/images/ESci/AcousticSensor/ULTIMADTS.png" },
                    { productId: "tc_10001_0004", name: "Carina® 传感系统", image: "/images/ESci/AcousticSensor/CarinaSensingSystem.png" },
                    { productId: "tc_10001_0005", name: "iDAS™ 智能分布式声学传感器", image: "/images/ESci/AcousticSensor/iDAS.png" },
                    { productId: "tc_10001_0006", name: "iDAS-MG™ 多标距智能分布式声学传感器", image: "/images/ESci/AcousticSensor/iDAS-MG.png" },
                    { productId: "tc_10001_0007", name: "ULTIMATM™-S高分辨率温度传感器", image: "/images/ESci/AcousticSensor/ULTIMATM-S.png" },
                    { productId: "tc_10001_0008", name: "iDSS™智能分布式应变传感器", image: "/images/ESci/AcousticSensor/iDSS.png" },
                ]
            },
            {
                subcategoryId:"tc_10002", name: "应急救援装备", image: "/images/ESci/EmergencyRescueequipment/yj1.png",route: 'EmergencyRescueequipment', list: [
                    { productId: "tc_10002_0001", name: "坍塌体超前探测装备", image: "/images/ESci/EmergencyRescueequipment/yj1.png" }
                ]
            },
            {
                subcategoryId: "tc_10003", name: "探地雷达", image: "/images/ESci/GroundPenetratingRadar/SPIDAR.png",route: 'GroundPenetratingRadar', list: [
                    { productId: "tc_10003_0001", name: "TR1000 钢筋混凝土探测雷达", image: "/images/ESci/GroundPenetratingRadar/TR1000.png" },
                    { productId: "tc_10003_0002", name: "Noggin250 管线探测雷达（暗管探测仪）", image: "/images/ESci/GroundPenetratingRadar/Noggin250.png" },
                    { productId: "tc_10003_0003", name: "Noggin 便携式大深度地质雷达", image: "/images/ESci/GroundPenetratingRadar/NogginPortable.png" },
                    { productId: "tc_10003_0004", name: "Ultra PE 专业级大深度地质雷达", image: "/images/ESci/GroundPenetratingRadar/UltraPE.png" },
                    { productId: "tc_10003_0005", name: "SPIDAR 三维多通道阵列雷达", image: "/images/ESci/GroundPenetratingRadar/SPIDAR.png" },
                    { productId: "tc_10003_0006", name: "pulseEKKO专业分离式数字雷达", image: "/images/ESci/GroundPenetratingRadar/pulseEKKO.png" },
                    { productId: "tc_10003_0007", name: "IceMap冰雪厚度水深雷达", image: "/images/ESci/GroundPenetratingRadar/IceMap.png" },
                ]
            },
            {
                subcategoryId: "tc_10004", name: "磁法勘探设备", image: "/images/ESci/MagneticExplorationEquipment/GSM-19T.png",route: 'MagneticExplorationEquipment', list: [
                    { productId: "tc_10004_0001", name: "KAPPA KAPPA 多深度磁化率仪", image: "/images/ESci/MagneticExplorationEquipment/KAPPA.png" },
                    { productId: "tc_10004_0002", name: "SM-20 磁化率仪", image: "/images/ESci/MagneticExplorationEquipment/SM-20.png" },
                    { productId: "tc_10004_0003", name: "GSM-19T质子磁力仪", image: "/images/ESci/MagneticExplorationEquipment/GSM-19T.png" },
                ]
            },
            {
                subcategoryId: "tc_10005", name: "电法勘探设备", image: "/images/ESci/ElectricalExplorationEquipment/GEM-2UAV.png",route: 'ElectricalExplorationEquipment', list: [
                    { productId: "tc_10005_0001", name: "GEM-2UAV 多频电磁探测仪", image: "/images/ESci/ElectricalExplorationEquipment/GEM-2UAV.png" },
                    { productId: "tc_10005_0002", name: "GEM-2多频电磁探测仪", image: "/images/ESci/ElectricalExplorationEquipment/GEM-2.png" },
                ]
            },
            {
                subcategoryId: "tc_10006", name: "宽频带地震仪", image: "/images/ESci/BroadbandSeismograph/BlueSeis.png",route: 'BroadbandSeismograph', list: [
                    { productId: "tc_10006_0001", name: "CERTIS 流动式三分量宽频带地震计", image: "/images/ESci/BroadbandSeismograph/CERTIS.png" },
                    { productId: "tc_10006_0002", name: "Güralp 3ESPCDE 便携式一体化三分量宽频带地震仪", image: "/images/ESci/BroadbandSeismograph/3ESPCDE.png" },
                    { productId: "tc_10006_0003", name: "MINIMUS MINIMUS+ 智能化地震数据采集器", image: "/images/ESci/BroadbandSeismograph/MINIMUS.png" },
                    { productId: "tc_10006_0004", name: "Güralp 40TDE 便携式一体化宽频带数字地震仪", image: "/images/ESci/BroadbandSeismograph/40TDE.png" },
                    { productId: "tc_10006_0005", name: "3T-120PH  三分量浅井型宽频带地震计", image: "/images/ESci/BroadbandSeismograph/3T-120PH.png" },
                    { productId: "tc_10006_0006", name: "BlueSeis-3A 旋转地震计", image: "/images/ESci/BroadbandSeismograph/BlueSeis.png" },
                    { productId: "tc_10006_0007", name: "Certimus一体化地震仪", image: "/images/ESci/BroadbandSeismograph/Certimus.png" },
                    { productId: "tc_10006_0008", name: "Güralp 40T 系列地震仪", image: "/images/ESci/BroadbandSeismograph/40T.png" },
                    { productId: "tc_10006_0009", name: "Güralp 6T 系列地震仪", image: "/images/ESci/BroadbandSeismograph/6T.png" },
                    { productId: "tc_10006_0010", name: "Güralp 3T 系列地震仪", image: "/images/ESci/BroadbandSeismograph/3T.png" },
                    { productId: "tc_10006_0011", name: "Güralp 3ESPC 系列地震仪", image: "/images/ESci/BroadbandSeismograph/3ESPC.png" },
                    { productId: "tc_10006_0012", name: "6TD 轻便型一体化三分量宽频带地震仪", image: "/images/ESci/BroadbandSeismograph/6TD.png" },
                ]
            },
            {
                subcategoryId: "tc_10007", name: "强震动加速度仪", image: "/images/ESci/StrongMotionAccelerometer/FORTIS.png",route: 'StrongMotionAccelerometer', list: [
                    { productId: "tc_10007_0001", name: "Güralp FORTIS 强震动加速度计", image: "/images/ESci/StrongMotionAccelerometer/FORTIS.png" },
                ]
            },
            {
                subcategoryId: "tc_10008", name: "数据采集器", image: "/images/ESci/StrongMotionAccelerometer/Affinity.png",route: 'IntegratedDigitiser', list: [
                    { productId: "tc_10008_0001", name: "DM24数据采集器", image: "/images/ESci/StrongMotionAccelerometer/DM24.png" },
                    { productId: "tc_10008_0002", name: "Affinity数据采集器", image: "/images/ESci/StrongMotionAccelerometer/Affinity.png" },
                ]
            },
            {
                subcategoryId: "tc_10009", name: "海底地震仪", image: "/images/ESci/SubmarineSeismograph/Maris.png",route: 'SubmarineSeismograph', list: [
                    { productId: "tc_10009_00001", name: "Orcus海底地震仪", image: "/images/ESci/SubmarineSeismograph/Orcus.png" },
                    { productId: "tc_10009_00002", name: "Maris海底地震仪", image: "/images/ESci/SubmarineSeismograph/Maris.png" },
                    { productId: "tc_10009_00003", name: "Aquarius海底地震仪", image: "/images/ESci/SubmarineSeismograph/Aquarius.png" },
                    { productId: "tc_10009_00004", name: "AQUARIUS 声学遥测海底地震仪（OBS）", image: "/images/ESci/SubmarineSeismograph/OBS.png" },
                ]
            },
            {
                subcategoryId: "tc_10010", name: "井中地震仪", image: "/images/ESci/BoreholeSeismograph/3TBorehole.png",route: 'BoreholeSeismograph', list: [
                    { productId: "tc_10010_0001", name: "Radian井中地震仪", image: "/images/ESci/BoreholeSeismograph/RADIAN.png" },
                    { productId: "tc_10010_0002", name: "5T深井版", image: "/images/ESci/BoreholeSeismograph/5T.png" },
                    { productId: "tc_10010_0003", name: "3T深井版", image: "/images/ESci/BoreholeSeismograph/3TBorehole.png" },
                    { productId: "tc_10010_0004", name: "3T、5T组合深井版", image: "/images/ESci/BoreholeSeismograph/3T5T.png" },
                    { productId: "tc_10010_0005", name: "RADIAN 井中三分量宽频带数字地震计", image: "/images/ESci/BoreholeSeismograph/RADIAN.png" },
                ]
            },
            {
                subcategoryId: "tc_10011", name: "重力测量仪", image: "/images/ESci/Gravimeter/AQG.png",route: 'IIot',route: 'Gravimeter', list: [
                    { productId: "tc_10011_0001", name: "量子绝对重力仪", image: "/images/ESci/Gravimeter/AQG.png" },
                    { productId: "tc_10011_0002", name: "LG-1 重力仪", image: "/images/ESci/Gravimeter/LG-1.png" },

                ]
            },
            {
                subcategoryId: "tc_10012", name: "放射性测量", image: "/images/ESci/Radioactivity/VARIO.png",route: 'Radioactivity', list: [
                    { productId: "tc_10012_0001", name: "VARIO伽玛能谱仪", image: "/images/ESci/Radioactivity/VARIO.png" },
                ]
            },
        ]
    }
]

export default {
    hotList,
    productsList,

}