<template>
  <div class="water-wrap">
    <!-- banner 开始 -->
    <div class="page-banner" :style="{background: '#1D212E url('+require('@/assets/images/water/banner.jpg')+') no-repeat', backgroundSize: 'cover'}">
      <div class="banner-mask"></div>
      <div class="valid-area">
        <div class="align-center">
          <p class="page-banner-title">智慧水务解决方案</p>
          <p class="page-banner-description" v-if="widOver768">解决高危高难场景抄表难题，避免估抄、漏抄、错抄，</p>
          <p class="page-banner-description" v-if="widOver768">降低产销差，提升运营管控能力</p>
          <p class="page-banner-description" v-if="!widOver768">解决高危高难场景抄表难题，</p>
          <p class="page-banner-description" v-if="!widOver768">避免估抄、漏抄、错抄，降低产销差，</p>
          <p class="page-banner-description" v-if="!widOver768">提升运营管控能力</p>
        </div>
      </div>
    </div>
    <!-- banner 结束 -->
    <!-- 智慧水务 开始 -->
    <div class="part part-light-bg letter-space">
      <div class="valid-area">
        <!-- 标题 -->
        <p class="part-title">智慧水务</p>
        <!-- 装饰线 -->
        <p class="decoration-line"></p>
        <!-- 主内容区 -->
        <div class="main-content layout-flex reslove-case-main" v-if="widOver768">
          <div class="resolve-case align-right">
            <div class="resolve-case-item">
              <p class="resolve-case-title">便捷</p>
              <p class="resolve-case-description">居民线上便捷缴费</p>
              <p class="resolve-case-description">替代高难度抄表，如深水井下、隧道内、狭小空间、居民住宅内等</p>
            </div>
            <div class="resolve-case-item">
              <p class="resolve-case-title">降本</p>
              <p class="resolve-case-description">节约智能化改造成本，无需断水施工</p>
              <p class="resolve-case-description">用水量监控，避免估抄、漏抄、人情水费，经济损失有据可查</p>
            </div>
          </div>
          <div class="resolve-case-pic">
            <img src="@/assets/images/water/water.png">
          </div>
          <div class="resolve-case align-left">
            <div class="resolve-case-item">
              <p class="resolve-case-title">安全</p>
              <p class="resolve-case-description">替代高危环境抄表，确保人员生命安全</p>
              <p class="resolve-case-description">供水管网监控，监测水表工作状态与流量，预防爆管引发的安全事故</p>
            </div>
            <div class="resolve-case-item">
              <p class="resolve-case-title">增收</p>
              <p class="resolve-case-description">分析产销差率，及时发现跑冒滴漏区域，提出整改建议，最终实现水费增收</p>
            </div>
          </div>
        </div>
        <div class="main-content reslove-case-main-768" v-if="!widOver768">
          <div class="reslove-img">
            <img src="@/assets/images/water/water-res.png">
          </div>
          <div class="resolve-case-768-item">
            <p class="resolve-case-768-title">便捷</p>
            <p class="resolve-case-768-description">居民线上便捷缴费</p>
            <p class="resolve-case-768-description">替代高难度抄表，如深水井下、隧道内、狭小空间、居民住宅内等</p>
          </div>
          <div class="resolve-case-768-item">
            <p class="resolve-case-768-title">安全</p>
            <p class="resolve-case-768-description">替代高危环境抄表，确保人员生命安全</p>
            <p class="resolve-case-768-description">供水管网监控，监测水表工作状态与流量，预防爆管引发的安全事故</p>
          </div>
          <div class="resolve-case-768-item">
            <p class="resolve-case-768-title">降本</p>
            <p class="resolve-case-768-description">节约智能化改造成本，无需断水施工</p>
            <p class="resolve-case-768-description">用水量监控，避免估抄、漏抄、人情水费，经济损失有据可查</p>
          </div>
          <div class="resolve-case-768-item">
            <p class="resolve-case-768-title">增收</p>
            <p class="resolve-case-768-description">分析产销差率，及时发现跑冒滴漏区域，提出整改建议，最终实现水费增收</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 智慧水务 结束 -->
    <!-- 应用案例 开始 -->
    <div class="part part-gray-bg letter-space">
      <div class="valid-area">
        <!-- 标题 -->
        <p class="part-title">应用案例</p>
        <!-- 装饰线 -->
        <p class="decoration-line"></p>
        <!-- 主内容区 -->
        <div class="main-content">
          <div class="case">
            <div class="case-info">
              <p><span>客户&nbsp;&nbsp;—&nbsp;&nbsp;</span>吉林省某自来水</p>
              <p><span>时间&nbsp;&nbsp;—&nbsp;&nbsp;</span>2018年12月</p>
              <p><span>表具&nbsp;&nbsp;—&nbsp;&nbsp;</span>供水管网水表、工商业水表、居民水表（千余台）</p>
              <p><span>效果&nbsp;&nbsp;—&nbsp;&nbsp;</span>解决高难度和高危场景下的抄表难题，实现用水大户重点监测，一键导出用水月报，及时发现偷漏损等异常用水行为，经济损失有据可查。</p>
            </div>
            <div class="case-pic">
              <img src="@/assets/images/water/case-img.png">
            </div>
          </div>
          <div class="case-app">
            <div class="case-app-box">
              <div class="case-data">
                <p style="width: 34%">（水表详细数据）</p>
                <p style="width: 32%">（水表用量报表）</p>
                <p style="width: 27%">（水表历史用量）</p>
              </div>
              <img class="case-data-pic" src="@/assets/images/water/case.png">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 应用案例 结束 -->
    <!-- 安装照片 开始 -->
    <div class="part letter-space" :style="{background:'#2B2E46 url('+require('@/assets/images/home/advantage-bg.png')+') no-repeat 90px 90px / 90% 90%'}">
      <div class="valid-area">
        <!-- 标题 -->
        <p class="part-title dark-bg-text-white">安装照片</p>
        <!-- 装饰线 -->
        <p class="decoration-line"></p>
        <div class="main-content">
          <img src="@/assets/images/water/install.png" style="width: 100%;display: block;margin:0 auto;">
        </div>
      </div>
    </div>
    <!-- 安装照片 结束 -->
  </div>
</template>

<script>
  import $ from 'jquery'
  export default {
    name: 'water',
    data(){
      return {
        widOver768:false,
      }
    },
    methods: {
      appear(){
        if(this.widOver768){
          $('.resolve-case-item').eq(0).delay(200).animate({"right":0,"opacity":1},600);
          $('.resolve-case-item').eq(2).delay(400).animate({"left":0,"opacity":1},600);
          $('.resolve-case-item').eq(1).delay(600).animate({"right":0,"opacity":1},600);
          $('.resolve-case-item').eq(3).delay(800).animate({"left":0,"opacity":1},600);
        }else{
          $('.resolve-case-768-item').eq(0).delay(200).animate({"right":0,"opacity":1},600);
          $('.resolve-case-768-item').eq(2).delay(400).animate({"left":0,"opacity":1},600);
          $('.resolve-case-768-item').eq(1).delay(600).animate({"right":0,"opacity":1},600);
          $('.resolve-case-768-item').eq(3).delay(800).animate({"left":0,"opacity":1},600);
        }
      },
      handleScroll(){
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        let offsetTop = document.querySelector('.case-app').parentElement.parentElement.offsetTop - 300
        if(scrollTop >= offsetTop){
          $('.case-app').animate({'top':0,'opacity':1},300)
        }
      },
      getWid(){
        let wid = document.documentElement.clientWidth || document.body.clientWidth
        this.widOver768 = wid <= 768 ? false : true;
      },
    },
    mounted(){
      this.getWid();
      this.appear();
      window.addEventListener('scroll',()=>{
        if(this.$route.name=='water'){
          this.handleScroll()
        }
      });
      window.addEventListener('resize',this.getWid);
    },
    updated(){
      this.appear();
    }
  }
</script>

<style lang="less" scoped>
  .page-banner{
    .valid-area{
      height: 100%;
    }
    .align-center{
      width: 100%;
      left: 0;
      transform: translate(0,-50%);
      @media screen and (max-width:768px){
        left: 50%;
        transform: translate(-50%,-50%);
      }
    }
  }
</style>
