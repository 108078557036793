<template>
  <!-- 井中地震仪 -->
  <div>
    <div v-for="(product, index) in products" :key="index">
      <div v-if="product.id === productId" class="product-details">
        <div class="product-top">
          <h2 v-html="product.name"></h2>
          <p class="product-description" v-html="product.description"></p>
        </div>
        <div
          v-if="productId === 'tc_10010_0001' || productId === 'tc_10010_0002' || productId === 'tc_10010_0003'"
          class="carousel-container"
        >
          <el-carousel :interval="3000" autoplay style="width: 400px;">
            <!-- 轮播图内容 -->
            <el-carousel-item v-for="(item, index) in product.carouselItems" :key="index">
              <img :src="item.src" alt="轮播图" />
            </el-carousel-item>
          </el-carousel>
        </div>
        <div v-if="productId === 'tc_10010_0004'|| productId === 'tc_10010_0005'" class="carousel-container">
          <img :src="product.carouselItems" style="width: 400px;" />
        </div>
      </div>
      <div v-if="product.id === productId" class="parameter-vbom">
        <div class="parameter-title">
          <h3>产品参数</h3>
          <div class="separator"></div>
        </div>
        <div v-if="product.id === productId" class="image-gallery">
          <p v-html="product.parameter"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["productId"],
  data() {
    return {
      products: [
        {
          id: "tc_10010_0003",
          name: "3T深井版",
          description:
            "3TB系统是为直径为99 - 203mm的井眼设计的，3TB模拟传感器可以与DM24深井数字化仪和EAM数据采集模块相结合，在单个井眼内构建一个完全网络验证的数字仪器系统。<br>该仪器配备了过载保护装置和应变释放机构，以消除电缆运动对仪器中的传感器造成的影响，灵活的模块化设计提供了宽泛的安装可能性。<br>生产厂家：Güralp Systems Ltd. <br>应用：垂直地震剖面；微地震监测；稳健的地下速度建模；远震地震监测；禁止核试验条约监督",
          carouselItems: [
            { src: require("@/assets/images/ESci/BoreholeSeismograph/B1.jpg") },
            {
              src: require("@/assets/images/ESci/BoreholeSeismograph/B1.1.jpg")
            }
          ],
          parameter:
            "·&nbsp;&nbsp; 用一个传递函数覆盖完整的地震谱。<br>·&nbsp;&nbsp; 3TB系列提供120秒或360秒长周期角的标准频率响应，其他选项可定制。<br>·&nbsp;&nbsp; 混合速度-加速度响应，提供动态范围。<br>·&nbsp;&nbsp; 单爪锁孔，适用于直径为99 - 203mm的井眼，或可用砂充填，以减少对流对仪器产生的影响<br>·&nbsp;&nbsp; 防水和耐用的O形密封圈<br>·&nbsp;&nbsp; 内置倾角仪选项，用于仪器倾角状态检查<br>·&nbsp;&nbsp; 操作倾斜容限高达2.5º，可将其增加到12.5º"
        },
        {
          id: "tc_10010_0002",
          name: "5T深井版",
          description:
            "5TB配备了一个加速度计，用于深井强运动研究。适用于直径为99 - 203mm的套管井三轴模拟传感器可以与深井或地表数字化仪器相结合，建立一个完全网络化的综合深井监测系统。该仪器配备了过载保护装置和应变释放机构，以消除电缆运动对仪器中的传感器造成的影响。",
          carouselItems: [
            { src: require("@/assets/images/ESci/BoreholeSeismograph/B2.jpg") },
            {
              src: require("@/assets/images/ESci/BoreholeSeismograph/B2.2.jpg")
            }
          ],
          parameter:
            "性能特点：<br>·&nbsp;&nbsp; 从DC到100Hz (200Hz可选)的平坦加速度输出<br>·&nbsp;&nbsp; 直径为89毫米，安装时可将锁孔插入直径为99至203毫米的套管孔中<br>·&nbsp;&nbsp; 浅井安装直径76毫米，无锁孔<br>·&nbsp;&nbsp; 防水和耐用的O形密封圈<br>·&nbsp;&nbsp; 适合安装，可用砂充填，以减少对流对仪器产生的影响<br>·&nbsp;&nbsp; 双输出(高、低增益)和可选的高/低通滤波器<br>·&nbsp;&nbsp; 可选电子罗盘模块，用于确定井下仪器状态<br>·&nbsp;&nbsp; 远程直流偏置归零"
        },
        {
          id: "tc_10010_0001",
          name: "Radian井中地震仪",
          description:
            "高性能地下宽频带地震仪，具有通用性，可在任何角度工作<br>Güralp Radian是一种三轴力反馈宽频带数字地震仪，为浅井和深井部署设计。<br>Radian的超宽频率响应范围在120秒到200赫兹之间，这使得Radian非常适合各种尺度的地震监测，特别是在高文化噪声地区。为了增加灵活性，可以远程配置高通频率角，以适应部署环境。<br>Radian直径仅55毫米，是理想的地下数字地震仪，根据您的应用，Radian可以通过加速度响应或速度响应来构建。<br>速度响应增加了监测长周期弱地震动的长周期增益。<br>加速度响应随频率增加增益，用于微地震监测或诱发地震活动检测等应用。<br>Radian的主动反馈传感器确保在仪器带宽范围内的振幅和相位响应一致。<br>Radian系统包括一个地面接口，提供先进的数据处理能力和软件通信。数据以行业标准miniSEED格式在本地保存，并使用许多标准协议(如SEEDlink、GDI-link和GCF)进行远程流媒体。<br>集成的网络连接，可使用我们的免费Güralp Discovery软件或通过标准的web浏览器远程控制地面接口单元。<br>生产厂家：Güralp Systems Ltd.<br>应用：<br>1.浅井型：用于余震和火山动荡监测的快速部署；噪声环境下的微地震监测；能源开采或储存的法规遵从性监测；准确记录由当地事件引起的强烈地面震动；永久区域和全球地震台网；冰川中冰震的记录<br>2.深井型：被动的微地震监测；垂直地震剖面(VSP)；中强远震地震监测；补充密集曲面阵列",
          carouselItems: [
            { src: require("@/assets/images/ESci/BoreholeSeismograph/B3.png") },
            {
              src: require("@/assets/images/ESci/BoreholeSeismograph/B3.3.png")
            },
            {
              src: require("@/assets/images/ESci/BoreholeSeismograph/B3.3.3.png")
            }
          ],
          parameter:
            "·&nbsp;&nbsp; 120秒到200赫兹<br>·&nbsp;&nbsp; 主动反馈传感器的振幅和相位一致响应高达200赫兹<br>·&nbsp;&nbsp; 速度或加速度响应可用(订购时选择)<br>·&nbsp;&nbsp; 内部24位数字转换器<br>·&nbsp;&nbsp; STA / LTA触发<br>·&nbsp;&nbsp; 不锈钢外壳<br>·&nbsp;&nbsp; 远程质量定心<br>·&nbsp;&nbsp; 兼容Windows、Linux和macOS<br>·&nbsp;&nbsp; 内部MEMS加速度计和磁力计<br>·&nbsp;&nbsp; 从GNSS (GPS、GLONASS或北斗)或PTP(精密时间协议)授时源中选择<br>·&nbsp;&nbsp; 蓝牙(Android、iOS) App安装完整性检查<br>· &nbsp;&nbsp;数据输出在多个文件格式选项<br>· &nbsp;&nbsp;IP68 - 2000米深度压力下永久浸没影响的防护(超过100米深度部署需要光缆)"
        },
        {
          id: "tc_10010_0005",
          name: "RADIAN 井中三分量宽频带数字地震计",
          description:
            "尺寸小、测量范围大、通用性强的地震计<br>Radian可以安装于55毫米直径的钻井中。它的主要特点包括：<br>·&nbsp;&nbsp; 仪器可以以任意角度进行工作，利用蓝牙连接便可进行快速、低成本的安装<br>·&nbsp;&nbsp; 数据和仪器管理采用行业标准方式<br>·&nbsp;&nbsp; 标准配置集成了三分量宽频带地震计、加速度计和磁力计",
          carouselItems: require("@/assets/images/ESci/BoreholeSeismograph/B4.jpg"),
          parameter:
            "·&nbsp;&nbsp; 作为地震传感器，RADIAN的安装倾角范围达到 ±180° ，其摆锤可以自动对中<br>·&nbsp;&nbsp; 利用内置磁力计和MEMS 加速度计可以测量仪器方位和倾角，最终储存和输出正确的波形数据<br>·&nbsp;&nbsp; RADIAN内置了MEMS加速度计，将其动态范围扩展至 2g，从而能够捕获本地地震事件引起的高幅值的地面运动信号<br>·&nbsp;&nbsp; RADIAN的直径仅为55 mm，长度仅有 770 mm，非常适合在浅井中快速安装<br>·&nbsp;&nbsp; RADIAN能够通过工业级可伸缩三爪锁紧装置固定到钻井套管上，从而实现充分耦合<br>·&nbsp;&nbsp; RADIAN具有仪器响应和标定参数的存储及实时传输功能，显著简化了数据管理 (采用RESP 和datalessSEED 格式)<br>·&nbsp;&nbsp; 通过蓝牙连接对应的Android或IOS 应用，可以用来确认仪器安装的完整性，而且避免了对仪器的物理扰动<br>·&nbsp;&nbsp; 仪器采用三轴(ZNE)正交型设计，具有较高的交叉轴抑制 (> 65 dB)"
        },
        {
          id: "tc_10010_0004",
          name: "3T、5T组合深井版",
          description:
            "使用这种3T/5T组合深井组件，可实现非常理想的动态范围。<br>Güralp 3T/5T结合了我们畅销的3T宽频带地震仪与经过验证的5T强运动加速度计。一台仪器可以同时监测微弱、遥远的地震发生和近场、高强度的震动。<br>该仪器直径仅89毫米，适合安装在直径为99米到203毫米的井眼中。<br>该仪器配备了过载保护装置和应变释放机构，以消除电缆运动对仪器中的传感器造成的影响。<br>灵活、模块化的设计提供了宽泛的安装可能性，包括与深井数字化仪一起在单个井眼内实现完全网络化的解决方案。<br>改进的防滑设计保证了仪器在外壳中的稳定性，并提高了5T传感器的高频响应。<br>生产厂家：Güralp Systems Ltd.<br>应用：垂直阵列；地震预警系统；强运动监测和建模",
          carouselItems: require("@/assets/images/ESci/BoreholeSeismograph/B5.jpg"),
          parameter:
            "·&nbsp;&nbsp; 总实现动态范围超过200 dB<br>·&nbsp;&nbsp; 地面超低噪音<br>·&nbsp;&nbsp; 89毫米的外径，可以安装在内径只有99毫米的井眼中<br>·&nbsp;&nbsp; 可选单爪锁，适用于直径为99 - 203毫米的井眼<br>·&nbsp;&nbsp; 应变消除机制，消除承重索移动对仪器中的传感器造成的影响"
        }
        // 这里添加更多产品
      ]
    };
  }
};
</script>
<style lang="less" scoped>
@import "../../../assets/styles/GeoSciProduct.less";
</style>
<style scoped>


</style>
