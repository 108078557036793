<!-- GlobalPanel.vue -->
<template>
  <div class="global-panel">
    <!-- 上半部分 -->
    <div class="top-section">
      <div class="icon-text" @mouseenter="showLeftBox" @mouseleave="hideLeftBox">
        <!-- 图标和文字 -->
        <img src="@/assets/images/home/asideStay/qrCode.svg" alt="Icon" />
        <p>咔咔抄表体验入口</p>
      </div>
    </div>
     <!-- 灰色背景框框 -->
    <div class="divider"></div>
    <!-- 下半部分 -->
    <div class="bottom-section">
      <div class="icon-text">
        <!-- 图标和文字 -->
        <a class="icon" href="https://p.qiao.baidu.com/cps/chat?siteId=17547112&userId=37638491&siteToken=713290b484f91cbf4c7487ded8293f3d" target="view_window">
        <img src="@/assets/images/home/asideStay/online.svg" alt="Consult Icon" />
      </a>
        <p>在线咨询</p>
      </div>
    </div>
   <div class="tooltip" v-show="isLeftBoxVisible" style="display: block;">
      <!-- 切图和二维码 -->
      <img src="@/assets/images/home/asideStay/union.svg" alt="Tooltip Image" />
       <div class="qr-code-container">
        <img class="qrImg" src="@/assets/images/footer/micro_reading.jpg" alt="QR Code" />
      </div>
      
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLeftBoxVisible: false,
    };
  },
  methods: {
      showLeftBox() {
      this.isLeftBoxVisible = true;
      // 添加.active类
  document.querySelector('.tooltip').classList.add('active');
    },
    hideLeftBox() {
      this.isLeftBoxVisible = false;
      // 移除.active类
  document.querySelector('.tooltip').classList.remove('active');
    },
  },
};
</script>

<style scoped>
/* 样式可以根据实际情况进行调整 */
.global-panel {
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 97px;
  height: 218px;
  background-color: #0087ff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  color: #fff;
  z-index: 1000;
  border-top-left-radius: 15px; /* 左上角弧度 */
  border-bottom-left-radius: 15px; /* 左下角弧度 */

}

.top-section,
.bottom-section {
  width: 100%;
}

.icon-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
}

.icon-text img{
    margin-bottom: 5px;
}

.tooltip {
  position: absolute;
  top: 35%;
  left: -170px; /* 调整框框位置 */
  transform: translateY(-50%);
  padding: 35px;
  display: none;
}

.tooltip img {
  max-width: 100%;
  height: auto;

}

.tooltip .qrImg{
  width: 100px;
  height: 100px;
}

.active .tooltip {
  display: block;
}

 .divider {
    width: 100%;
    height: 1px;
    background-color: #fff;
    margin: 10px 0; /* 调整分割线上下的间距 */
  }

.qr-code-container {
  position: absolute;
  top: 50%;
  left: 47%;
  transform: translate(-50%, -50%);
}
</style>
