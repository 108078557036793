<template>
 <!-- 放射性测量 -->
  <div v-if="productId == 'tc_10012_0001'">
    <div class="product-details">
      <div class="product-top">
        <!-- 顶部居左是产品名称 -->
        <h2>{{ productName }}</h2>
        <!-- 产品描述 -->
        <p class="product-description">{{ productDescription }}</p>
      </div>
      <!-- 产品描述的右侧是轮播图展示区 -->
      <div class="carousel-container">
        <!-- 轮播图展示区域 -->
          <el-carousel :interval="3000" autoplay style="width: 400px;">
            <!-- 轮播图内容 -->
            <el-carousel-item v-for="(item, index) in carouselItems" :key="index">
              <img :src="item.src" alt="轮播图" />
            </el-carousel-item>
          </el-carousel>
      </div>
    </div>
    <div class="parameter-vbom">
      <!-- 产品参数标题 -->
      <div class="parameter-title">
        <h3>产品参数</h3>
        <div class="separator"></div>
      </div>

      <!-- 图片展示区域 -->
      <div class="image-gallery">
        <!-- 图片内容 -->
        <img :src="galleryImage" alt="产品展示" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["productId"],
  data() {
    return {
      productName: "VARIO伽玛能谱仪",
      productDescription:
        "VARIO 伽玛能谱仪是典型的便携式2048道放射性γ能谱测量仪，满足野外、井中、车载、水下、实验室剂量分析、工业健康防护监测的所有测量要求。基于多年在放射性伽玛测量分析上的研究经验，GF公司为世界范围内的客户提供能够在天然源或人造放射性同位素稳谱下可以高效、稳定测量伽马射线总的剂量率和铀(U) 、钾(K) 、钍(Th)元素的富集程度的设备。",
      carouselItems: [
        { src: require("@/assets/images/ESci/Radioactivity/VARIO1.jpg") },
        { src: require("@/assets/images/ESci/Radioactivity/VARIO2.jpg") },
        { src: require("@/assets/images/ESci/Radioactivity/VARIO3.jpg") },
        { src: require("@/assets/images/ESci/Radioactivity/VARIO4.jpg") }
      ],
      galleryImage: require("@/assets/images/ESci/Radioactivity/VARIO5.jpg")
    };
  },

};
</script>
<style lang="less" scoped>
@import "../../../assets/styles/GeoSciProduct.less";
</style>
<style scoped>
</style>
