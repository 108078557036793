<template>
  <!-- 磁法勘探设备 -->
  <div>
    <div v-for="(product, index) in products" :key="index">
      <div v-if="product.id === productId" class="product-details">
        <div class="product-top">
          <h2>{{ product.name }}</h2>
          <p class="product-description">{{ product.description }}</p>
        </div>
        <div class="carousel-container" v-if="product.carouselImage">
          <img :src="product.carouselImage" style="width: 400px;"/>
        </div>
        <div class="carousel-container" v-else>
        <!-- 轮播图展示区域 -->
          <el-carousel :interval="3000" autoplay style="width: 400px;">
            <!-- 轮播图内容 -->
            <el-carousel-item v-for="(item, index) in product.carouselItems" :key="index">
              <img :src="item.src" alt="轮播图" />
            </el-carousel-item>
          </el-carousel>
      </div>
      </div>
      <div v-if="product.id === productId" class="parameter-vbom">
        <div class="parameter-title">
          <h3>产品参数</h3>
          <div class="separator"></div>
        </div>
        <p v-if="product.galleryText" class="product-description">{{ product.galleryText }}</p>
        <div v-if="product.galleryImage" class="image-gallery">
          <img :src="product.galleryImage" />
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
import productsList from "@/libs/productsList.js"; // 假设这是您的产品信息列表

export default {
props: ["productId"],
  data() {
    return {
      products: [
        {
          id: "tc_10004_0001",
          name: "KAPPA KAPPA 多深度磁化率仪",
          description: "Multi Kappa仪器用作快速野外点测量和多探头扫面测量，与GF传统的便携式袖珍磁化率一起能够覆盖更宽、 更精确的磁化率测量，包括地面、露头、岩石样品和土壤。",
          carouselItems:[
            { src: require("@/assets/images/ESci/MagneticExplorationEquipment/k1.png") },
            { src: require("@/assets/images/ESci/MagneticExplorationEquipment/k2.png") },
            { src: require("@/assets/images/ESci/MagneticExplorationEquipment/k3.png") },
            { src: require("@/assets/images/ESci/MagneticExplorationEquipment/k4.png") },
          ],
          galleryImage: require("@/assets/images/ESci/MagneticExplorationEquipment/p1.png")
        },
        {
          id: "tc_10004_0002",
          name: "SM-20 磁化率仪™",
          description: "SM-20磁化率仪具有高达10-6国际标准单位的高灵敏度，仪器轻便（可装入衬衫口袋），可在很多地质和地球物理应用中使用。 SM-20专为野外地质工作者和实验室工作人员设计，快速测定分析岩石或岩心样本，提供岩石基本参数（反磁性、 顺磁性和铁磁性）及高精度的磁化率数值。 原装探头和全新的数字信号处理技术是磁化率仪的品质保证。 SM-20磁化率仪简单的使用两键操作，一个按键测量，一个按键存数。添加测点标签、备注及数据处理等其它操作功能可使用电脑完成。 采用锂电池并具有自动关机省电功能，可长时间连续工作。",
          carouselItems:[
            { src: require("@/assets/images/ESci/MagneticExplorationEquipment/s1.png") },
            { src: require("@/assets/images/ESci/MagneticExplorationEquipment/s2.png") },
            { src: require("@/assets/images/ESci/MagneticExplorationEquipment/s3.png") },
          ],
          galleryImage: require("@/assets/images/ESci/MagneticExplorationEquipment/p2.png")
        },
        {
          id: "tc_10004_0003",
          name: "GSM-19T质子磁力仪",
          description: "全方位磁探头，无指北要求，非常有利于野外工作\n灵活设置测线号和测点号\n具有观测质量监控功能，可以及时处理质量不合格的数据\n可在主机屏幕上实时显示观测磁场变化曲线\n通过RS232接口，快速下载或上传观测数据，速率高达115200bps\n分辨率高达0.01nT,灵敏度达0.05nT，绝对精度±0.2nT\n具有编程式（预设）基站观测功能，软件进行日变校正\n使用GSM-19T的Walking Mag采集模式仪器可以按照预设的时间间隔自动读取磁场值，从而获得近乎连续的观测剖面\n配置GPS时（可选），用户可以用户可以在办公室内把设计好的观测点位坐标输入到仪器，在野外就可以实现导航定位功能\n基站观测和野外流动观测之间具有高精度的时间同步，这对于在磁干扰地区和保证高精度的观测结果是非常重要的",
          carouselImage: require("@/assets/images/ESci/MagneticExplorationEquipment/g1.jpg"),
          galleryImage: null,
          galleryText:"· 灵敏度：0.15nT（1s采样间隔），0.05（4s采样间隔）\n· 分辨率：0.01nT\n· 绝对精度：±0.2nT\n· 动态范围：20000到120000nT\n· 梯度容差：>7000nT/m\n· 采样率：日变站时3s~3600s可选，移动观测等待时间3s~10s可选，步行模式0.5s~2s可选，快速步行模式0.2s~2s可选\n· 温飘：0.0025nT/°C（环境温度为0到-40°C）；  0.0018nT/°C（环境温度为0到+55°C）    \n· 工作温度：-40℃~+55℃  \n· 存储32M字节：\n· 对流动站可存1,465,623个读数\n· 对基站可存5,373,951个\n· 对梯度测量可存1,240,142个读数\n· 对步行磁测可存2,686,975个读数  \n· 尺寸及重量：\n· 主机223×69×240mm,重2.1Kg\n· 传感器170mm(长)×75mm(直径)，重2.2Kg\n· 工作模式：\n· 流动站：mobile、grad时手动观测（3s~10s同步等待，可无等待测量）；walk、walkgrad模式时0.5s~2s间隔自动采样（可扩展至0.2s），测量输出点线号、磁场值、信号质量、测量时间、梯度值（梯度模式时）、点位坐标（使用内置GPS时）\n· 遥控：可选择RS232接口进行远程控制和实时传输\n· 输入与输出：RS232接口数字输出或应用6针防水插头（选项）作模拟输出"
        },
      ]
    };
  }
};
</script>
<style lang="less" scoped>
@import "../../../assets/styles/GeoSciProduct.less";
</style>
<style scoped>
</style>
