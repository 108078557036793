<template>
  <div>
    <!-- 子元素内容 -->
  </div>
</template>

<script>
export default {
    name: "lanwairHome"
}
</script>

<style scoped>

</style>
