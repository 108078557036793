<template>
  <!-- 探地雷达 -->
  <div>
    <div v-for="(product, index) in products" :key="index">
      <div v-if="product.id === productId" class="product-details">
        <div class="product-top">
          <h2>{{ product.name }}</h2>
          <p class="product-description">{{ product.description }}</p>
        </div>
        <div class="carousel-container" v-if="product.carouselImage">
          <img :src="product.carouselImage" style="width: 400px;"/>
        </div>
        <div class="carousel-container" v-else>
        <!-- 轮播图展示区域 -->
          <el-carousel :interval="3000" autoplay style="width: 400px;">
            <!-- 轮播图内容 -->
            <el-carousel-item v-for="(item, index) in product.carouselItems" :key="index">
              <img :src="item.src" alt="轮播图" />
            </el-carousel-item>
          </el-carousel>
      </div>
      </div>
      <div v-if="product.id === productId" class="parameter-vbom">
        <div class="parameter-title">
          <h3>产品参数</h3>
          <div class="separator"></div>
        </div>
        <p v-if="product.galleryText" class="product-description">{{ product.galleryText }}</p>
        <div v-if="product.galleryImage" class="image-gallery">
          <img :src="product.galleryImage" />
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
import productsList from "@/libs/productsList.js"; // 假设这是您的产品信息列表

export default {
props: ["productId"],
  data() {
    return {
      products: [
        {
          id: "tc_10003_0001",
          name: "TR1000 钢筋混凝土探测雷达",
          description: "TR1000钢筋混凝土探测雷达，可探测混凝土深度达1米以内的钢筋分布及混凝土裂缝结 构等，利用三维网格采集，可以在现场实时便捷的测量出钢筋的分布，另外也可以用来探测木桩或树木的空洞（深度在40cm以内），内置Wifi模块可以把现场解释报告无线传输给办公室。",
          carouselItems:[
            { src: require("@/assets/images/ESci/GroundPenetratingRadar/hps1.jpg") },
            { src: require("@/assets/images/ESci/GroundPenetratingRadar/hps2.jpg") },
          ],
          galleryImage: require("@/assets/images/ESci/GroundPenetratingRadar/p1.png")
        },
        {
          id: "tc_10003_0002",
          name: "Noggin250 管线探测雷达（暗管探测仪）™",
          description: "全数字式天线，探测深度小于10米以内的金属管线和非金属管线等地下目标体的专业化管线探测雷达。可执行二维和三维网格采集，在现场实时显示剖面和三维深度水平切片，能精确的圈定地下管线等目标体的三维展布特征，可在现场WiFi实时的把解释报告传输给办公室。",
          carouselImage: require("@/assets/images/ESci/GroundPenetratingRadar/as1.jpg"),
          galleryImage: require("@/assets/images/ESci/GroundPenetratingRadar/p2.png")
        },
        {
          id: "tc_10003_0003",
          name: "Noggin 便携式大深度地质雷达",
          description: "Noggin收发一体式雷达的天线全部为数字式天线，整个系统由三部分组成：雷达主机、雷达天线、后处理解释软件，可外接高精度GPS、 测量轮等外置配件。主机兼容中心频率为100MHz、250MHz、 500MHz、1000MHz雷达天线，满足浅层、中层、深层勘探的需要。",
          carouselItems:[
            { src: require("@/assets/images/ESci/GroundPenetratingRadar/n1.jpg") },
            { src: require("@/assets/images/ESci/GroundPenetratingRadar/n2.jpg") },
            { src: require("@/assets/images/ESci/GroundPenetratingRadar/n3.jpg") },
            { src: require("@/assets/images/ESci/GroundPenetratingRadar/n4.jpg") },
            { src: require("@/assets/images/ESci/GroundPenetratingRadar/n5.jpg") },
          ],
          galleryImage: require("@/assets/images/ESci/GroundPenetratingRadar/p3.png")
        },
        {
          id: "tc_10003_0004",
          name: "Ultra PE 专业级大深度地质雷达",
          description: "新型Ultra pE接收机可以单道数据叠加高达65536次，能够探测传统接收机弱达100倍的微弱信号，有效的提升探测深度。\nUltra接收机的性能解决了目前传统地质雷达探测深度不足的难题。 \n适用用户： \n中、深部探测用户 \n煤田采空区、岩土勘察、地质超前预报、地质灾害（地下空洞、滑坡等）、城市地裂缝、活断层、坝体检测、冰川探测、地下水位探测、污染物调查等",
          carouselImage: require("@/assets/images/ESci/GroundPenetratingRadar/up1.jpg"),
          galleryText:"· 高压输出，可实现干扰地区的大深度探测 \n· 特有的叠加技术，使提取弱小信号的能力提高了100倍，从而加大了探测深度 \n· 全数字天线，比模拟天线抗干扰能力更强，信噪比更高 \n· 分离式天线设计适用于不同采集方式：共中心点，共偏移距，广角采集和透射采集 \n· 高动态范围：>186dB，数据格式：32位"
        },
        {
          id: "tc_10003_0005",
          name: "SPIDAR 三维多通道阵列雷达",
          description: "SPIDAR多通道阵列雷达系统可以配置pulseEKKO以及Noggin系列的高、中、低频的天线，所有天线为全数字式天线，可以根据客户的需求配置成同频多通道、多频多通道、多极化多通道、一发多收、多发多收阵列式三维采集雷达系统，能够完成大面积道路快速探测、大面积地下管线快速探测及定位、快速高效的定位地下三维目标体走向、尺寸等参数。\n生产厂家：加拿大Sensors & Software Inc.\n应用领域：大面积测区、道路、地下管线、快速探测；地下三维目标体的快速确定；科研教学示范等。",
          carouselItems:[
            { src: require("@/assets/images/ESci/GroundPenetratingRadar/s1.jpg") },
            { src: require("@/assets/images/ESci/GroundPenetratingRadar/s2.jpg") },
            { src: require("@/assets/images/ESci/GroundPenetratingRadar/s3.jpg") },
          ],
          galleryText:"· 天线全部为数字式天线\n· 可兼容所有地面天线\n· 通道数不受限制：可配置成任意通道数的三维阵列雷达\n· 可配置单频多个天线或多频多个天线满足浅、中、深同时探测的需要\n· 多极化模式配置：横向极化、纵向极化或任意角度极化\n· 用于高速路快速详查：时速高达80km/h\n· GPS测线轨迹Google三维显示\n· 适用的用户：复杂管线探测及三维绘图、公路综合检测、机场跑道综合检测、考古等部门\n· 可实现一发多收、多发多收真三维雷达数据采集，满足科研领域的专业应用"
        },
        {
          id: "tc_10003_0006",
          name: "pulseEKKO专业分离式数字雷达",
          description: "pulseEKKO PRO为全数字化天线，动态范围186dB，发射电压高达1000V、接收灵敏度1.5μV，特别适合强干扰区深层探测，天线全部为分离式天线，可以执行四种采集方法 （共中心点、共偏移距、广角采集、透射）拓宽了野外采集方式。\n生产厂家：加拿大Sensors & Software Inc.\n应用领域：煤田采空区、岩土勘察、地质超前预报、地质灾害（地下空洞、滑坡等）、城市地裂缝、活断层、坝体检测、冰川探测、地下水位探测、污染物调查等。",
          carouselItems:[
            { src: require("@/assets/images/ESci/GroundPenetratingRadar/e1.jpg") },
            { src: require("@/assets/images/ESci/GroundPenetratingRadar/e2.jpg") },
            { src: require("@/assets/images/ESci/GroundPenetratingRadar/e3.jpg") },
            { src: require("@/assets/images/ESci/GroundPenetratingRadar/e4.jpg") },
            { src: require("@/assets/images/ESci/GroundPenetratingRadar/e5.png") },
            { src: require("@/assets/images/ESci/GroundPenetratingRadar/e6.png") },
          ],
          galleryText:"· 全数字天线：比模拟天线抗干扰能力更强、信噪比更高\n· 分离式天线设计可完成：共中心点CMP、共偏移距、广角采集WARR及透射采集\n· 主机可兼容各种天线（含井中天线）：12.5MHz、25MHz、50MHz（地面和井中）、100MHz（地面和井中）、200MHz（地面和井中）、250MHz、500MHz、1000MHz\n· 深度探测：12.5MHz低频天线与高达1000V的发射电压保证深层信号的信噪比（干扰地区）\n· 2018年推出的Ultra pulseEKKO大深度地质雷达系统，单道数据叠加高达65536次，能够探测传统接收机弱达100倍的微弱信号，极大的提升探测深度。Ultra接收机的性能解决了目前传统地质雷达探测深度不足的难题。"
        },
        {
          id: "tc_10003_0007",
          name: "IceMap冰雪厚度水深雷达",
          description: "IceMap是一种便捷、快速实时测量冰层厚度、冰层水深和水底地貌特征的专用雷达。\n生产厂家：加拿大Sensors & Software Inc.\n应用领域：冰层厚度、冰层水深和水底地貌特征测量。",
          carouselItems:[
            { src: require("@/assets/images/ESci/GroundPenetratingRadar/i1.jpg") },
            { src: require("@/assets/images/ESci/GroundPenetratingRadar/i2.jpg") },
            { src: require("@/assets/images/ESci/GroundPenetratingRadar/i3.jpg") },
            { src: require("@/assets/images/ESci/GroundPenetratingRadar/i4.jpg") },
          ],
          galleryText:"· 笔记本对天线单元无线控制:系统实时把采集的数据传输给笔记本\n· 天线可由机械车辆拖拽高速运动:最高时速可高达80km/h ,也可由人拖拽\n· 监控并记录冰层的最小和最大厚度\n· 冰层危险区域报警功能:可通过设置冰层最小厚度阀值来实现\n· 连续记录的GPS信息，可以精确的确定测线下冰层危险区域\n· 可在Google Earth 上绘制冰层厚度的等值线图\n· 数据回放显示功能，便于查证",
          galleryImage: require("@/assets/images/ESci/GroundPenetratingRadar/p7.png")
        },
      ]
    };
  }
};
</script>
<style lang="less" scoped>
@import "../../../assets/styles/GeoSciProduct.less";
</style>
<style scoped>
</style>
