<template>
  <div class="algorithm-wrap">
    <!-- banner 开始 -->
    <div class="page-banner" :style="{background: !widOver768 ? '#1D212E url('+require('@/assets/images/algorithm/banner.jpg')+') no-repeat' : 'transparent', backgroundSize: '100% 100%'}">
      <div class="banner-mask" style="background:rgba(0,0,0,.2)" v-if="!widOver768"></div>
      <div id="alg-banner" v-if="widOver768"></div>
      <div class="align-center">
        <p class="page-banner-title">图像识别系统</p>
        <p class="page-banner-description">EvaGo仪表识别系统具备大规模商业化应用能力</p>
      </div>
    </div>
    <!-- banner 结束 -->
    <!-- 全表型识别能力&异常图片稽查能力 开始 -->
    <div class="part meter-error letter-space">
      <div class="valid-area">
        <div>
          <!-- 标题 -->
          <p class="part-title dark-bg-text-white">全表型识别能力</p>
          <!-- 装饰线 -->
          <p class="decoration-line"></p>
          <!-- 描述 -->
          <div class="part-description dark-bg-text-white" v-if="widOver768">
            <p><span class="dark-bg-text"></span>支持全仪表类型识别的自学习算法，支持字轮表、</p>
            <p>指针表、LCD液晶屏、数码管、刻度表等表型</p>
          </div>
          <div class="part-description dark-bg-text-white" v-if="!widOver768">
            <p><span class="dark-bg-text"></span>支持全仪表类型识别的</p>
            <p>自学习算法，支持字轮表、指针表、LCD</p>
            <p>液晶屏、数码管、刻度表等表型</p>
          </div>
          <!-- 轮播 -->
          <div class="meter">
            <swiper :options="meterOption" ref="meterSwiper">
              <swiper-slide v-for="meter in meters" :key="meter.index">
                <div class="meter-item">
                  <p>{{meter.name}}</p>
                  <img class="meter-pic" :src="require('@/assets/images/algorithm/meter-'+meter.img)">
                  <div class="banner-mask"></div>
                </div>
              </swiper-slide>
            </swiper>
            <img src="@/assets/images/algorithm/meter-bg.png">
          </div>
        </div>
        <div :style="{'margin-top': widOver768 ? '60px' : '0.6rem'}">
          <!-- 标题 -->
          <p class="part-title dark-bg-text-white">异常图片稽查能力</p>
          <!-- 装饰线 -->
          <p class="decoration-line"></p>
          <!-- 描述 -->
          <div class="part-description dark-bg-text-white" v-if="widOver768">
            <p><span class="dark-bg-text"></span>支持表盘图像质量分析，有效应对复杂表盘环境的算法，</p>
            <p>如水雾、变形、模糊、划痕、光斑、污渍等</p>
          </div>
          <div class="part-description dark-bg-text-white" v-if="!widOver768">
            <p><span class="dark-bg-text"></span>支持表盘图像质量分析，</p>
            <p>有效应对复杂表盘环境的算法，如水雾、</p>
            <p>变形、模糊、划痕、光斑、污渍等</p>
          </div>
          <!-- 轮播 -->
          <div class="meter error">
            <swiper :options="errorOption" ref="errorSwiper">
              <swiper-slide v-for="error in errors" :key="error.index">
                <div class="meter-item">
                  <p>{{error.name}}</p>
                  <img class="meter-pic" :src="require('@/assets/images/algorithm/error-'+error.img)">
                  <div class="banner-mask"></div>
                </div>
              </swiper-slide>
            </swiper>
            <img src="@/assets/images/algorithm/meter-bg.png">
          </div>
        </div>
      </div>
    </div>
    <!-- 全表型识别能力&异常图片稽查能力 结束 -->
    <!-- 识别系统优势及原理 开始 -->
    <div class="part part-light-bg letter-space">
      <div class="valid-area">
        <!-- 标题 -->
        <p class="part-title">识别系统优势及原理</p>
        <!-- 装饰线 -->
        <p class="decoration-line"></p>
        <!-- 图示 -->
        <div class="main-content">
          <div class="api-way layout-flex">
            <div class="way-item">
              <div class="item-pic-box">
                <img class="align-center" src="@/assets/images/algorithm/sys-shibie.png">
              </div>
              <div>
                <div class="way-title">任意表型的精准识别</div>
                <div class="way-description">客户实测识别准确率>99.9%</div>
              </div>
            </div>
            <div class="way-item">
              <div class="item-pic-box">
                <img class="align-center" src="@/assets/images/algorithm/sys-xiaolv.png">
              </div>
              <div>
                <div class="way-title">提高稽查与运维效率</div>
                <div class="way-description">一键稽查数据异常，确保结果准确</div>
              </div>
            </div>
          </div>
          <img class="sys-theory" src="@/assets/images/algorithm/sys-theory.png">
        </div>
      </div>
    </div>
    <!-- 识别系统优势及原理 结束 -->
    <!-- 计算机视觉领域研究成果 开始 -->
    <div class="part letter-space" :style="{'background':'#1D212E url('+require('@/assets/images/algorithm/research-bg.png')+') no-repeat right bottom','backgroundSize':'100% 100%'}">
      <div class="valid-area">
        <!-- 标题 -->
        <p class="part-title dark-bg-text-white">计算机视觉领域研究成果</p>
        <!-- 装饰线 -->
        <p class="decoration-line"></p>
        <div class="main-content">
          <p class="main-content-title dark-bg-text">《基于视觉认知机理的图像不变性分析》</p>
          <p class="main-content-title dark-bg-text">国际顶级会议 & 期刊论文 & 合作院校</p>
          <p class="main-content-description" style="color: #fff;">CVPR, ICCV,ECCV, ICML, NIPS</p>
          <p class="main-content-description" style="color: #fff;">IEEE TPAMI, IEEE TNNLS, IEEE TMM</p>
          <p class="main-content-description" style="color: #fff;">IEEE TGRS, TEEE TIP</p>
          <img src="@/assets/images/algorithm/research-findings.jpg" style="width:100%;margin-top:60px">
        </div>
      </div>
    </div>
    <!-- 计算机视觉领域研究成果 结束 -->
  </div>
</template>
<script>
  import $ from 'jquery'
  import {swiper,swiperSlide} from 'vue-awesome-swiper'
  import {algCanvas} from '@/libs/algorithmCanvas.js'

  export default {
    name: 'algorithm',
    components: {
      swiper,
      swiperSlide,
    },
    data(){
      return {
        widOver768: false,
        algCanvas: null,
        meterOption:{
          autoplay: {
            delay: 2000,
            stopOnLastSlide: false,
            disableOnInteraction: false,
          },
          speed: 800,
          initialSlide: 1,
          loop:true,
          effect:'coverflow',//可以实现3D效果的轮播
          slidesPerView: 3,
          centeredSlides: true,
          slideToClickedSlide: true,
          coverflowEffect:{
            rotate:0,//slide做3d旋转时Y轴的旋转角度。默认50。
            stretch:500,//每个slide之间的拉伸值，越大slide靠得越紧。 默认0。
            depth:323,//slide的位置深度。值越大z轴距离越远，看起来越小。 默认100。
            modifier: 2,
            slideShadows: false
          },
          observer:true,//修改swiper自己或子元素时，自动初始化swiper
          observeParents:true,//修改swiper的父元素时，自动初始化swiper
        },
        meters:[
          {
            index: 0,
            name: '字轮表',
            img:'zilun.jpg'
          },
          {
            index: 1,
            name: '指针表',
            img:'zhizhen.jpg'
          },
          {
            index: 2,
            name: 'LCD液晶屏',
            img:'lcd.jpg'
          },
          {
            index: 3,
            name: '数码管 / 刻度表',
            img:'shuma.jpg'
          },
        ],
        errorOption:{
          autoplay: {
            delay: 2000,
            stopOnLastSlide: false,
            disableOnInteraction: false,
          },
          speed: 800,
          initialSlide: 1,
          loop:true,
          effect:'coverflow',//可以实现3D效果的轮播
          slidesPerView: 3,
          centeredSlides: true,
          slideToClickedSlide: true,
          coverflowEffect:{
            rotate:0,//slide做3d旋转时Y轴的旋转角度。默认50。
            stretch:500,//每个slide之间的拉伸值，越大slide靠得越紧。 默认0。
            depth:323,//slide的位置深度。值越大z轴距离越远，看起来越小。 默认100。
            modifier: 2,
            slideShadows: false
          },
          observer:true,//修改swiper自己或子元素时，自动初始化swiper
          observeParents:true,//修改swiper的父元素时，自动初始化swiper
        },
        errors:[
          {
            index: 0,
            name: '水雾 / 模糊',
            img:'shuiwu.jpg'
          },
          {
            index: 1,
            name: '变形 / 污渍',
            img:'wuzi.jpg'
          },
          {
            index: 2,
            name: '光斑 / 划痕',
            img:'guangban.jpg'
          },
        ],
      }
    },
    methods:{
      handleScroll(dom,itemName){
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        let offsetTop = document.querySelector(dom).parentElement.parentElement.offsetTop - 600 
        if(scrollTop >= offsetTop){
          this.slideToUp(itemName)
        }
      },
      slideToUp(itemName){
        $.each(itemName,function(ind,item){
          $(item).delay(100*(ind+1)).animate({"top":0,"opacity":1},300);
        })
        $('.sys-theory').delay(400).animate({"top":0,"opacity":1},300)
      },
      getWid(){
        this.algCanvas = null;
        let wid = document.documentElement.clientWidth || document.body.clientWidth
        this.widOver768 = wid <= 768 ? false : true;
        if(this.widOver768){
          setTimeout(() => {
            this.algCanvas = algCanvas;
            this.algCanvas('alg-banner',wid,$('.page-banner').height());
          }, 0);
        }else{
          $('#alg-banner').empty();
        }
      },
    },
    mounted(){
      this.getWid();
      window.addEventListener('scroll',()=>{
        if(this.$route.name=='algorithm'){
          this.handleScroll('.api-way',$('.way-item'))
        }
      });
      window.addEventListener('resize',this.getWid);
    },
    destroyed(){
      this.algCanvas = null;
      window.removeEventListener('scroll',this.handleScroll);
    },
  }
</script>
<style lang="less" scoped>
  .page-banner{
    text-align: center;
  }
  #alg-banner{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .meter-error{
    background: #090630;
  }
  .meter{
    height: 400px;
    margin-top: 44px;
    position: relative;
    >img{
      width: 120%;
      position: absolute;
      bottom: -60px;
      left: -10%;
    }
    .meter-item{
      width: 514px;
      height: 320px;
      margin-left: -67px;
      padding: 0 16px 16px;
      background: rgba(9,6,48,.9);
      border: 2px solid #24D5FF;
      box-shadow: 0 12px 40px rgba(0,0,0,.8);
      p{
        line-height: 60px;
        font-size: 20px;
        color: #24D5FF;
      }
      .meter-pic{
        width: 478px;
        height: 240px;
      }
      .banner-mask{
        width: 514px;
        height: 320px;
        left: -67px;
      }
    }
    @media screen and (max-width: 768px){
      height: 4rem;
      margin-top: 0.44rem;
      >img{
        bottom: -0.6rem;
      }
      .meter-item{
        width: 4.2rem;
        height: 2.8rem;
        margin-left: -0.98rem;
        padding: 0 0.16rem 0.16rem;
        p{
          line-height: 0.6rem;
          font-size: 0.2rem;
        }
        .meter-pic{
          width: 3.8rem;
          height: 1.9rem;
        }
        .banner-mask{
          width: 4.2rem;
          height: 2.8rem;
          left: 0.98rem;
        }
      }
    }
  }
  .error{
    height: 380px;
    >img{
      bottom: -120px;
    }
    .meter-item{
      height: 300px;
      .meter-pic{
        height: 220px;
      }
      .banner-mask{
        height: 300px;
      }
    }
    @media screen and (max-width: 768px){
      height: 3.8rem;
      >img{
        bottom: -1.2rem;
      }
      .meter-item{
        height: 3rem;
        .meter-pic{
          height: 2.2rem;
        }
        .banner-mask{
          height: 3rem;
        }
      }
    }
  }
  .api-way{
    justify-content: space-between;
    .way-item{
      width: 550px;
      height: 160px;
      display: flex;
      padding: 46px 0 0 36px;
      background: #f8f9fe;
      color: #1D212E;
      text-align: left;
      position: relative;
      top: 100px;
      opacity: 0;
      .item-pic-box{
        width: 68px;
        height: 68px;
        margin-right: 36px;
      }
      .way-title{
        line-height: 24px;
        margin-bottom: 20px;
        font-size: 24px;
      }
      .way-description{
        font-size: 18px;
      }
    }
    @media screen and (max-width: 768px){
      flex-wrap: wrap;
      .way-item{
        width: 95%;
        height: 1.6rem;
        padding: 0.4rem 0 0 0.36rem;
        margin: 0.2rem auto;
        top: 1rem;
        .item-pic-box{
          width: 0.68rem;
          height: 0.68rem;
          margin-right: 0.36rem;
        }
        .way-title{
          line-height: 0.24rem;
          margin-bottom: 0.2rem;
          font-size: 16px;
        }
        .way-description{
          font-size: 14px;
        }
      }
    }
  }
  .sys-theory{
    width: 710px;
    margin: 80px auto 0;
    display: block;
    position: relative;
    top: 100px;
    opacity: 0;
    @media screen and (max-width: 768px){
      width: 95%;
      margin: 0.8rem auto 0;
      top: 1rem;
    }
  }
  .part-description{
    p{
      line-height: 2;
    }
  }
</style>
<style>
  .meter .swiper-container{
    height: 400px;
  }
  .meter .swiper-slide .banner-mask{
    opacity: 1;
  }
  .meter .swiper-slide-active .banner-mask{
    opacity: 0;
  }
</style>
