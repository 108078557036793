<template>
  <div class="wrap" style="position: relative;overflow: hidden;">
    <!-- banner -->
    <!-- <div class="company-banner" :style="{ 'background': 'url(' + require('@/assets/images/company/introduce_bg.png') + ') no-repeat 50% 0 / cover' }"> -->
    <div class="company-banner"
      :style="{ 'background': 'url(' + require('@/assets/images/company/soho-bg1.jpg') + ') no-repeat 50% 0 / cover' }">

      <div class="valid-area">
        <div class="banner-info">
          <p class="banner-title">
            <span>公司简介</span>
            <!-- <span class="point-box">
              <span class="split-point split-point-white align-center"></span>
            </span>Lanwair -->
          </p>
          <p class="banner-line"></p>
          <p class="banner-content">
            北京三合智能科技有限公司，由三合(北京)探测有限公司控股，北京羿娲科技有限公司更名而来。是一家以人工智能、物联网、地球物理传感器为核心技术的国家高新技术企业及北京市“专精特新”认定企业。公司涵盖智慧工业与地球科学两大业务版块。<br>
            公司已入选全国工商联物联网委员会、中国地球物理学会、5G应用产业方阵、工业互联网产业联盟及中国通信标准化协会等多家行业机构及组织，曾获《科技日报》、《人民政协报》、CCTV新闻频道等主流媒体关注报道。随着全球业务的拓展，三合智能已与英国Guralp公司、加拿大Sensors
            & software公司、英国Silixa公司、捷克GF仪器公司、美国Geophex公司、法国iXblue公司、加拿大ESG公司、美国Seismic Source &
            iSeis公司、挪威Elop公司建立了战略合作关系，并在欧洲、中东、北美筹备设立办事处。
          </p>
        </div>
      </div>
    </div>

    <!-- 业务板块 -->
    <div class="business-unit part">
      <div class="valid-area">
        <p class="part-title">
          <span class="blue-bg-text">业务版块</span>
          <!-- <span class="point-box">
            <span class="split-point align-center"></span>
          </span>智慧工业与地球科学两大业务版块 -->
        </p>
        <p class="decoration-line"></p>
      </div>
    </div>
    <div class="iiot-bg">
      <div>
        <div class=" layout-flex">
          <div class="business-content-item-left ">
            <div class="business-content-title text-left">
              智慧工业
            </div>
            <div class="iiot-content-en">
              INTELLIGENT INDUSTRY
            </div>
            <div class="business-content-text">
              专注水电气热及工业仪表感知及物联。公司自主研发低功耗物联AI相机、仪表图像识别算法、工业云平台及边缘计算站产品。旨在解决海量仪器仪表/设备设施/环境现场的巡检难、分析难、管理难等痛点。目前，产品已广泛应用于燃气、
              石油、化工、电力、制造、水务等行业，在全国数百座城市区县广泛应用落地，助力企业节能降碳、安全生产、提质增收，环境保护、平安社区。
            </div>
          </div>

          <div class="business-img-item">
            <img class="" :src="require('@/assets/images/company/iiot.jpg')" />
          </div>
        </div>
      </div>

    </div>
    <div class="es-bg">
      <div class=" layout-flex">
        <div class="es-img-item fl">
          <img class="" :src="require('@/assets/images/company/es.jpg')" />
        </div>
        <div class="business-content-item-right ">
          <div class="business-content-title text-right">
            地球科学
          </div>
          <div class="es-content-en text-right">
            EARTH SCIENCE
          </div>
          <div class="business-content-text text-left fr">
            专注地球科学领域。覆盖地震科学调查、地球深部构造探测、地震流动台网和余震监测、核爆监测、地震与海啸预警、大型设施结构健康监测（如：大坝、工厂、建筑、桥梁等）、微震与压裂监测、环境与工程地球物理、土壤场地调查、地质灾害、道路病害探测、城市管网探测、隧道超前预报、考古探测、农业土壤根系调查、冰川冻土研究、未爆炸物探测、放射性污染监测等领域。
          </div>
        </div>
      </div>
    </div>
    <!-- 企业文化 -->
    <div id="culture" class="enterprise-culture"
      :style="{ 'background': 'url(' + require('@/assets/images/company/culture-bg.jpg') + ') no-repeat 50% 0 / cover' }">
      <!-- <div id="culture-rectangle" class="rectangle" style="position: absolute;">
        <img :src="require('@/assets/images/company/lanwair-icon.png')" />
        <p>
          公司已入选全国工商联物联网委员会、中国地球物理学会、5G应用产业方阵、工业互联网产业联盟及中国通信标准化协会等多家行业机构及组织，曾获《科技日报》、《人民政协报》、CCTV新闻频道等主流媒体关注报道。随着全球业务的拓展，三合智能已与英国Guralp公司、加拿大Sensors
          & software公司、英国Silixa公司、捷克GF仪器公司、美国Geophex公司、法国iXblue公司、加拿大ESG公司、美国Seismic Source &
          iSeis公司、挪威Elop公司建立了战略合作关系，并在欧洲、中东、北美筹备设立办事处。
        </p>
      </div> -->
      <div class="valid-area" style="padding-top: 80px;">
        <p class="part-title">
          <span class="blue-bg-text">企业文化</span>
          <!-- <span class="point-box">
            <span class="split-point align-center"></span>
          </span>文化 -->
        </p>
        <p class="decoration-line"></p>
      </div>
    </div>

    <div class="culture-info">
      <div class="culture-area">
        <div class="culture-item-container">
          <div class="culture-item">
            <img class="culture-item-img img-left-1" :src="require('@/assets/images/company/culture1.jpg')"
              width="698px">
            <div class="culture-content content-right">
              <div class="culture-item-title">正道</div>
              <div class="culture-item-text">正道即遵循道德原则、恪守良知、以诚信为本的做人之路。<br>立正心，走正道是三合智能团队的立身之本。</div>
            </div>
          </div>
          <div class="culture-item">

            <div class="culture-content">
              <div class="culture-item-title">勤奋</div>
              <div class="culture-item-text">昼作不辍手，猛烛继望舒。<br>勤奋是三合智能团队的做事态度，也是三合智能团队的习惯作风。</div>
            </div>
            <img class="culture-item-img" :src="require('@/assets/images/company/culture2.jpg')">
          </div>
          <div class="culture-item">
            <img class="culture-item-img img-left-2" :src="require('@/assets/images/company/culture3.jpg')">
            <div class="culture-content">
              <div class="culture-item-title">务实</div>
              <div class="culture-item-text">道虽迩，不行不至；事虽小，不为不成。<br>三合智能团队永远秉承务实的精神为社会创造价值。</div>
            </div>
          </div>
          <div class="culture-item">
            <div class="culture-content">
              <div class="culture-item-title">利他</div>
              <div class="culture-item-text">利己者生，利他者久。<br>三合智能团队期待拥抱更多的合作伙伴，共创未来。</div>
            </div>
            <img class="culture-item-img" :src="require('@/assets/images/company/culture4.jpg')">
          </div>
        </div>
      </div>
    </div>
    <!-- 荣誉资质 -->
    <div id=”qualification-info“ class="qualification">
      <div id="qualification-list" class="valid-area" style="padding-top: 88px;">
        <p class="part-title">
          <span class="blue-bg-text">荣誉资质</span>
          <!-- <span class="point-box">
            <span class="split-point align-center"></span>
          </span>资质 -->
        </p>
        <p class="decoration-line"></p>
        <img :src="require('@/assets/images/company/qualification4.jpg')">
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  name: "company",

  data() {
    return {

    };
  },

  methods: {
    navTo() {
      this.$router.push("/");
    },
    showHotProducts() {
      this.showHotProductsDropdown = true;
    },

    handleScroll(dom, itemName, direction, distance) {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      let offsetTop =
        document.querySelector(dom).parentElement.parentElement.offsetTop - distance;
      if (scrollTop >= offsetTop) {
        switch (direction) {
          case "up":
            this.slideToUp(itemName);
            break;
          case "left":
            this.slideToLeft(dom);
            break;
          case "right":
            this.slideToRight(dom);
            break;
          default:
            break;
        }
      }
    },
    slideToUp(itemName) {
      $.each(itemName, function (ind, item) {
        $(item)
          .delay(100 * (ind + 1))
          .animate({ top: 0, opacity: 1 }, 300);
      });
    },
    slideToRight(dom) {
      // $(dom).animate({ left: 0, opacity: 1 }, 500);
      var element = document.querySelector(dom);
      element.style.transform = 'translateX(0)';
      element.style.opacity = '1';
    },
    slideToLeft(dom) {
      $(dom).animate({ left: 0, opacity: 1 }, 500);
    },
  },
  mounted() {
    window.addEventListener("scroll", () => {
      if (this.$route.name == "company") {
        // this.widOver768? this.handleScroll(".sys-graphic", $(".graphic-item"), "up", 600): this.handleScroll(".sys-img", $(".sys-img"), "up", 600);
        this.handleScroll(".business-content-item-left", "", "left", 600);
        this.handleScroll(".business-content-item-right", "", "right", 800);
        this.handleScroll(".img-left-1", "", "left", 600);
        this.handleScroll(".img-left-2", "", "left", 600);
        this.handleScroll(".content-right", "", "right", 600);
      }
    });
  }
  ,
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  }
};
</script>

<style scoped>
.wrap {
  line-height: 2;
}

.header-wrap {
  width: 100%;
  height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  background: #1D212E;
  z-index: 99998;

}

.company-banner {
  height: 950px;
  position: relative;
  overflow: hidden;
}

.banner-info {
  /* width: 820px; */
  width: 75%;
  position: absolute;
  top: 24%;
  text-align: left;

  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  /* 添加文字阴影 */
}

.banner-title {
  line-height: 37.5px;
  font-size: 32px;
  color: #fff !important;

  /* left: ; */
}

.banner-content {
  font-size: 20px;
  color: #fff;
  /* background-color: rgba(102, 102, 102, 0.1);  */
  /* background-color: rgba(56, 62, 74, 0.1); 
  padding: 10px;
  border-radius: 3px; */
}

.valid-area {
  height: 100%;
}

.banner-line {
  width: 94px;
  height: 6px;
  margin: 30px 0;
  background: #FFFFFF;
}

.point-box {
  height: 42px;
  margin: 0 20px 0 16px;
  position: relative;

  @media screen and (max-width:768px) {
    height: 0.42rem;
    margin: 0 0.2rem 0 0.16rem;
  }

  .split-point {
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #383E4A;

    @media screen and (max-width:768px) {
      width: 0.05rem;
      height: 0.05rem;
    }
  }

  .split-point-white {
    background: #fff;
  }
}

.decoration-line {
  width: 94px;
  height: 6px;
  margin: 32px auto 0;
  /* background-image: linear-gradient(to right,#3E86F7,#24D5FF); */
  background: #0087FF;

  @media screen and (max-width:768px) {
    width: 0.4rem;
    height: 0.05rem;
    margin: 0.3rem auto 0;
  }
}

.blue-bg-text {
  color: #0087FF;
}

.iiot-bg {
  background: #F2F9FF;
  height: 900px;
  /* width: 1920px; */
}

.business-content-item-left {
  width: 720px;
  overflow: visible;
  padding-left: 160px;
  position: relative;
  opacity: 0;
  right: 200px;
}

.business-content-item-right {
  width: 720px;
  overflow: visible;
  padding-right: 160px;
  position: relative;
  top: -200px;
  position: relative;
  opacity: 0;
  /* right: 200px; */
  /* left: 200px; */
  transform: translateX(200px);
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.business-content-title {
  font-size: 42px;
  padding-top: 35px;
}

.iiot-content-en {
  width: 760px;
  height: 90px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  font-size: 64px;
  color: rgba(168, 169, 173, 0.1);
  line-height: 75px;
  text-align: right;
  font-style: normal;
  text-transform: none;
  /* z-index:10; */
  /* position: absolute; */
  position: relative;
}

.business-content-text {
  width: 528px;
  height: 210px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 20px;
  color: #666666;
  line-height: 30px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  line-height: 2;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.business-img-item {
  max-width: calc(100% - 720px);
  height: 674px;
  border-radius: 0px 0px 0px 0px;
  background-color: transparent;
}

.business-img-item img {
  width: 100%;
  padding-right: 160px;
}

.es-img-item {
  position: relative;
  top: -200px;
  max-width: calc(100% - 720px);
  height: 674px;
  border-radius: 0px 0px 0px 0px;
  background-color: transparent;
}

.es-img-item img {
  width: 100%;
  padding-left: 160px;
}

.es-bg {
  background: #fff;
  /* height: 700px; */
  height: 450px;
}

.es-content-en {
  /* width: 518px; */
  height: 90px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  font-size: 64px;
  color: rgba(168, 169, 173, 0.1);
  line-height: 75px;
  text-align: right;
  font-style: normal;
  text-transform: none;
}

/* .business-content-item-left.slide-in {

  .business-content-title,
  .iiot-content-en,
  .business-content-text {
    animation: slideIn 1s forwards ease-out;
  }
} */

/* .business-content-item-right.slideIn-right {

  .business-content-title,
  .es-content-en,
  .business-content-text {
    animation: slideIn-right 1s forwards ease-out;
  }
} */

/* @keyframes slideIn {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slideIn-right {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
} */

/* .business-content-item-left:not(.slide-in) .business-content-title,
.business-content-item-left:not(.slide-in) .iiot-content-en,
.business-content-item-left:not(.slide-in) .business-content-text {
  transform: translateX(0);
  
} */

.enterprise-culture {
  /* width: 1920px; */
  height: 999px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
  border-radius: 0px 0px 0px 0px;
  /* position: relative;  */
  z-index: 0;
}

.culture-info {
  /* background-color: #fff; */
  margin-bottom: 46px;

}

.culture-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-top: -590px;  */
  margin-top: -790px;
  z-index: 2;
  /* position: relative; */
}

.culture-item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.culture-item {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.culture-content {
  margin-left: 10px;
  padding: 136px 74px;
}

.culture-item-title {
  width: 64px;
  height: 45px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  font-size: 32px;
  color: #1E222E;
  line-height: 38px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.culture-item-text {
  width: 608px;
  height: 68px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 20px;
  color: #666666;
  line-height: 28px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  line-height: 2;
}

.rectangle {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1380px;
  margin: 0 auto;
  height: 304px;
  background: #FFFFFF;
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 0px 0px;
  z-index: 1;

  img {
    padding-top: 64px;
    padding-bottom: 32px;
  }

  p {
    height: 112px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 20px;
    color: #666666;
    line-height: 23px;
    text-align: center;
    font-style: normal;
    text-transform: none;
    padding: 0 64px;
  }
}

.qualification {
  /* width: 1920px; */
  /* height: 1084px; */
  background: #F2F9FF;
  border-radius: 0px 0px 0px 0px;
  padding-bottom: 64px;
}

.qualification img {
  width: 100%;
  padding-top: 43px;
}

.img-left-1,
.img-left-2 {
  opacity: 0;
}

.content-right {
  opacity: 0;
}
</style>
