<template>
  <!-- 宽频带地震仪 -->
  <div>
    <div v-for="(product, index) in products" :key="index">
      <div v-if="product.id === productId" class="product-details">
        <div class="product-top">
          <h2>{{ product.name }}</h2>
          <p class="product-description">{{ product.description }}</p>
        </div>
        <div class="carousel-container" v-if="product.carouselImage">
          <img :src="product.carouselImage" style="width: 400px;"/>
        </div>
        <div class="carousel-container" v-else>
        <!-- 轮播图展示区域 -->
          <el-carousel :interval="3000" autoplay style="width: 400px;">
            <!-- 轮播图内容 -->
            <el-carousel-item v-for="(item, index) in product.carouselItems" :key="index">
              <img :src="item.src" alt="轮播图" />
            </el-carousel-item>
          </el-carousel>
      </div>
      </div>
      <div v-if="product.id === productId" class="parameter-vbom">
        <div class="parameter-title">
          <h3>产品参数</h3>
          <div class="separator"></div>
        </div>
        <p v-if="product.galleryText" class="product-description">{{ product.galleryText }}</p>
        <div v-if="product.galleryImage" class="image-gallery">
          <img :src="product.galleryImage" />
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>

export default {
props: ["productId"],
  data() {
    return {
      products: [
        {
          id: "tc_10006_0001",
          name: "CERTIS 流动式三分量宽频带地震计",
          description: "CERTIS三分量宽频带地震计适用于地面安装、直埋式安装和浅井式安装等多种工作场景，工作倾角为± 90°，工作深度达100m。",
          carouselImage: null,
          carouselItems:[
            { src: require("@/assets/images/ESci/BroadbandSeismograph/p1c1.png") },
            { src: require("@/assets/images/ESci/BroadbandSeismograph/p1c2.png") },
            { src: require("@/assets/images/ESci/BroadbandSeismograph/p1c3.png") },
          ],
          galleryImage: require("@/assets/images/ESci/BroadbandSeismograph/p1.png")
        },
        {
          id: "tc_10006_0002",
          name: "Güralp 3ESPCDE 便携式一体化三分量宽频带地震仪",
          description: "Güralp公司生产的3 ESPCDE数字地震仪由久负盛名的 3ESPC地震计内置DM24数字转换器和数据釆集模块组成的一体化地震仪。 作为一款小巧、轻便的三分量宽频带地震仪，它集成了基于Linux操作系统的EAM数据采集模块，并采用方便的网络化用户接口设计，提供串口和网口两种通讯方式，支持远程监测和控制，具有很好的灵活性。",
          carouselImage: null,
          carouselItems:[
            { src: require("@/assets/images/ESci/BroadbandSeismograph/p2c1.png") },
            { src: require("@/assets/images/ESci/BroadbandSeismograph/p2c2.png") },
          ],
          galleryImage: require("@/assets/images/ESci/BroadbandSeismograph/p2.png")
        },
        {
          id: "tc_10006_0003",
          name: "MINIMUS MINIMUS+ 智能化地震数据采集器",
          description: "Güralp公司研制的 Minimus(4通道)和 Minimus+(8通道)的智能化地震数字采集器，拥有大量独特优点，为快速部署和多尺度网络阵列提供即插即用的理想解决方案。",
          carouselImage: null,
          carouselItems:[
            { src: require("@/assets/images/ESci/BroadbandSeismograph/p3c1.png") },
            { src: require("@/assets/images/ESci/BroadbandSeismograph/p3c2.png") },
            { src: require("@/assets/images/ESci/BroadbandSeismograph/p3c3.png") },
          ],
          galleryImage: require("@/assets/images/ESci/BroadbandSeismograph/p3.jpg")
        },
        {
          id: "tc_10006_0004",
          name: "Güralp 40TDE 便携式一体化宽频带数字地震仪",
          description: "英国Güralp公司研制的40TDE一体化数字震仪，是一款基于三分量力平衡反馈原理的宽频带地震仪。 该设备由地震计，数字化仪和数据采集模块组成，拥有嵌入式通讯模块并支持内置和外置两种存储方式，可通 过串口、以太网等多种协议进行通讯。仪器采用坚固耐用的不锈钢外壳，达到IP68防护等级，以应对极端恶劣的安装环境，非常适合野外流动观测。",
          carouselImage: null,
          carouselItems:[
            { src: require("@/assets/images/ESci/BroadbandSeismograph/p4c1.png") },
            { src: require("@/assets/images/ESci/BroadbandSeismograph/p4c2.png") },
          ],
          galleryImage: require("@/assets/images/ESci/BroadbandSeismograph/p4.png")
        },
        {
          id: "tc_10006_0005",
          name: "3T-120PH  三分量浅井型宽频带地震计",
          description: "自从1987年开始量产，3T系列宽频地震计，适用于地面、地下和浅井安装。在全世界范围内， 3T 广泛应用于国家地震台网，全球各地的部署量超过5000台。",
          carouselImage: null,
          carouselItems:[
            { src: require("@/assets/images/ESci/BroadbandSeismograph/p5c1.png") },
            { src: require("@/assets/images/ESci/BroadbandSeismograph/p5c2.png") },
            { src: require("@/assets/images/ESci/BroadbandSeismograph/p5c3.png") },
          ],
          galleryImage: require("@/assets/images/ESci/BroadbandSeismograph/p5.png")
        },
        {
          id: "tc_10006_0006",
          name: "BlueSeis-3A 旋转地震计",
          description: "iXblue公司拥有30多年的光纤陀螺研发经验，iXBlue公司把该项陀螺新技术应用在地震、火山、海啸、核反应堆等监测领域，与传统的强震计或宽频带地震仪传感器不同，iXblue公司研发生产的blueSeis-3A三分量旋转地震仪融合了光纤陀螺新技术，可以直接测量出测点的旋转运动各分量（俯 仰角、偏航角、翻滚角），是旋转地震学研究直接、可靠的旋转测量地震计。",
          carouselImage: null,
          carouselItems:[
            { src: require("@/assets/images/ESci/BroadbandSeismograph/p6c1.png") },
            { src: require("@/assets/images/ESci/BroadbandSeismograph/p6c2.png") },
            { src: require("@/assets/images/ESci/BroadbandSeismograph/p6c3.png") },
            { src: require("@/assets/images/ESci/BroadbandSeismograph/p6c4.png") },
          ],
          galleryImage: require("@/assets/images/ESci/BroadbandSeismograph/p6.png")
        },
        {
          id: "tc_10006_0007",
          name: "Certimus一体化地震仪",
          description: "下一代中震台站，倾斜范围为±90°\nCertimus是一种一体化便携式、数字、宽频带三轴地震仪，具有非常理想的通信能力，适用于直接埋地、地面和地下空间部署。\n与其他宽频带地震仪不同，Certimus的工作角度可达90°，频率响应宽为120 s - 100 Hz。\nCertimus还受益于远程可调节的长周期角。可以在部署前或部署后进行调整为1秒或10秒模式，并显著减少传感器的设置时间。\n以太网供电(POE)和Wi-Fi，可即插即用部署，可选的2.4英寸多点触摸敏感全彩液晶显示屏显示波形、仪器健康状态(SOH)、增益设置、网络配置和虚拟仪器级别。\nCertimus有两种型号：\nCertimus普通版--用于地面/地下空间安装\nCertimus直埋版\n生产厂家：Güralp Systems Ltd.\n应用领域：局部、区域和远程地震监测；临时部署在困难的环境或偏远地区；余震监测快速部署；油气市场的微地震和诱发地震监测，如裂缝监测；地热能生产监测；永久或快速临时部署火山不稳定监测",
          carouselImage: null,
          carouselItems:[
            { src: require("@/assets/images/ESci/BroadbandSeismograph/p7c1.jpg") },
            { src: require("@/assets/images/ESci/BroadbandSeismograph/p7c2.jpg") },
            { src: require("@/assets/images/ESci/BroadbandSeismograph/p7c3.jpg") },
            { src: require("@/assets/images/ESci/BroadbandSeismograph/p7c4.jpg") },
            { src: require("@/assets/images/ESci/BroadbandSeismograph/p7c5.png") },
          ],
          galleryImage: null,
          galleryText:"· 120秒到100赫兹\n· 远程选择长周期角1秒，10秒和120秒\n· 在+/- 90°操作\n· 内部Minimus 24位数字化与SoH监控系统\n· 超低功率模式< 300mW\n· 128 GB双冗余micro SD\n· 多点触控液晶显示屏，带LED指示灯（普通版）\n· 蓝牙连接(GüVü App - Android和iOS)（普通版）\n· 防水硬质阳极氧化铝外壳(IP68)\n· PTP授时或GNSS (GPS, GLONASS，北斗或伽利略)\n· Discovery软件套件(Windows、Linux和macOS)"
        },
        {
          id: "tc_10006_0008",
          name: "Güralp 40T 系列地震仪",
          description: "· 体积小，坚固耐用，适合快速部署\n· Güralp 40系列三轴，宽频带地震仪是快速部署在具有挑战性的环境（如火山动荡或余震监测环境）的理想选择。它们也非常适合安装在具有中等环境噪声水平的地下空间。由于顶部有连接器，它们也适用于井中或直接掩埋设施。\n· 40T - 模拟信号仪器\n· 40TDE - 集成了基于Linux的数字转换器和采集模块，具有基于web的用户界面和通过串口和以太网通信\n· 生产厂家：Güralp Systems Ltd.\n· 应用：本地及区域监测；火山监测；微地震监测；被动地震成像；快速临时部署，如余震和火山动荡监测",
          carouselImage: null,
          carouselItems:[
            { src: require("@/assets/images/ESci/BroadbandSeismograph/p8c1.jpg") },
            { src: require("@/assets/images/ESci/BroadbandSeismograph/p8c2.jpg") },
            { src: require("@/assets/images/ESci/BroadbandSeismograph/p8c3.jpg") },
            { src: require("@/assets/images/ESci/BroadbandSeismograph/p8c4.jpg") },
            { src: require("@/assets/images/ESci/BroadbandSeismograph/p8c5.jpg") },
          ],
          galleryImage: null,
          galleryText:"· 60秒到100hz(1或30秒到100hz的选项)\n· 直接输出速度\n· 坚固、耐腐蚀的不锈钢外壳\n· 完全可调的调平脚\n· 不需要大量的锁定-插拔接头\n· IP68 -在3米深度持续浸泡72小时的防护"
        },
        {
          id: "tc_10006_0009",
          name: "Güralp 6T 系列地震仪",
          description: "· 超低功耗、轻量化，可在现场快速部署\n· Güralp 6系列是一系列超低功率、轻量、三轴、宽频带地震仪，非常适合一人快速安装在中等噪音的场所，如余震或火山动荡的监测。\n· 6T – 模拟信号仪器\n· 6TD - 与数字转换器集成为低功耗部署，闪存存储\n· 生产厂家：Güralp Systems Ltd.\n· 应用：诱发地震监测，如水力压裂；快速部署，如余震和火山动荡监测",
          carouselImage: null,
          carouselItems:[
            { src: require("@/assets/images/ESci/BroadbandSeismograph/p9c1.jpg") },
            { src: require("@/assets/images/ESci/BroadbandSeismograph/p9c2.jpg") },
            { src: require("@/assets/images/ESci/BroadbandSeismograph/p9c3.jpg") },
            { src: require("@/assets/images/ESci/BroadbandSeismograph/p9c4.jpg") },
          ],
          galleryImage: null,
          galleryText:"· 30秒到100赫兹\n· 2400 V/ms-1 (2 × 1200 V/ms-1)灵敏度\n· 硬质阳极氧化铝外壳\n· 自动中心，不需要大规模锁定\n· IP68 -在3米深度持续浸泡72小时的防护\n· 快速简单，一人安装\n· 不需要大量固定-插拔接头\n· 灵敏度高，动态范围广"
        },
        {
          id: "tc_10006_0010",
          name: "Güralp 3T 系列地震仪",
          description: "· Güralp 3T是具有三轴、宽频带、弱动地震仪，提供高质量的长周期监测。根据需要的性能水平和安装位置，可选型号如下：\n· 3T-360 -天文观测级别的仪器，适用于在非常安静的地点进行长时间监测\n· 3T-120 -用于地面和地下空间，可选择极地外壳，可在-55摄氏度下进行操作和冷启动测试。\n· 3T-120 PH-浅井外壳版，适合在潮湿环境中永久浸泡\n· Güralp还生产一种三轴3T井中传感器，可以单独使用，也可以与加速度计结合使用。详见Güralp 3TB深井版产品介绍。\n· 生产厂家：Güralp Systems Ltd.\n· 应用领域：局部、区域和远程地震监测；国家地震网；为《全面禁止核试验条约》监测核爆；固体潮观测；冰震监测(3T-120 极地版)",
          carouselImage: null,
          carouselItems:[
            { src: require("@/assets/images/ESci/BroadbandSeismograph/p10c1.jpg") },
            { src: require("@/assets/images/ESci/BroadbandSeismograph/p10c2.jpg") },
            { src: require("@/assets/images/ESci/BroadbandSeismograph/p10c3.jpg") },
          ],
          galleryImage: null,
          galleryText:"· 3T系列提供120 s和360 s两种标准的频带响应\n· 3T-120的自噪声在166 s 至 10 Hz 范围内低于NLNM\n· 3T-360的自噪声在200 s 至 10 Hz 范围内低于NLNM\n· 1Hz时的动态范围167dB\n· 灵敏度1500Vms-1(2X750V/ms-1)\n· 交叉轴抑制65 dB\n· 遥控/自动锁摆、解锁和质量定中\n· 工作倾角范围±2.5°"
        },
        {
          id: "tc_10006_0011",
          name: "Güralp 3ESPC 系列地震仪",
          description: "· 便携式，宽频带，弱动三轴地震仪\n· Güralp 3ESPC是一种弱动三轴宽频带地震仪。\n· 非常适合在噪音水平低至中等的地区进行长期、临时及永久安装。仪器的宽频带响应和低自噪声水平使其适用于所有尺度的地震监测:局部、区域和远震。\n· 除了模拟信号仪器，我们还提供两种数字型号:\n· 3ESPC -模拟信号仪器\n· 3ESPCD - 与数字转换器集成，低功耗部署，闪存存储\n· 3ESPCDE - 集成了数字转换器和采集模块，具有基于web的用户界面和通过串行和以太网通信\n· 生产厂家：Güralp Systems Ltd.\n· 应用：局部、区域和远程地震监测；国家地震网；永久火山监测网络；长期的微震监测；快速临时部署，如余震和火山动荡监测",
          carouselImage: null,
          carouselItems:[
            { src: require("@/assets/images/ESci/BroadbandSeismograph/p11c1.jpg") },
            { src: require("@/assets/images/ESci/BroadbandSeismograph/p11c2.jpg") },
            { src: require("@/assets/images/ESci/BroadbandSeismograph/p11c3.jpg") },
            { src: require("@/assets/images/ESci/BroadbandSeismograph/p11c4.jpg") },
            { src: require("@/assets/images/ESci/BroadbandSeismograph/p11c5.jpg") },
          ],
          galleryImage: null,
          galleryText:"· 60s - 100hz标准频率响应，(120 s低通角选项)\n· 高线性度:>水平107 dB，垂直111 dB\n· 在宽频带动态范围超过165 dB\n· 在宽频带内的低自噪声\n· 横轴抑制超过62 dB;传感器轴正交在±0.05°以内\n· 强大的自动质量锁定，解锁和定心\n· 硬质阳极氧化铝外壳\n· IP68 -在3米深度持续浸泡72小时的防护"
        },
        {
          id: "tc_10006_0012",
          name: "6TD 轻便型一体化三分量宽频带地震仪",
          description: "Güralp6TD是地震计和数据采集器组合成一体的宽频带地震仪，非常适合在中等噪音场所进行快速单人安装，总体测量线性度超过90dB。 三个分量的方向对于敏感轴的定位精度优于0.1°。 轻巧防水，符合IP67标准，全部采用O型密封圈，适合安装在各种环境中。",
          carouselImage: require("@/assets/images/ESci/BroadbandSeismograph/p12c1.jpg"),
          carouselItems:null,
          galleryImage: require("@/assets/images/ESci/BroadbandSeismograph/p12.png")
        },
        
      ]
    };
  }
};
</script>
<style lang="less" scoped>
@import "../../../assets/styles/GeoSciProduct.less";
</style>
<style scoped>

</style>
