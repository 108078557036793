
// 核心优势
const advantageList = [
  {
    index: 0,
    img: 'advantage-jingzhunshibie.jpg',
    icon: 'advantage-jingzhunshibie-icon.png',
    title: '精准识别',
    desc1: '基于深度学习的图像识别算法',
    desc2: '识别准确率 > 99.9%'
  },
  {
    index: 1,
    img: 'advantage-duobiaoheyi.jpg',
    icon: 'advantage-duobiaoheyi-icon.png',
    title: '多表合一',
    desc1: '适用于不同品牌、不同型号',
    desc2: '不同通信协议的仪表'
  },
  {
    index: 2,
    img: 'advantage-youtuweizheng.jpg',
    icon: 'advantage-youtuweizheng-icon.png',
    title: '有图为证',
    desc1: '服务端备份照片，与读数对应',
    desc2: '方便审计，避免用量纠纷'
  }
]

// 智能AI相机
const deviceList = [
  {
    index: 0,
    img: 'device-meter.png',
    title: '赋能机械表',
    desc: '发挥机械表工作稳定可靠，寿命长等优势'
  },
  {
    index: 1,
    img: 'device-charge.png',
    title: '超长待机  稳定工作',
    desc: '每日一拍，六年无需更换电池'
  },
  {
    index: 2,
    img: 'device-tool.png',
    title: '施工简单  便于维护',
    desc: '在表具上加装智能AI相机，无需布线和改动旧表'
  },
  {
    index: 3,
    img: 'device-lock.png',
    title: '防盗设计  异常告警',
    desc: '防盗防破坏设计，拆卸实时报警'
  }
]

// 图像识别算法
const algorithmList = [
  {
    index: 0,
    img: 'algorithm-meter.png',
    title: '全表型识别能力',
    desc: '全仪表类型识别的自学习算法，支持字轮表、指针表、LCD液晶屏、数码管、刻度表等表型'
  },
  {
    index: 1,
    img: 'algorithm-pic.png',
    title: '异常图片稽查',
    desc: '表盘图像质量分析，有效应对复杂表盘环境的算法，如水雾、变形、模糊、划痕、光斑、污渍等'
  }
]

// 云平台
const platformList = [
  {
    index: 0,
    img: 'platform-pic.png',
    title: '图片备份 方便审计',
    desc: '仪表照片云端可见，方便人工审计，解决用量纠纷'
  },
  {
    index: 1,
    img: 'platform-location.png',
    title: '同时多地 统一集抄',
    desc: '获取同一时间所有地点的仪表数据，避免人工抄表的漏抄、错抄、估抄等问题'
  },
  {
    index: 2,
    img: 'platform-ralation.png',
    title: '仪表关系 一目了然',
    desc: '构建清晰的仪表总分关系图，方便分析产销差率和用量分布，及时发现偷、漏、损问题'
  }
]
// 技术壁垒
const rampartList = [
  {
    index: 0,
    img: 'tecnology-dev.png',
    title: '软硬件自主开发',
    desc: '从终端、算法、云平台、应用系统到小程序，全部自主研发，支持持续升级优化，具备很高的产品安全性和应变灵活性'
  },
  {
    index: 1,
    img: 'tecnology-cloud.png',
    title: '端云一体化',
    desc: '端云协同实现产品各部分的有效对接和快速联动，大大提升了业务处理能力和部署速度，为各行业解决方案奠定了坚实基础'
  },
  {
    index: 2,
    img: 'tecnology-eye.png',
    title: '突破业界识别点',
    desc: '算法已实现对复杂环境下表盘图片的高精准识别，无惧水雾、水渍、光斑、划痕等各种干扰因素'
  },
  {
    index: 3,
    img: 'tecnology-iot.png',
    title: '掌握前沿AI与IoT技术',
    desc: 'AI赋予IoT高速运转的“大脑”，IoT保障AI智能识别的图片来源和数据价值。用设备连接仪表，用技术连接未来'
  },
  {
    index: 4,
    img: 'tecnology-cycle.png',
    title: '广泛使用性',
    desc: '算法支持全仪表类型识别，在使用时无需限定仪表的品牌、型号及通信协议，广泛适用于各类水电气热及工业仪表'
  },
  {
    index: 5,
    img: 'tecnology-patent.png',
    title: '专利  知识产权布局',
    desc: '已构建完善、严密的专利及知识产权保护网络，能够对公司发展战略和商业模式提供强有力的支持'
  }
]

// 解决方案
const resolveContent = [
  {
    index: 0,
    icon: 'icon-resolve-fuelGas.png',
    iconSelected: 'icon-resolve-fuelGas-selected.png',
    name: '智慧燃气',
    description: '解决燃气公司在采购天然气时面临采气量难以精准预估的痛点，精准调度工商业大用户的用气量，及时发现计量异常',
    keyword: ['安全', '降本', '省心', '省心'],
    bg: 'resolve-fuelGas.png',
    route: '/fuelGas'
  },
  {
    index: 1,
    icon: 'icon-resolve-oilGas.png',
    iconSelected: 'icon-resolve-oilGas-selected.png',
    name: '智慧油气',
    description: '解决人工巡检任务繁重，避免问题发现不及时、精细化运营难等问题，保障现场生产安全，提质增产',
    keyword: ['远程巡检', '巡检效率', '能耗监控', '有图可依'],
    bg: 'resolve-oilGas.png',
    route: '/oilGas'
  },
  {
    index: 2,
    icon: 'icon-resolve-substations.png',
    iconSelected: 'icon-resolve-substations-selected.png',
    name: '智慧变电站',
    description: '解决变电站仪表高频采集问题，缩短改造时间，降低改造成本，保障巡检工作安全进行，大幅减少路途时间和表计现场巡检的工作时间',
    keyword:['安全', '降本', '省心', '省心'],
    bg: 'resolve-substations.png',
    route: '/substations'
  },
  {
    index: 3,
    icon: 'icon-resolve-water.png',
    iconSelected: 'icon-resolve-water-selected.png',
    name: '智慧水务',
    description: '解决高危高难场景抄表难题，避免估抄、漏抄、错抄，降低产销差，提升运营管控能力',
    keyword: ['便捷', '安全', '降本', '增收'],
    bg: 'resolve-water.jpg',
    route: '/water'
  },
  {
    index: 4,
    icon: 'icon-resolve-wuye.png',
    iconSelected: 'icon-resolve-wuye-selected.png',
    name: '智慧物业',
    description: '解决日益繁重的抄表难题，提高抄收效率，有图为证，避免账单纠纷',
    keyword: ['安全', '降本', '省力', '省心'],
    bg: 'resolve-wuye.jpg',
    route: '/property'
  },
  {
    index: 5,
    icon: 'icon-resolve-factory.png',
    iconSelected: 'icon-resolve-factory-selected.png',
    name: '智慧工厂',
    description: '解决实时监控难题，发现异常后，实时推送告警，提升安全管理水平',
    keyword: ['统一', '安全', '高效'],
    bg: 'resolve-factory.jpg',
    route: '/factory'
  }
]

// const newsList = [
//   {
//     index: 0,
//     img: 'news0.jpg',
//     title: '官媒点赞！阅读量3.2亿+！羿娲如何开启智慧养老新时代',
//     time: '2020-12-10',
//     summary: '昨晚，人民日报官方微博发布了一篇《上海：独居老人水表12小时不走自动预警》的推文，介绍了羿娲科技在上海市长宁区...'
//   },
//   {
//     index: 1,
//     img: "news1.jpg",
//     title: "疫情期间“云”抄表，东升博展发感谢信“点赞”羿娲科技",
//     time: "2020-03-23",
//     summary:
//       "春暖花开的三月，羿娲科技收到了一份特殊的礼物，一封来自北京东升博展科技发展有限公司的感谢信..."
//   },
//   {
//     index: 2,
//     img: "news2.jpg",
//     title: "“羿读”系统助力打赢疫情歼灭战和经济保卫战",
//     time: "2020-02-21",
//     summary:
//       "新型冠状病毒疫情爆发以来，各地都采取了强有力的防控排查措施，力图早日结束这场战役。随着预计..."
//   },
//   {
//     index: 3,
//     img: "news3.jpg",
//     title: "羿娲科技亮相5G标准发布及产业推动大会",
//     time: "2020-01-14",
//     summary:
//       "2020年1月9日，中国通信标准化协会主办的5G标准发布及产业推动大会在北京召开。会议举行了我国..."
//   }
// ];

export default {
  advantageList,
  deviceList,
  algorithmList,
  platformList,
  rampartList,
  resolveContent,
  // newsList
}
