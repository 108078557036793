<template>
  <div class="water-wrap">
    <!-- banner 开始 -->
    <div
      class="page-banner"
      :style="{background: '#1D212E url('+require('@/assets/images/oilGas/banner.jpg')+') no-repeat', backgroundSize: 'cover'}"
    >
      <div class="banner-mask"></div>
      <div class="valid-area">
        <div class="align-center">
          <p class="page-banner-title">智慧油气解决方案</p>
          <p class="page-banner-description" v-if="widOver768">解决人工巡检任务繁重，避免问题发现不及时、精细化运营难等问题，</p>
          <p class="page-banner-description" v-if="widOver768">保障现场生产安全，提质增产</p>
          <p class="page-banner-description" v-if="!widOver768">解决高危高难场景抄表难题，</p>
          <p class="page-banner-description" v-if="!widOver768">避免估抄、漏抄、错抄，降低产销差，</p>
          <p class="page-banner-description" v-if="!widOver768">提升运营管控能力</p>
        </div>
      </div>
    </div>
    <!-- banner 结束 -->
    <!-- 智慧油气 开始 -->
    <div class="part part-light-bg letter-space">
      <div class="valid-area">
        <!-- 标题 -->
        <p class="part-title">智慧油气</p>
        <!-- 装饰线 -->
        <p class="decoration-line"></p>
        <!-- 主内容区 -->
        <div class="main-content layout-flex reslove-case-main" v-if="widOver768">
          <div class="resolve-case align-right">
            <div class="resolve-case-item">
              <p class="resolve-case-title">远程巡检</p>
              <p class="resolve-case-description">大幅减少现场巡检频次，替代高危高难环境巡检工作</p>
              <p class="resolve-case-description">保障多点位同时统抄，避免恶劣天气巡检效率低，及有毒有害气体空间威胁人员生命安全</p>
            </div>
            <div class="resolve-case-item">
              <p class="resolve-case-title">能耗监控</p>
              <p class="resolve-case-description">在仪器仪表周围直接加装采集终端，无需布线和改动管线及旧表</p>
              <p class="resolve-case-description">实现同一平台统一管理日/周/月能耗用量监控，助力生产现场减少碳排放，节能降碳，辅助人员分析</p>
            </div>
          </div>
          <div class="resolve-case-pic">
            <img src="@/assets/images/oilGas/oilGas.png" />
          </div>
          <div class="resolve-case align-left">
            <div class="resolve-case-item">
              <p class="resolve-case-title">巡检效率</p>
              <p class="resolve-case-description">同一时刻统一抄录所有仪器仪表的读数及状态,提升巡检效率</p>
              <p class="resolve-case-description">采油树井底压力，注水井注水流量，油气管道输送是否正常，配电室仪器仪表运行状态，减少错报率</p>
            </div>
            <div class="resolve-case-item">
              <p class="resolve-case-title">有图可依</p>
              <p class="resolve-case-description">服务端保存仪器仪表图片，历史数据可追溯，图片留痕</p>
              <p
                class="resolve-case-description"
              >无电子误差超低功耗和图像压缩技术，每日采集一次可用3年以上。适用于不同品牌、不同型号的仪表和设备，无需协议对接</p>
            </div>
          </div>
        </div>
        <div class="main-content reslove-case-main-768" v-if="!widOver768">
          <div class="reslove-img">
            <img src="@/assets/images/water/water-res.png" />
          </div>
          <div class="resolve-case-768-item">
            <p class="resolve-case-768-title">便捷</p>
            <p class="resolve-case-768-description">居民线上便捷缴费</p>
            <p class="resolve-case-768-description">替代高难度抄表，如深水井下、隧道内、狭小空间、居民住宅内等</p>
          </div>
          <div class="resolve-case-768-item">
            <p class="resolve-case-768-title">安全</p>
            <p class="resolve-case-768-description">替代高危环境抄表，确保人员生命安全</p>
            <p class="resolve-case-768-description">供水管网监控，监测水表工作状态与流量，预防爆管引发的安全事故</p>
          </div>
          <div class="resolve-case-768-item">
            <p class="resolve-case-768-title">降本</p>
            <p class="resolve-case-768-description">节约智能化改造成本，无需断水施工</p>
            <p class="resolve-case-768-description">用水量监控，避免估抄、漏抄、人情水费，经济损失有据可查</p>
          </div>
          <div class="resolve-case-768-item">
            <p class="resolve-case-768-title">增收</p>
            <p class="resolve-case-768-description">分析产销差率，及时发现跑冒滴漏区域，提出整改建议，最终实现水费增收</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 智慧油气 结束 -->
    <!-- 应用案例 开始 -->
    <div class="part part-gray-bg letter-space">
      <div class="valid-area">
        <!-- 标题 -->
        <p class="part-title">应用案例</p>
        <!-- 装饰线 -->
        <p class="decoration-line"></p>
        <!-- 主内容区 -->
        <div class="main-content">
          <div class="case">
            <div class="case-info">
              <p>
                <span>客户&nbsp;&nbsp;—&nbsp;&nbsp;</span>某油气田采油厂
              </p>
              <p>
                <span>时间&nbsp;&nbsp;—&nbsp;&nbsp;</span>2021年06月
              </p>
              <p>
                <span>表具&nbsp;&nbsp;—&nbsp;&nbsp;</span>采油设备压力表、管道流量计
              </p>
              <p>
                <span>效果&nbsp;&nbsp;—&nbsp;&nbsp;</span>降低财产损失：采油树本身价格昂贵，及时发现资产丢失，损坏或
                <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;盗窃，进行远程资产盘点
                <br />
                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;—&nbsp;&nbsp;</span>沉睡井转生产：检测井口压力到达一定临界值时，沉睡井到达出油
                <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;出气标准，可恢复成生产井
                <br />
                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;—&nbsp;&nbsp;</span>提高采油效率：监控采油设备压力值，检测压力变化规律，及时调
                <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;整设备参数及阀门开合大小，提高采油效率
                <br />
                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;—&nbsp;&nbsp;</span>确保生产安全：及时发现采油设备数值异常情况，如避免因压力表
                <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;失效而导致生产事故发生，确保采油作用安全运行
              </p>
            </div>
            <div class="case-pic">
              <img src="@/assets/images/oilGas/case-poi.png" />
            </div>
          </div>
          <div class="case-app">
            <div class="case-app-box">
              <div class="case-data">
                <p style="width: 28%">（压力表检测数据）</p>
                <p style="width: 32%">（历史读数走势图）</p>
                <p style="width: 27%">（流量计检测数据）</p>
              </div>
              <img class="case-data-pic" src="@/assets/images/oilGas/case.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 应用案例 结束 -->
    <!-- 安装照片 开始 -->
    <div
      class="part letter-space"
      :style="{background:'#2B2E46 url('+require('@/assets/images/home/advantage-bg.png')+') no-repeat 90px 90px / 90% 90%'}"
    >
      <div class="valid-area">
        <!-- 标题 -->
        <p class="part-title dark-bg-text-white">安装照片</p>
        <!-- 装饰线 -->
        <p class="decoration-line"></p>
        <div class="main-content">
          <img
            src="@/assets/images/oilGas/azxg.png"
            style="width: 100%;display: block;margin:0 auto;"
          />
        </div>
      </div>
    </div>
    <!-- 安装照片 结束 -->
  </div>
</template>

<script>
import $ from "jquery";
export default {
  name: "oilGas",
  data() {
    return {
      widOver768: false
    };
  },
  methods: {
    appear() {
      if (this.widOver768) {
        $(".resolve-case-item")
          .eq(0)
          .delay(200)
          .animate({ right: 0, opacity: 1 }, 600);
        $(".resolve-case-item")
          .eq(2)
          .delay(400)
          .animate({ left: 0, opacity: 1 }, 600);
        $(".resolve-case-item")
          .eq(1)
          .delay(600)
          .animate({ right: 0, opacity: 1 }, 600);
        $(".resolve-case-item")
          .eq(3)
          .delay(800)
          .animate({ left: 0, opacity: 1 }, 600);
      } else {
        $(".resolve-case-768-item")
          .eq(0)
          .delay(200)
          .animate({ right: 0, opacity: 1 }, 600);
        $(".resolve-case-768-item")
          .eq(2)
          .delay(400)
          .animate({ left: 0, opacity: 1 }, 600);
        $(".resolve-case-768-item")
          .eq(1)
          .delay(600)
          .animate({ right: 0, opacity: 1 }, 600);
        $(".resolve-case-768-item")
          .eq(3)
          .delay(800)
          .animate({ left: 0, opacity: 1 }, 600);
      }
    },
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      let offsetTop =
        document.querySelector(".case-app").parentElement.parentElement
          .offsetTop - 300;
      if (scrollTop >= offsetTop) {
        $(".case-app").animate({ top: 0, opacity: 1 }, 300);
      }
    },
    getWid() {
      let wid =
        document.documentElement.clientWidth || document.body.clientWidth;
      this.widOver768 = wid <= 768 ? false : true;
    }
  },
  mounted() {
    this.getWid();
    this.appear();
    window.addEventListener("scroll", () => {
      if (this.$route.name == "oilGas") {
        this.handleScroll();
      }
    });
    window.addEventListener("resize", this.getWid);
  },
  updated() {
    this.appear();
  }
};
</script>

<style lang="less" scoped>
.page-banner {
  .valid-area {
    height: 100%;
  }
  .align-center {
    width: 100%;
    left: 0;
    transform: translate(0, -50%);
    @media screen and (max-width: 768px) {
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
