<template>
  <div class="property-wrap">
    <!-- banner 开始 -->
    <div
      class="page-banner"
      :style="{background: '#1D212E url('+require('@/assets/images/fuelGas/banner.png')+') no-repeat', backgroundSize: 'cover'}"
    >
      <div class="banner-mask"></div>
      <div class="valid-area">
        <div class="align-center">
          <p class="page-banner-title">智慧燃气解决方案</p>
          <p class="page-banner-description">解决燃气公司在采购天然气时面临采气量难以精准预估的痛点</p>
          <p class="page-banner-description">精准调度工商业大用户的用气量，及时发现计量异常</p>
        </div>
      </div>
    </div>
    <!-- banner 结束 -->
    <!-- 智慧燃气 开始 -->
    <div class="part part-light-bg letter-space">
      <div class="valid-area">
        <!-- 标题 -->
        <p class="part-title">智慧燃气</p>
        <!-- 装饰线 -->
        <p class="decoration-line"></p>
        <!-- 主内容区 -->
        <div class="main-content layout-flex reslove-case-main" v-if="widOver768">
          <div class="resolve-case align-right">
            <div class="resolve-case-item">
              <p class="resolve-case-title">安全</p>
              <p class="resolve-case-description">掌握设备用能趋势，合理调度控制，及时发现用气异常</p>
              <p class="resolve-case-description">远程统抄运行稳定、数据采集率高，后期免维护</p>
            </div>
            <div class="resolve-case-item">
              <p class="resolve-case-title">省力</p>
              <p class="resolve-case-description">适用于各类型号仪器仪表，无需协议对接，实现同一平台统一管理</p>
              <p class="resolve-case-description">解决人工现场抄表强度大、误差大、不实时、效率低等问题</p>
            </div>
          </div>
          <div class="resolve-case-pic">
            <img src="@/assets/images/property/property.png" />
          </div>
          <div class="resolve-case align-left">
            <div class="resolve-case-item">
              <p class="resolve-case-title">降本</p>
              <p class="resolve-case-description">避免管道拆卸，停气停工，后装优势可快速实现各类流量计远程数据采集</p>
              <p class="resolve-case-description">方便用户进行设备管理和能耗分析，实现用能精细化管理，降低经济损失</p>
            </div>
            <div class="resolve-case-item">
              <p class="resolve-case-title">省心</p>
              <p class="resolve-case-description">用气量一目了然，仪表照片留底，减少账单纠纷</p>
              <p class="resolve-case-description">减少现场巡视，燃气公司、工商业大用户可使用小程序便捷查询每日用量</p>
            </div>
          </div>
        </div>
        <div class="main-content reslove-case-main-768" v-if="!widOver768">
          <div class="reslove-img">
            <img src="@/assets/images/property/property-res.png" />
          </div>
          <div class="resolve-case-768-item">
            <p class="resolve-case-768-title">安全</p>
            <p class="resolve-case-768-description">发现故障仪表，排查安全隐患</p>
            <p class="resolve-case-768-description">掌握设备用能趋势，合理调度控制</p>
          </div>
          <div class="resolve-case-768-item">
            <p class="resolve-case-768-title">降本</p>
            <p class="resolve-case-768-description">快速实现机械表远程数据采集</p>
            <p class="resolve-case-768-description">实现用能精细化管理，降低经济损失</p>
          </div>
          <div class="resolve-case-768-item">
            <p class="resolve-case-768-title">省力</p>
            <p class="resolve-case-768-description">解决人工现场抄表强度大、</p>
            <p class="resolve-case-768-description">误差大、不实时、效率低等问题</p>
          </div>
          <div class="resolve-case-768-item">
            <p class="resolve-case-768-title">省心</p>
            <p class="resolve-case-768-description">改变租赁双方抄表模式，建立客户信任关系</p>
            <p class="resolve-case-768-description">水电气用量一目了然，仪表照片留底，减少账单纠纷</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 智慧燃气 结束 -->
    <!-- 应用案例 开始 -->
    <div class="part part-gray-bg letter-space">
      <div class="valid-area">
        <!-- 标题 -->
        <p class="part-title">应用案例</p>
        <!-- 装饰线 -->
        <p class="decoration-line"></p>
        <!-- 主内容区 -->
        <div class="main-content">
          <div class="case">
            <div class="case-info">
              <p>
                <span>客户&nbsp;&nbsp;—&nbsp;&nbsp;</span>某城市燃气公司
              </p>
              <p>
                <span>时间&nbsp;&nbsp;—&nbsp;&nbsp;</span>2022年03月
              </p>
              <p>
                <span>表具&nbsp;&nbsp;—&nbsp;&nbsp;</span>工商业大用户燃气表、楼宇电表
              </p>
              <p>
                <span>效果&nbsp;&nbsp;—&nbsp;&nbsp;</span>智能抄表终端持续正常运行近4个月，数据上传率和准确率均达到100%，为精准调度和及时发现计量异常提供了坚实的技术保障。通过及时传回大工业用户和商业采暖锅炉用户的用气数据，节省了数千万元的气量采购费用，为公司调度气源和保证供气提供了重要的数据支撑和决策依据
              </p>
            </div>
            <div class="case-pic">
              <img src="@/assets/images/fuelGas/case-img.png" />
            </div>
          </div>
          <div class="case-app">
            <div class="case-app-box">
              <div class="case-data">
                <p style="width: 34%">（仪表抄录详细数据）</p>
                <p style="width: 40%">（仪表读数变化趋势）</p>
                <p style="width: 33%">（仪表用量变化趋势）</p>
              </div>
              <img class="case-data-pic" src="@/assets/images/fuelGas/case.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 应用案例 结束 -->
    <!-- 安装照片 开始 -->
    <div
      class="part letter-space"
      :style="{background:'#2B2E46 url('+require('@/assets/images/home/advantage-bg.png')+') no-repeat 90px 90px / 90% 90%'}"
    >
      <div class="valid-area">
        <!-- 标题 -->
        <p class="part-title dark-bg-text-white">安装照片</p>
        <!-- 装饰线 -->
        <p class="decoration-line"></p>
        <div class="main-content">
          <img
            src="@/assets/images/fuelGas/aztp.png"
            style="width: 100%;display: block;margin:0 auto;"
          />
        </div>
      </div>
    </div>
    <!-- 安装照片 结束 -->
  </div>
</template>

<script>
import $ from "jquery";
export default {
  name: "fuelGas",
  data() {
    return {
      widOver768: false
    };
  },
  methods: {
    appear() {
      if (this.widOver768) {
        $(".resolve-case-item")
          .eq(0)
          .delay(200)
          .animate({ right: 0, opacity: 1 }, 600);
        $(".resolve-case-item")
          .eq(2)
          .delay(400)
          .animate({ left: 0, opacity: 1 }, 600);
        $(".resolve-case-item")
          .eq(1)
          .delay(600)
          .animate({ right: 0, opacity: 1 }, 600);
        $(".resolve-case-item")
          .eq(3)
          .delay(800)
          .animate({ left: 0, opacity: 1 }, 600);
      } else {
        $(".resolve-case-768-item")
          .eq(0)
          .delay(200)
          .animate({ right: 0, opacity: 1 }, 600);
        $(".resolve-case-768-item")
          .eq(2)
          .delay(400)
          .animate({ left: 0, opacity: 1 }, 600);
        $(".resolve-case-768-item")
          .eq(1)
          .delay(600)
          .animate({ right: 0, opacity: 1 }, 600);
        $(".resolve-case-768-item")
          .eq(3)
          .delay(800)
          .animate({ left: 0, opacity: 1 }, 600);
      }
    },
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      let offsetTop =
        document.querySelector(".case-app").parentElement.parentElement
          .offsetTop - 300;
      if (scrollTop >= offsetTop) {
        $(".case-app").animate({ top: 0, opacity: 1 }, 300);
      }
    },
    getWid() {
      let wid =
        document.documentElement.clientWidth || document.body.clientWidth;
      this.widOver768 = wid <= 768 ? false : true;
    }
  },
  mounted() {
    this.getWid();
    this.appear();
    window.addEventListener("scroll", () => {
      if (this.$route.name == "fuelGas") {
        this.handleScroll();
      }
    });
    window.addEventListener("resize", this.getWid);
  },
  updated() {
    this.appear();
  }
};
</script>

<style lang="less" scoped>
.page-banner {
  .valid-area {
    height: 100%;
  }
  .align-center {
    width: 100%;
    left: 0;
    transform: translate(0, -50%);
    @media screen and (max-width: 768px) {
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
.reslove-case-main .resolve-case .resolve-case-item:nth-child(2n) {
  margin-top: 232px;
}
</style>
