<template>
  <!-- 海底地震仪 -->
  <div>
    <div v-for="(product, index) in products" :key="index">
      <div v-if="product.id === productId" class="product-details">
        <div class="product-top">
          <h2 v-html="product.name"></h2>
          <p class="product-description" v-html="product.description"></p>
        </div>
        <div v-if="productId === 'tc_10009_00003' || productId === 'tc_10009_00004'" class="carousel-container">
          <el-carousel :interval="3000" autoplay style="width: 400px;">
            <!-- 轮播图内容 -->
            <el-carousel-item v-for="(item, index) in product.carouselItems" :key="index">
              <img :src="item.src" alt="轮播图" />
            </el-carousel-item>
          </el-carousel>
        </div>
        <div v-if="productId === 'tc_10009_00001' || productId === 'tc_10009_00002'" class="carousel-container">
          <img :src="product.carouselItems" style="width: 400px;" />
        </div>
      </div>
      <div v-if="product.id === productId" class="parameter-vbom">
        <div class="parameter-title">
          <h3>产品参数</h3>
          <div class="separator"></div>
        </div>
        <div v-if="product.id === productId" class="image-gallery">
          <p v-html="product.parameter"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["productId"],
  data() {
    return {
      products: [
        {
          id: "tc_10009_00003",
          name: "Aquarius海底地震仪",
          description:
            "Aquarius海底地震仪是Liber海底地震仪的改进，利用声波遥测能力，在没有电缆的情况下，将海底的实时地震数据传输到地面。<br>Aquarius OBS系统是一种紧凑的自由投放水下地震观测站，可在+/- 90度下完全工作，理想的自由投放部署在深度达6000米的海底。低而稳固和紧凑的设计经过优化，降低了电流产生的噪音，同时降低了运输和部署成本。<br>该地震仪具有120秒到100赫兹之间的平坦响应，用户可选择120秒到1秒的长周期角，因此您可以根据环境定制响应。系统中还包括APG和水听器等其他传感器。<br>部署后，使用声波遥测技术从海底传输仪器健康状态参数和噪声性能图，选择所需的传感器响应，并测量OBS压力容器中数字化仪的时间偏移量。如果需要，还可以利用声波遥测技术将震动触发数据传输到浮标或钻机上的接收器，以接近实时的方式进行警报应用。<br>一旦进入海底，Aquarius就可以使用集成的定位和测距软件进行精确定位。它也兼容Sonardyne的USBL系统。<br>Aquarius回收系统可以通过声学连接、预先编程的超时或可选的临界水平电池触发器进行初始化。还可激活燃烧丝系统并释放镇重锚从而回收仪器，卫星跟踪系统在海面上跟踪仪器。<br>生产厂家：Güralp Systems Ltd.<br>应用：局部和区域地震研究；能源勘探；碳储存；噪声调查；地震监测；地震/海啸预警",
          carouselItems: [
            {
              src: require("@/assets/images/ESci/SubmarineSeismograph/S3.jpg")
            },
            {
              src: require("@/assets/images/ESci/SubmarineSeismograph/S3.1.png")
            },
            {
              src: require("@/assets/images/ESci/SubmarineSeismograph/S3.2.png")
            },
            {
              src: require("@/assets/images/ESci/SubmarineSeismograph/S3.3.png")
            }
          ],
          parameter:
            "·&nbsp;&nbsp; 先进的三轴宽频带地震仪可在±90°下工作，使其成为自由投放部署到海底6000米深处的理想工具<br>·&nbsp;&nbsp; 三轴磁强计和MEMS加速度计记录地震仪在海底的三维位置，以便在后期处理期间进行数据旋转<br>·&nbsp;&nbsp; 地震仪的平面响应在120秒到100赫兹之间，用户可选择120秒到1秒的长周期角，因此您可以根据环境定制响应<br>·&nbsp;&nbsp; 电池的大小可以记录长达18个月的地震数据，如果需要，还可以延长记录时间<br>·&nbsp;&nbsp; 利用声波遥测技术，从海底传送仪器健康状态参数和噪声性能图，选择所需的传感器响应，测量OBS压力容器内数字化仪器的时间偏移量<br>·&nbsp;&nbsp; 可选择利用声波遥测技术，将震动触发数据传输到浮标或钻机上位于水面的接收器，以接近实时的方式进行警报应用<br>·&nbsp;&nbsp; 地面和海底Aquarius之间的数据传输采用直接声学通信，传输速度可达9000 bps<br>·&nbsp;&nbsp; Aquarius装有绝对压力计(APG)和水听器。可选择升级这些功能，例如应用于海啸探测<br>·&nbsp;&nbsp; 数据存储在本地的双冗余128gb Micro-SD卡中，恢复后可通过千兆以太网下载<br>·&nbsp;&nbsp; 连接Aquarius和Güralp甲板单元的一根电缆为数据检索和系统配置提供电量。可通过一个专用连接器连接到外部充电器，对电池进行单独和并发的充电，这样系统就可以尽可能快地重新部署<br>·&nbsp;&nbsp; 该系统配备了可充电的锂离子电池，可以快速轻松地重新部署，相当于每个月大约一个小时的充电时间"
        },
        {
          id: "tc_10009_00002",
          name: "Maris海底地震仪",
          description:
            "实时电缆OBS，可插入或取心到海底，适用深度高达3000米/ 9854英尺*<br>Maris采用钛材料，配备了一个宽频带传感器，可以在任何角度全面运行，支持多种安装方式。插入海底可减少环境噪声，并在浅层部署时增加拖网阻力。内部磁力计和MEMS加速度计一起工作，自动补偿倾斜和水平方向，以提供高质量的波形。<br>Maris系统可以是单个仪器使用，也可以是一串多达8个的仪器组合使用，其中包括Minimus数据采集器，安装在一个单独的容器中，通过以太网直接将数据传输到电缆系统。Minimus还提供了一种低延迟模式，在运行因果过滤器的同时，为地震预警应用传统的因果过滤器。宽频带传感器的频率范围可以设计成适合项目的要求。<br>*有适用于更深海底的版本可以选择。<br>生产厂家：Güralp Systems Ltd.<br>应用：插入或取心到海底，以改善耦合；水下机器人部署；电缆连接到海岸或浮标系统；实时数据流；局部、区域和远程地震监测；火山监测；地震/海啸预警",
          carouselItems: require("@/assets/images/ESci/SubmarineSeismograph/M1.png"),
          parameter:
            "·&nbsp;&nbsp;适用深度达3000米(9854英尺)<br>·&nbsp;&nbsp;地下埋藏提高数据质量和拖网阻力<br>·&nbsp;&nbsp;易于串在一起的链状阵列<br>·&nbsp;&nbsp;5级钛套管<br>·&nbsp;&nbsp;Minimus接口单元在现场数字化数据，通过以太网实时传输到陆上数据中心<br>·&nbsp;&nbsp;可选的低延迟模式运行因果过滤器<br>·&nbsp;&nbsp;电源和以太网流通过湿式耦合连接器<br>·&nbsp;&nbsp;与陆上GPS同步的绝对时间<br>·&nbsp;&nbsp;可灵活连接各种电缆系统，如光纤<br>·&nbsp;&nbsp;通过水下机器人进行部署和电缆安装"
        },
        {
          id: "tc_10009_00001",
          name: "Orcus海底地震仪",
          description:
            "实时电缆OBS，可完全或部分埋入海底，深度可达6000米/19,685英尺<br>外壳是双相不锈钢或钛，适用不同的部署深度，以防止腐蚀。外壳独特的球形形状可在高压下保护仪器，底部的金属板确保地面耦合。可选混凝土圆顶罩，可以用来减少噪音和保护仪器不被浅水捕鱼网拖走<br>集成先进技术的Güralp Affinity数据采集器将数据实时传输到电缆系统。传感器的频率范围可以设计成适合项目的要求。如果需要一个以上的响应范围，可以安装额外的传感器或水听器。非地震传感器，如海啸预警的绝对压力传感器也可以集成。<br>生产厂家：Güralp Systems Ltd.<br>应用：电缆连接至海岸或浮标系统；实时数据流；局部、区域和远程地震监测；地震/海啸预警",
          carouselItems: require("@/assets/images/ESci/SubmarineSeismograph/O1.png"),
          parameter:
            "·&nbsp;&nbsp;适用深度达6000米(19,685英尺)<br>·&nbsp;&nbsp;电缆连接至海岸或浮标系统<br>·&nbsp;&nbsp;可选的附加传感器包括绝对压力传感器，水听器，电流表和温度计<br>·&nbsp;&nbsp;铝或钛外壳，耐腐蚀，防水<br>·&nbsp;&nbsp;可选混凝土圆顶，以减少噪音和保护渔船<br>·&nbsp;&nbsp;现场数字化数据，并实时传输到陆上数据中心<br>·&nbsp;&nbsp;数据采集器定时与NTP或PTP协议同步。<br>·&nbsp;&nbsp;带有以太网的外部传感器，可使用相同的计时协议<br>·&nbsp;&nbsp;灵活地连接各种电缆系统，如以太网或光纤<br>·&nbsp;&nbsp;恒压电源加备用电池<br>·&nbsp;&nbsp;通过绞盘进行部署，需要水下机器人支持进行更深入的安装<br>·&nbsp;&nbsp;自我升级系统"
        },
        {
          id: "tc_10009_00004",
          name: "AQUARIUS 声学遥测海底地震仪（OBS）",
          description:
            "Aquarius是一款海底地震仪 (OBS)，它无需电缆，采用声学遥测技术，将海底地震数据近实时地传回水面。<br>Aquarius是新型的独立式自由下落OBS。它自带声学遥测功能，投放深度达 6000米，连续工作时长达18个月，投放简便，定位精确。<br>Aquarius OBS 设计时便重视降低运输和安装成本。<br>Aquarius 是带有声学调制解调器的OBS 。它可以由较小的船投放，下落至海底后，可以利用大船上配备的通用型超短基线系统（USBL）对其进行精确定位。<br>Aquarius 配备的声学链路还可以用来激活燃烧丝系统并释放镇重锚从而回收仪器。铝合金耐压外壳周围的复合泡沫材料提供提升力，将仪器带回水面。<br>OBS释放后，置于玻璃罩内的卫星追踪系统对浮出水面的仪器中进行跟踪，并可以通过电子邮件、卫星电话或普通手机的短信和网络平台等方式自动发送跟踪信息。在夜晚进行回收时，玻璃罩内的频闪LED灯也将指示OBS所处的方向和位置。",
          carouselItems: [
            {
              src: require("@/assets/images/ESci/SubmarineSeismograph/OBS1.png")
            },
            {
              src: require("@/assets/images/ESci/SubmarineSeismograph/OBS1.1.png")
            }
          ],
          parameter:
            "·&nbsp;&nbsp;AQUARIUS系列的OBS设计紧凑，配备声学调制解调器<br>·&nbsp;&nbsp;它采用数字反馈三分量宽频带地震计，以任意角度工作，在120s 至100Hz 之间平坦响应<br>·&nbsp;&nbsp;内置的三轴磁力计和三轴MEMS 加速度计可以记录OBS 在海底的三维姿态信息<br>·&nbsp;&nbsp;Aquarius 和Aquarius+ 安装完成后，可以将健康状态参数和噪声水平信息发送到水面装置<br>·&nbsp;&nbsp;Aquarius+ 的声学通讯功能可以将数据从海底传输到水面，传输速率高达9000 bps<br>·&nbsp;&nbsp;Aq uarius+ 利用STA/LTA 触发器探测地震事件并自动传输压缩列表，然后用户可以选择某些事件，传输对应的完整数据<br>·&nbsp;&nbsp;配有双冗余 256 GB Micro-SD 卡<br>·&nbsp;&nbsp;OBS回收后，通过千兆以太网回收数据，同时以太网电源 (PoE) 对OBS电池进行充电<br>·&nbsp;&nbsp;在声学熔断机构释放OBS后，卫星追踪系统通过邮件、短信或网页等方式报告OBS在水面上的位置，从而指导回收工作"
        }
        // 这里添加更多产品
      ]
    };
  }
};
</script>
<style lang="less" scoped>
@import "../../../assets/styles/GeoSciProduct.less";
</style>
<style scoped>
</style>
