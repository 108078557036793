<template>
  <div class="footer">
    <div class="valid-area" style="height: 620px;">
      <div class="footer-icon"><img src="@/assets/images/header/logo.svg" /></div>
      <div class="footer-content">
        <div class="footer-content-left">
          <div slot="content" class="micro-program">
            <div class="micro-item">
              <img src="@/assets/images/footer/gzh.jpg" />
              <p class="micro-title">三合智能公众号</p>
            </div>
            <div class="micro-item">
              <img src="@/assets/images/footer/tc.jpg" />
              <p class="micro-title">三合探测公众号</p>
            </div>
            <div class="micro-item">
              <img src="@/assets/images/footer/micro_reading.jpg" />
              <p class="micro-title">咔咔抄表小程序</p>
            </div>
            <!-- <div class="micro-item">
              <img src="@/assets/images/footer/micro_search.jpg" />
              <p class="micro-title">随羿查小程序</p>
            </div> -->
            <div class="micro-item">
              <img src="@/assets/images/footer/kh1.jpg" />
              <p class="micro-title">大客户经理</p>
            </div>
          </div>
          <ul class="footer-content-info">
            <li class="footer-content-info-item">
              <img src="@/assets/images/footer/email.png" />
              <p>邮箱：sanhe@lanwair.com</p>
            </li>
            <li>
              <img src="@/assets/images/footer/phone.png" />
              <p>电话：010-82860760</p>
            </li>
            <li>
              <img src="@/assets/images/footer/local.png" />
              <p>地址：北京市朝阳区望京街道望京SOHO-T1B座1801</p>
            </li>
          </ul>
        </div>
        <div class="footer-content-right">
          <div class="footer-item ">
            <ul>
              <li class="footer-title">公司介绍</li>
              <li>
                <router-link to="/company">公司简介</router-link>
              </li>
              <!-- <li>
                <router-link to="/algorithm">高管介绍</router-link>
              </li> -->
              <li>
                <router-link to="/company#culture">企业文化</router-link>
              </li>
              <li>
                <router-link to="/company#qualification-list">荣誉资质</router-link>
              </li>
            </ul>
          </div>
          <div class="footer-item ">
            <ul>
              <li class="footer-title">业务板块</li>
              <li>
                <router-link to="/IIot">智慧工业</router-link>
              </li>
              <li>
                <router-link to="/geoscience">地球科学</router-link>
              </li>
            </ul>
          </div>
          <div class="footer-item ">
            <ul>
              <li class="footer-title">产品中心</li>
              <li>
                <router-link to="/device">人工智能产品</router-link>
              </li>
              <li>
                <router-link to="/geoscience">地球科学仪器</router-link>
              </li>
            </ul>
          </div>
          <!-- <div class="footer-item ">
            <ul>
              <li class="footer-title">新闻动态</li>
              <li>
                <router-link to="/fuelGas">公司动态</router-link>
              </li>
              <li>
                <router-link to="/oilGas">行业动态</router-link>
              </li>
            </ul>
          </div> -->
          <!-- <div class="footer-item " style="width: 25%;">
            <ul>
              <li class="footer-title">行业案例</li>
              <li>
                <router-link to="/fuelGas">智慧工业案例</router-link>
              </li>
              <li>
                <router-link to="/oilGas">地球科学案例</router-link>
              </li>
            </ul>
          </div> -->
          <div class="footer-item " style="width: 25%;">
            <ul>
              <li class="footer-title">智慧工业案例</li>
              <li>
                <router-link to="/fuelGas">智慧燃气</router-link>
              </li>
              <li>
                <router-link to="/oilGas">智慧油气</router-link>
              </li>
              <li>
                <router-link to="/substations">智慧变电站</router-link>
              </li>
              <li>
                <router-link to="/water">智慧水务</router-link>
              </li>
              <li>
                <router-link to="/property">智慧物业</router-link>
              </li>
              <li>
                <router-link to="/factory">智慧工厂</router-link>
              </li>
            </ul>
          </div>
          <!-- <div class="footer-item ">
            <ul>
              <li class="footer-title">加入我们</li>
              <li>
                <router-link to="/fuelGas">招聘入口</router-link>
              </li>
            </ul>
          </div> -->
        </div>

        <!-- <div class="footer-item diff-item" v-if="widOver768">
          <ul>
            <li class="footer-title">联系我们</li>
            <li>邮箱：sanhe@lanwair.com</li>
            <li>电话：010-82860760</li>
            <li>地址：北京市朝阳区望京街道望京sohoT1B座1801</li>
          </ul>
        </div>
        <div class="footer-item" v-if="widOver768">
          <ul>
            <li class="footer-title">关注我们</li>
            <li>
              <img src="@/assets/images/footer/ercode.jpg" />
            </li>
          </ul>
        </div> -->
      </div>
      <!-- <div class="footer-content">
        <div class="footer-item diff-item" v-if="!widOver768">
          <ul>
            <li class="footer-title">联系我们</li>
            <li>邮箱：sanhe@lanwair.com</li>
            <li>电话：010-82860760</li>
            <li>地址：北京市朝阳区望京街道望京sohoT1B座1801</li>
          </ul>
        </div>
        <div class="footer-item" v-if="!widOver768">
          <ul>
            <li class="footer-title">关注我们</li>
            <li>
              <img src="@/assets/images/footer/ercode.jpg" />
            </li>
          </ul>
        </div>
      </div> -->
      <div class="crossline"></div>
      <div class="copyright">版权所有 © 2024 北京三合智能科技有限公司</div>
      <div class="beian">
        <a target="_blank" href="https://beian.mps.gov.cn/#/query/webSearch?code=11010502054953" rel="noreferrer">
          <img src="@/assets/images/footer/beian.png" />
          <span>京公网安备11010502054953</span>
        </a>
        <a target="_blank" href="http://beian.miit.gov.cn">京ICP备18017476号</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "tfooter",
  data() {
    return {
      widOver768: false
    };
  },
  methods: {
    getWid() {
      this.showMenu = false;
      let wid =
        document.documentElement.clientWidth || document.body.clientWidth;
      this.widOver768 = wid <= 768 ? false : true;
    }
  },
  mounted() {
    this.getWid();
    window.addEventListener("resize", this.getWid);
  }
};
</script>

<style lang="less" scoped>
.footer {
  width: 100%;
  height: 620px;
  min-width: 1140px;
  background: #292F43;

  .footer-icon {
    padding: 80px 0 0 0;
    display: flex;
    justify-content: space-between;
  }

  .footer-content {
    height: 420px;
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
  }

  .footer-content-left {
    width: 40%;
  }

  .footer-content-right {
    width: 50%;
    display: flex;
  }

  .footer-content-info {
    font-size: 16px;
    color: #fff;
    align-items: center;

    li {
      display: flex;
      margin: 20px 0;
    }

    img {
      margin-right: 10px;
      top: 4px;
    }
  }

  .footer-item {
    width: 20%;
    justify-content: space-between;

    li {
      margin: 20px 0;

      a {
        color: #c1c5d1;
        font-size: 16px;
        // color: #FFFFFF;
        // opacity: 0.7;
        font-weight: 400;
        font-family: PingFang SC, PingFang SC;

        &:hover {
          color: #3e86f7;
        }
      }
    }

    li.footer-title {
      font-size: 16px;
      color: #fff;
    }

    img {
      width: 120px;
    }

    @media screen and (max-width: 768px) {
      width: 28%;

      &:first-child {
        width: 44%;
      }

      li {
        margin: 0.2rem 0;
        color: #fff;
        font-size: 13px;

        a {
          color: #fff;
          font-size: 13px;
        }
      }

      li.footer-title {
        font-size: 12px;
        color: #c1c5d1;
      }
    }
  }

  .diff-item {
    width: 32%;
  }

  .copyright {
    padding: 10px 0;
    text-align: center;
    // border-top: 1px solid #495164;
    color: #fff;

    @media screen and (max-width: 768px) {
      padding: 0.2rem 0;
      // font-size: 0.14rem;
    }
  }
}

.beian {
  padding-bottom: 10px;
}

.beian a {
  margin-right: 10px;
  // color: #c1c5d1;
  color: #fff;
}

.beian a img {
  margin-right: 10px;
  position: relative;
  top: 4px;
}

.crossline {
  // width: 1920px;
  height: 1px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 49%, rgba(255, 255, 255, 0) 100%);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .footer {
    min-width: auto;

    .footer-content {
      padding: 0.3rem 0.4rem;
      justify-content: space-around;
    }
  }
}

.micro-program {
  // width: 420px;
  display: flex;
  justify-content: space-around;
  // padding: 15px;
  margin: 20px 0;

  .micro-item {
    width: 115px;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 100%;
    }

    .micro-title {
      margin: 10px 0 5px;
      color: #FFFFFF;
      font-size: 14px;
    }

    .micro-text {
      margin: 10px 0 5px;
      color: #485064;
      font-size: 14px;
    }

    .micro-description {
      color: #c1c5d1;
    }
  }
}
</style>
