<template>
  <div class="fullPage" ref="fullPage">
    <div class="fullPageContainer" ref="fullPageContainer" @mousewheel="mouseWheelHandle"
      @DOMMouseScroll="mouseWheelHandle" @touchstart="handleTouchstart" @touchend="handleTouchend"
      @touchmove="handleTouchmove">
      <!-- 首屏视频开始 -->
      <div class="section section1">
        <div id="index-banner">
          <!-- <video id="video" src="@/assets/images/videoHome_8M.mp4" loop autoplay muted>您的浏览器不支持视频播放，请更换浏览器</video> -->
          <video id="video" src="@/assets/images/videoHome_720.mp4" loop autoplay muted>您的浏览器不支持视频播放，请更换浏览器</video>
          
        </div>

        <!-- 左侧文字容器 -->
        <div class="video-text">
          <h1>用AI连接物理世界的数据</h1>
          <p>仪表感知 | 能耗监测 | 安全巡检 | 地球探测 | 灾害预警 | 环境监测</p>
        </div>
      </div>
      <!-- 首屏视频结束 -->

      <!-- 业务介绍开始 -->
      <div class="section section2">
        <div class="business-intro" :class="{ 'slide-in': fullpage.current === 2 }">
          <!-- 背景图片 -->
          <div class="background-image">
            <img src="@/assets/images/home/businessIntroduction/Group154.jpg" />
          </div>
          <!-- 业务介绍标题 -->
          <div class="intro-title">
            <h2>业务介绍</h2>
            <div class="blue-banner"></div>
          </div>
          <!-- 弧线左侧内容 -->
          <div class="left-container">
            <h2 class="curve-content-h2">智慧工业</h2>
            <p class="curve-content-p">专注水电气热及工业仪表感知及物联</p>
            <router-link class="curve-content-button" to="/IIot">View More ></router-link>
          </div>

          <!-- 弧线右侧内容 -->
          <div class="right-container">
            <!-- 五个icon及文案 -->
            <ul class="ul">
              <li class="li" style="left: -26px; top: -28px;">
                <i class="ico"
                  :style="{ 'background-image': `url(${require('@/assets/images/home/businessIntroduction/Frame1.svg')})` }"></i>
                <p>
                  <span>安全生产</span>
                </p>
              </li>

              <li class="li" style="left: 6px; top: 108px;">
                <i class="ico"
                  :style="{ 'background-image': `url(${require('@/assets/images/home/businessIntroduction/Frame2.svg')})` }"></i>
                <p>
                  <span>节能降碳</span>
                </p>
              </li>

              <li class="li" style="left: 22px; top: 244px;">
                <i class="ico"
                  :style="{ 'background-image': `url(${require('@/assets/images/home/businessIntroduction/Frame3.svg')})` }"></i>
                <p>
                  <span>经营监管</span>
                </p>
              </li>

              <li class="li" style="left: 6px; top: 380px;">
                <i class="ico"
                  :style="{ 'background-image': `url(${require('@/assets/images/home/businessIntroduction/Frame4.svg')})` }"></i>
                <p>
                  <span>提质增效</span>
                </p>
              </li>

              <li class="li" style="left: -26px; top: 516px;">
                <i class="ico"
                  :style="{ 'background-image': `url(${require('@/assets/images/home/businessIntroduction/Frame5.svg')})` }"></i>
                <p>
                  <span>数智化转型</span>
                </p>
              </li>
            </ul>
            <!-- Canvas元素用于绘制弧线 -->
            <!-- <canvas id="connectorCanvas" style="border: 1px solid red; wight:800px; height:600px"></canvas> -->
          </div>
        </div>
      </div>

      <div class="section section3">
        <div class="business-intro2" :class="{ 'slide-in2': fullpage.current === 3 }">
          <!-- 背景图片 -->
          <div class="background-image2">
            <img src="@/assets/images/home/businessIntroduction/Group155.jpg" />
          </div>
          <!-- 业务介绍标题 -->
          <div class="intro-title">
            <h2>业务介绍</h2>
            <div class="blue-banner"></div>
          </div>
          <!-- 弧线左侧内容 -->
          <div class="left-container2">
            <h2 class="curve-content-h2">地球科学</h2>
            <p class="curve-content-p">专注地球科学调查、地球深部构造探测</p>
            <router-link class="curve-content-button2" to="/Geoscience">View More ></router-link>
          </div>
          <!-- 弧线 -->
          <!-- <div class="curve">
           
            <img src="@/assets/images/home/businessIntroduction/MaskGroup.svg" alt="Curve" />
          </div>-->
          <!-- 弧线右侧内容 -->
          <div class="right-container2">
            <!-- 五个icon及文案 -->
            <ul class="ul">
              <li style="left: -26px; top: -28px;">
                <i class="ico"
                  :style="{ 'background-image': `url(${require('@/assets/images/home/businessIntroduction/EarthFrame1.svg')})` }"></i>
                <p>
                  <span>大深度地质雷达</span>
                </p>
              </li>

              <li style="left: 6px; top: 108px;">
                <i class="ico"
                  :style="{ 'background-image': `url(${require('@/assets/images/home/businessIntroduction/EarthFrame2.svg')})` }"></i>
                <p>
                  <span>监测地震</span>
                </p>
              </li>

              <li style="left: 22px; top: 244px;">
                <i class="ico"
                  :style="{ 'background-image': `url(${require('@/assets/images/home/businessIntroduction/EarthFrame3.svg')})` }"></i>
                <p>
                  <span>重磁勘探</span>
                </p>
              </li>

              <li style="left: 6px; top: 380px;">
                <i class="ico"
                  :style="{ 'background-image': `url(${require('@/assets/images/home/businessIntroduction/EarthFrame4.svg')})` }"></i>
                <p>
                  <span>电法勘探</span>
                </p>
              </li>

              <li style="left: -26px; top: 516px;">
                <i class="ico"
                  :style="{ 'background-image': `url(${require('@/assets/images/home/businessIntroduction/EarthFrame5.svg')})` }"></i>
                <p>
                  <span>工程地震</span>
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- 业务介绍结束 -->
      <!-- 热门产品 -->
      <div class="section section4" ref="section4">
        <!-- 业务介绍标题 -->
        <div class="intro-title">
            <h2>热门产品</h2>
            <div class="blue-banner"></div>
          </div>
        <div class="partners-container">
          <!-- 第一张图片 -->
          <div class="partner" @mouseover="expandPartner" @mouseout="shrinkPartner">
            <!-- <img class="normal" src="@/assets/images/home/popularProduct/AI.svg" alt="Partner 1" /> -->
            <img class="expanded" src="@/assets/images/home/popularProduct/AIs.jpg" alt="智能AI相机" />
            <div class="partner-info">
              <div class="row-1">
                <div class="partner-left">
                  <span class="jump-number1">1000</span>
                </div>
                <div class="partner-top">项目+</div>
              </div>
              <div class="row-2">
                <div class="partner-icon">
                  <img src="@/assets/images/home/popularProduct/Frame1.svg" alt="" />
                </div>
                <div class="partner-text">智能AI相机</div>
              </div>
            </div>
          </div>

          <!-- 第二张图片 -->
          <div class="partner" @mouseover="expandPartner" @mouseout="shrinkPartner">
            <!-- <img
              class="normal"
              src="@/assets/images/home/popularProduct/biaopan.svg"
              alt="Partner 2"
            />-->
            <img class="expanded" src="@/assets/images/home/popularProduct/biaopans.jpg" alt="图像识别算法" />

            <div class="partner-info">
              <div class="row-1">
                <div class="partner-left">
                  <span class="jump-number2">6</span>
                </div>
                <div class="partner-top">亿识别+</div>
              </div>
              <div class="row-2">
                <div class="partner-icon">
                  <img src="@/assets/images/home/popularProduct/Frame2.svg" alt="" />
                </div>
                <div class="partner-text">图像识别算法</div>
              </div>
            </div>
          </div>

          <!-- 第三张图片 -->
          <div class="partner" @mouseover="expandPartner" @mouseout="shrinkPartner">
            <!-- <img
              class="normal"
              src="@/assets/images/home/popularProduct/dubiao.svg"
              alt="Partner 3"
            />-->
            <img class="expanded" src="@/assets/images/home/popularProduct/dubiaos.jpg" alt="咔咔抄表SaaS" />

            <div class="partner-info">
              <div class="row-1">
                <div class="partner-left">
                  <span class="jump-number3">100</span>
                </div>
                <div class="partner-top">万档案+</div>
              </div>
              <div class="row-2">
                <div class="partner-icon">
                  <img src="@/assets/images/home/popularProduct/Frame3.svg" alt="" />
                </div>
                <div class="partner-text">咔咔抄表SaaS</div>
              </div>
            </div>
          </div>

          <!-- 第六张图片 -->
          <div class="partner" @mouseover="expandPartner" @mouseout="shrinkPartner">
            <!-- <img
              class="normal"
              src="@/assets/images/home/popularProduct/chuanganqi.svg"
              alt="Partner 4"
            />-->
            <img class="expanded" src="@/assets/images/home/popularProduct/chuanganqis.jpg" alt="光纤传感器" />

            <div class="partner-info">
              <div class="row-1">
                <div class="partner-left">
                  <span class="jump-number6">70</span>
                </div>
                <div class="partner-top">实验室+</div>
              </div>
              <div class="row-2">
                <div class="partner-icon">
                  <img src="@/assets/images/home/popularProduct/Frame4.svg" alt="" />
                </div>
                <div class="partner-text">光纤传感器</div>
              </div>
            </div>
          </div>

          <!-- 第五张图片 -->
          <div class="partner" @mouseover="expandPartner" @mouseout="shrinkPartner">
            <!-- <img
              class="normal"
              src="@/assets/images/home/popularProduct/dianfa.svg"
              alt="Partner 5"
            />-->
            <img class="expanded" src="@/assets/images/home/popularProduct/dianfas.jpg" alt="工程地震仪" />

            <div class="partner-info">
              <div class="row-1">
                <div class="partner-left">
                  <span class="jump-number5">50</span>
                </div>
                <div class="partner-top">场景+</div>
              </div>
              <div class="row-2">
                <div class="partner-icon">
                  <img src="@/assets/images/home/popularProduct/Frame5.svg" alt="" />
                </div>
                <div class="partner-text">工程地震仪</div>
              </div>
            </div>
          </div>

          
          <!-- 第四张图片 -->
          <div class="partner" @mouseover="expandPartner" @mouseout="shrinkPartner">
            <!-- <img
              class="normal"
              src="@/assets/images/home/popularProduct/dizhenyi.svg"
              alt="Partner 6"
            />-->
            <img class="expanded" src="@/assets/images/home/popularProduct/dizhenyis.jpg" alt="大深度地质雷达" />

            <div class="partner-info">
              <div class="row-1">
                <div class="partner-left">
                  <span class="jump-number4">200</span>
                </div>
                <div class="partner-top">单位+</div>
              </div>
              <div class="row-2">
                <div class="partner-icon">
                  <img src="@/assets/images/home/popularProduct/Frame6.svg" alt="" />
                </div>
                <div class="partner-text">大深度地质雷达</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 热门产品 -->
      <!-- 合作伙伴开始 -->
      <div class="section section5">
        <!-- 合作伙伴标题 -->
        <div class="intro-title">
          <h2>合作伙伴</h2>
          <div class="blue-banner"></div>
        </div>
        <div class="partner-image">
          <img src="@/assets/images/home/partner/huoban.jpg" />
        </div>
      </div>
      <!-- 合作伙伴结束 -->

      <!-- 信息录入开始 -->
      <div class="section section6">
        <div class="section-title">免费获取完整产品介绍资料</div>
        <div class="blue-bar"></div>
        <div class="input-container">
          <!-- 第一行文本框 -->
          <div class="input-row">
            <input type="text" placeholder="姓名/Full Name*" id="evaName" v-model="form.evaName" required />
            <input type="text" id="evaPhone" placeholder="电话/Phone Number*" v-model="form.evaPhone" required />
            <input type="text" id="evaEmail" placeholder="邮箱/Email*" v-model="form.evaEmail" />
          </div>
          <!-- 第二行文本框 -->
          <div class="input-row2">
            <input type="text" id="evaCompany" placeholder="公司名称/Company Name*" v-model="form.evaCompany" required />
            <input type="text" id="evaPosition" placeholder="职位/Current Position*" v-model="form.evaPosition" required />
            <input type="text" id="evaIndustry" placeholder="所属行业/Industry*" v-model="form.evaIndustry" required />
          </div>
          <!-- 大文本框 -->
          <textarea id="evaRequirements" placeholder="具体需求/Specific Needs*" rows="6" v-model="form.evaRequirements"
            required></textarea>
          <!-- 提交按钮 -->
          <button class="submit-button" @click="submitForm">提交信息</button>
        </div>
        <div class="contact-info">请填写上面表单或发送邮件至sanhe@lanwair.com，我们的工作人员将在24小时内与您取得联系。（所有关于您的个人信息我们都将保密，请放心填写）</div>
      </div>
      <!-- 信息录入结束 -->

      <!-- 首页单独的底部导航栏开始 -->
      <div class="section section7">
        <!-- 第六个部分的内容，例如导航栏 -->
        <t-footer></t-footer>
      </div>
    </div>
    <!-- 首页单独的底部导航栏结束 -->
  </div>
</template>
<script>
import TFooter from '@/components/layout/TFooter'
import axios from "axios";
export default {
  name: "Home",
  components: {
    TFooter,
  },
  data() {
    return {
      count1: 0,
      form: {
        evaName: "",
        evaPhone: "",
        evaEmail: "",
        evaCompany: "",
        evaPosition: "",
        evaIndustry: "",
        evaRequirements: ""
      },
      fullpage: {
        // 当前的页面编号
        current: 1,
        // 是否在滚动,是为了防止滚动多页，需要通过一个变量来控制是否滚动
        isScrolling: false,
        // 返回鼠标滚轮的垂直滚动量，保存的鼠标滚动事件的deleteY,用来判断是往下还是往上滚
        deltaY: 0
      },
      // 记录触摸开始的时间
      startTime: undefined,
      // 记录触摸开始的X坐标，本次主要实现的是上下滑动，所以当前坐标不做强制要求
      startX: undefined,
      // 记录触摸开始的Y坐标
      startY: undefined
    };
  },

  mounted() {
    this.getWid();
    // this.$nextTick(() => {
    //   this.drawConnector();
    // });
    // // 监听窗口大小变化
    // window.addEventListener("resize", this.drawConnector);
  },


  methods: {

    // drawConnector() {
    //   const canvas = document.getElementById("connectorCanvas");
    //   const ctx = canvas.getContext("2d");

    //   // 设置线条样式
    //   ctx.strokeStyle = "#ffffff"; // 设置线条颜色
    //   ctx.lineWidth = 1; // 设置线条宽度

    //   // 开始绘制路径
    //   ctx.beginPath();

    //   // 第一个点（左侧）
    //   const x1 = 100;
    //   const y1 = 100;
    //   ctx.moveTo(x1, y1);

    //   // 控制点1（左侧中部，稍微向上）
    //   const x2 = 150;
    //   const y2 = 50;

    //   // 控制点2（中间，稍微向下）
    //   const x3 = 250;
    //   const y3 = 150;

    //   // 控制点3（右侧中部，稍微向上）
    //   const x4 = 350;
    //   const y4 = 50;

    //   // 最后一个点（右侧）
    //   const x5 = 400;
    //   const y5 = 100;

    //   // 绘制竖直曲线
    //   ctx.bezierCurveTo(x2, y2, x3, y3, x4, y4);
    //   ctx.lineTo(x5, y5);

    //   // 绘制路径
    //   ctx.stroke();
    // },

    // drawConnector() {
    //   console.log("进来绘制了");
    //   const canvas = this.$refs.connectorCanvas;
    //   const ctx = canvas.getContext("2d");

    //   // 设置Canvas的宽度和高度为整个窗口
    //   canvas.width = window.innerWidth;
    //   canvas.height = window.innerHeight;

    //   // 通过获取图像元素的位置来确定弧线的起始和结束点
    //   const images = document.getElementsByClassName("li");
    //   const startPoint = this.getCenterPoint(images[0]);

    //   const endPoint = this.getCenterPoint(images[images.length - 1]);

    //   // 获取中间切图的中心点
    //   const midPoint0 = this.getCenterPoint(images[0]);
    //   const midPoint1 = this.getCenterPoint(images[1]);
    //   const midPoint2 = this.getCenterPoint(images[2]);
    //   const midPoint3 = this.getCenterPoint(images[3]);
    //   const midPoint4 = this.getCenterPoint(images[4]);
    //   // const midPoint5 = this.getCenterPoint(images[5]);

    //   // 绘制弧线
    //   ctx.clearRect(0, 0, canvas.width, canvas.height); // 清除之前的绘制
    //   ctx.beginPath();
    //   ctx.moveTo(startPoint.x, startPoint.y);
    //   ctx.quadraticCurveTo(midPoint0.x, midPoint0.y, midPoint1.x, midPoint1.y);
    //   ctx.quadraticCurveTo(midPoint1.x, midPoint1.y, midPoint2.x, midPoint2.y);
    //   ctx.quadraticCurveTo(midPoint2.x, midPoint2.y, midPoint3.x, midPoint3.y);
    //   ctx.quadraticCurveTo(midPoint3.x, midPoint3.y, midPoint4.x, midPoint4.y);
    //   //ctx.quadraticCurveTo(midPoint4.x, midPoint4.y, midPoint5.x, midPoint5.y);
    //   ctx.strokeStyle = "red"; // 设置弧线颜色
    //   ctx.lineWidth = 2; // 设置弧线宽度
    //   ctx.stroke();
    // },

    // // 获取图像元素的中心点
    // getCenterPoint(image) {
    //   const rect = image.getBoundingClientRect();
    //   return {
    //     x: rect.left + rect.width / 2,
    //     y: rect.top + rect.height / 2
    //   };
    // },

    validatePhone(phone) {
      // 手机号码和座机号码正则表达式
      const phoneReg = /^1[3-9]\d{9}$|^([0-9]{3,4}-)?[0-9]{7,8}$/;
      return phoneReg.test(phone);
    },
    isValidEmail(email) {
      const emailRegex = /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/;
      return emailRegex.test(email);
    },

    submitForm() {
      // 判断姓名和电话是否为空
      if (!this.form.evaName) {
        alert("请输入您的姓名");
        return;
      }
      if (!this.form.evaPhone) {
        alert("请输入您的电话");
        return;
      }
      if (!this.form.evaEmail) {
        alert("请输入您的邮箱");
        return;
      }
      if (!this.isValidEmail(this.form.evaEmail)) {
        alert("邮箱格式有误");
        return;
      }
      if (!this.validatePhone(this.form.evaPhone)) {
        alert("手机号码格式有误");
        return;
      }
      if (!this.form.evaCompany) {
        alert("请输入公司名称");
        return;
      }
      if (!this.form.evaPosition) {
        alert("请输入职位");
        return;
      }
      if (!this.form.evaIndustry) {
        alert("请输入行业");
        return;
      }
      if (!this.form.evaRequirements) {
        alert("请准确输入您的信息以便我们及时与您取得联系");
        return;
      }
      // 将表单数据发送到后端接口
      const requestBody = {
        sid: "123",
        pwd: "85eea08bf23348419cecd44b2b417b85",
        accessToken:
          "f5d62b06b85d40e2b34edaa1560e755bb6c85dd41b428c1ef2ccc45ab1980a53",
        msg: `【姓名】${this.form.evaName}\n【电话】${this.form.evaPhone}\n【邮箱】${this.form.evaEmail}\n【公司】${this.form.evaCompany}\n【职位】${this.form.evaPosition}\n【行业】${this.form.evaIndustry}\n【需求】${this.form.evaRequirements}`
      };

      axios.post("https://gw.evavision.cn/plat/dingrobot", requestBody, {
        headers: {
          webver: "v100.0.0"
        }
      });
      alert("提交成功");
      // 清空表单信息
      this.form.evaName = "";
      this.form.evaPhone = "";
      this.form.evaEmail = "";
      this.form.evaCompany = "";
      this.form.evaPosition = "";
      this.form.evaIndustry = "";
      this.form.evaRequirements = "";
    },

    expandPartner() {
      // 在鼠标移入时执行的代码
      // 可以用来修改元素样式或执行其他操作
    },
    shrinkPartner() {
      // 在鼠标移出时执行的代码
      // 可以用来还原元素样式或执行其他操作
    },

    getWid() {
      this.showMenu = false;
      let wid =
        document.documentElement.clientWidth || document.body.clientWidth;
      this.widOver768 = wid <= 768 ? false : true;
    },

    // 往下切换
    next() {
      // 页面的个数
      let len = 7;
      if (this.fullpage.current + 1 <= len) {
        // 如果当前页面编号+1 小于总个数，则可以执行向下滑动
        this.fullpage.current += 1;
        // 执行切换
        this.move(this.fullpage.current);
      }
    },
    // 往上切换
    pre() {
      if (this.fullpage.current - 1 > 0) {
        // 如果当前页面编号-1 大于0，则可以执行向下滑动
        this.fullpage.current -= 1;
        // 执行切换
        this.move(this.fullpage.current);
      }
    },



    // 滚动事件
    move(index) {
      // 为了防止滚动多页，需要通过一个变量来控制是否滚动
      this.fullpage.isScrolling = true;
      //执行滚动
      this.directToMove(index);
      setTimeout(() => {
        //这里的动画是1s执行完，使用setTimeout延迟1s后解锁
        this.fullpage.isScrolling = false;
      }, 1000);

      if (this.fullpage.current === 4) {

        // 调用跳动数字方法，每个图片的数字不同
        this.jumpNumbersForImage('.jump-number1', 1000,4); // 第一个图片的数字是1000
        this.jumpNumbersForImage('.jump-number2', 6,20);    // 第二个图片的数字是6
        this.jumpNumbersForImage('.jump-number3', 100,20);   // 第三个图片的数字是100
        this.jumpNumbersForImage('.jump-number4', 200,20);   // 第四个图片的数字是200
        this.jumpNumbersForImage('.jump-number5', 50,20);    // 第五个图片的数字是50
        this.jumpNumbersForImage('.jump-number6', 70,20);    // 第六个图片的数字是70
      }
    },

    // 从0到目标数字跳动的方法
    jumpNumbersForImage(selector, targetNumber,time) {
      let currentNumber = 0;
      const jumpInterval = setInterval(() => {
        // 更新数字显示
        const numberElement = document.querySelector(selector);
        if(numberElement!=null&&numberElement!=''){
        numberElement.innerText = currentNumber;
          // 到达目标数字时停止跳动
        if (currentNumber === targetNumber) {
          clearInterval(jumpInterval);
        } else {
          // 增加数字以进行下一次跳动
          currentNumber++;
        }
        }
      }, time); // 每10毫秒更新一次数字，可根据需要调整
    },


    // 执行滚动
    directToMove(index) {
      //获取屏幕的宽度
      let height = this.$refs["fullPage"].clientHeight;
      // 获取执行tarnsform的元素
      let scrollPage = this.$refs["fullPageContainer"];
      // 计算滚动的告诉，是往上滚还往下滚
      let scrollHeight;
      if (index == 7) {
        scrollHeight = -((index - 2) * height + 620) + "px";
      } else {
        scrollHeight = -(index - 1) * height + "px";
      }

      scrollPage.style.transform = `translateY(${scrollHeight})`;
      this.fullpage.current = index;
    },
    // 监听鼠标监听
    mouseWheelHandle(event) {
      // 添加冒泡阻止
      let evt = event || window.event;
      if (evt.stopPropagation) {
        evt.stopPropagation();
      } else {
        evt.returnValue = false;
      }
      if (this.fullpage.isScrolling) {
        // 判断是否可以滚动
        return false;
      }
      let e = event.originalEvent || event;
      // Firefox使用detail
      this.fullpage.deltaY = e.deltaY || e.detail;
      if (this.fullpage.deltaY > 0) {
        this.next();
      } else if (this.fullpage.deltaY < 0) {
        this.pre();
      }
    },

    // 清除触摸事件
    handleTouchmove(event) {
      event.preventDefault();
    },
    //手指按下屏幕
    handleTouchstart(event) {
      this.startTime = Date.now();
      this.startX = event.changedTouches[0].clientX;
      this.startY = event.changedTouches[0].clientY;
    },
    //手指离开屏幕
    handleTouchend(event) {
      const endTime = Date.now();
      const endX = event.changedTouches[0].clientX;
      const endY = event.changedTouches[0].clientY;
      //判断按下的时长
      if (endTime - this.startTime > 2000) {
        return;
      }
      //滑动的方向
      let direction = "";
      //先判断用户滑动的距离，是否合法，合法:判断滑动的方向 注意 距离要加上绝对值
      if (Math.abs(endY - this.startY) > 10) {
        //滑动方向
        direction = endY - this.startY > 0 ? "down" : "up";
      } else {
        return;
      }
      //用户做了合法的滑动操作
      // console.log('方向'+direction)
      if (direction === "up") {
        this.next();
      }
      if (direction === "down") {
        this.pre();
      }
    }
  }
};
</script>



<style lang="less" scoped>
@import "../../assets/styles/home.less";
</style>
<style scoped lang="scss">
// 总div样式
.fullPage {
  //一定要设置，多余的先隐藏
  overflow: hidden;
}

.fullPageContainer {
  width: 100%;
  height: 100vh; // 确保父容器至少占满一屏的高度
  transition: all linear 0.5s;
}

// 5屏基础样式
.section1 {
  width: 100%;
  height: 100vh;
}

.section2 {
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
  /* 添加这一行来隐藏容器内超出部分 */
}

.section3 {
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
  /* 添加这一行来隐藏容器内超出部分 */
}

.section4 {
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
  /* 添加这一行来隐藏容器内超出部分 */
  background-color: #292f43;
}

.section5 {
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
  /* 添加这一行来隐藏容器内超出部分 */
  width: 100%;
  height: 100vh;
}

.section6 {
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
  /* 添加这一行来隐藏容器内超出部分 */
  background-color: #292f43;
  width: 100%;
  height: 100vh;
}

.section7 {
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
  /* 添加这一行来隐藏容器内超出部分 */
  // height: 70%;
  align-self: flex-start;
}

.dot-container {
  position: fixed;
  left: 20px;
  /* 调整左侧位置 */
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dot {
  width: 10px;
  height: 10px;
  background-color: red;
  /* 未选中状态的颜色 */
  border-radius: 50%;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dot.active {
  background-color: #0087ff;
  /* 高亮状态的颜色 */
}

// 业务介绍样式开始*----------------------------------------------------------------------------------------------------------------
.section2 .business-intro {
  height: 100vh;
  overflow: hidden;
  top: 0px;
  left: 0px;
}

.background-image img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  background-position: center center;
  animation: zoomIn 5s infinite alternate;
  /* 调整动画持续时间和重复方式 */
}

.left-container {
  position: absolute;
  top: 250px;
  left: 200px;
  transform: translate(-50%, -50%);
  color: #ffffff;
  /* 文字颜色 */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  /* 文字阴影 */
  /* 其他样式... */
}

.intro-title {
  position: absolute;
  top: 13%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 1;
  /* 确保标题在背景图片之上 */
}

.intro-title h2 {
  font-size: 32px;
  color: #fff;
  margin-bottom: 10px;
}

.blue-banner {
  // width: 40px;
  width: 60px;
  height: 3px;
  background-image: linear-gradient(90deg, #3e86f7, #24d5ff);
  margin: 0 auto;
}

.left-container h2 {
  text-align: left;
  /* 文字左对齐 */
  font-size: 52px;
  font-weight: 600;
  line-height: 60.94px;
  color: #ffffff;
  margin-left: 367px; // 这里设置了左侧距离
  margin-top: 395px; // 这里设置了顶部距离
}

.left-container p {
  text-align: left;
  /* 文字左对齐 */
  font-size: 26px;
  font-weight: 500;
  line-height: 39px;
  color: #ffffff;
  margin-left: 367px; // 这里设置了左侧距离
  margin-top: 26px; // 这里设置了顶部距离
}

.curve-content-button {
  display: inline-block;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  color: #ffffff;
  text-decoration: none;
  border: 2px solid #ffffff;
  padding: 10px 20px;
  margin-top: 70px;
  transition: background-color 0.3s, color 0.3s;
  margin-left: 123px; // 这里设置了左侧距离
}

.right-container {
  position: absolute;
  top: 215px;
  left: 725px;
  z-index: 999;
}

.ul {
  position: absolute;
  top: 0;
  width: 100%;
}

.ul li {
  position: absolute;
  padding-left: 46px;
  width: 300px;
  background: url("../../assets/images/home/businessIntroduction/ico_circle.png") no-repeat left center;
  
  &:hover {
      transform: scale(1.3);
    }

  span {
    transition: transform 0.3s ease;
    &:hover {
      transform: scale(1.3);
    }
  }
}

.huxian {
  z-index: 99999;
}

.ul li p {
  display: inline-block;
  vertical-align: middle;
  line-height: 18px;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  display: inline-block;
}

.ul li .ico {
  display: inline-block;
  margin-right: 28px;
  width: 40px;
  height: 40px;
  background-position: center center;
  background-repeat: no-repeat;
  vertical-align: middle;

}

.ico {
  transition: transform 0.3s ease;
  /* 添加这一行，设置 transform 属性的动画效果 */
}

.ico:hover {
  transform: scale(1.3);
  /* 鼠标悬停时图标逐渐放大到 1.3 倍大小 */
}

.business-intro.slide-in {

  .left-container h2,
  .left-container p,
  .right-container,
  .curve-content-button,
  .ul li {
    animation: slideIn 1s forwards ease-out;
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

.business-intro:not(.slide-in) .left-container h2,
.business-intro:not(.slide-in) .left-container p,
.business-intro:not(.slide-in) .right-container,
.business-intro:not(.slide-in) .curve-content-button,
.business-intro:not(.slide-in) .ul li {
  transform: translateX(0);
}

@keyframes zoomIn {
  100% {
    transform: scale(1.1);
    /* 放大到 1.1 倍 */
  }
}

// 业务介绍 - 地球科学样式开始----------------------------------------------------------------------------------------------------------
.section3 .business-intro2 {
  height: 100vh;
  overflow: hidden;
  top: 0px;
  left: 0px;
}

.background-image2 img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  background-position: center center;
  animation: zoomIn 5s infinite alternate;
  /* 调整动画持续时间和重复方式 */
}

.left-container2 {
  position: absolute;
  top: 250px;
  left: 200px;
  transform: translate(-50%, -50%);
  color: #ffffff;
  /* 文字颜色 */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  /* 文字阴影 */
}

.left-container2 h2 {
  text-align: left;
  /* 文字左对齐 */
  font-size: 52px;
  font-weight: 600;
  line-height: 60.94px;
  color: #ffffff;
  margin-left: 367px; // 这里设置了左侧距离
  margin-top: 395px; // 这里设置了顶部距离
}

.left-container2 p {
  text-align: left;
  /* 文字左对齐 */
  font-size: 26px;
  font-weight: 500;
  line-height: 39px;
  color: #ffffff;
  margin-left: 367px; // 这里设置了左侧距离
  margin-top: 26px; // 这里设置了顶部距离
}

.curve-content-button2 {
  display: inline-block;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  color: #ffffff;
  text-decoration: none;
  border: 2px solid #ffffff;
  padding: 10px 20px;
  margin-top: 70px;
  transition: background-color 0.3s, color 0.3s;
  margin-left: 97px; // 这里设置了左侧距离
}

.right-container2 {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 215px;
  left: 725px;
  z-index: 999;
}

.ul {
  position: absolute;
  top: 0;
  width: 100%;
}

// .ul li {
//   position: absolute;
//   padding-left: 46px;
//   width: 300px;
// }

.ul li p {
  display: inline-block;
  vertical-align: middle;
  // line-height: 18px;
  // font-size: 18px;
  line-height: 20px;
  font-size: 20px;
  font-weight: 500;
  color: #ffffff;
}

.ul li .ico {
  display: inline-block;
  margin-right: 28px;
  width: 40px;
  height: 40px;
  background-position: center center;
  background-repeat: no-repeat;
  vertical-align: middle;
}

.ico {
  transition: transform 0.3s ease;
  /* 添加这一行，设置 transform 属性的动画效果 */
}

.ico:hover {
  transform: scale(1.3);
  /* 鼠标悬停时图标逐渐放大到 1.3 倍大小 */
}

.business-intro2.slide-in2 {

  .left-container2 h2,
  .left-container2 p,
  .right-container2,
  .curve-content-button2,
  .ul li {
    animation: slideIn 1s forwards ease-out;
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

.business-intro2:not(.slide-in2) .left-container2 h2,
.business-intro2:not(.slide-in2) .left-container2 p,
.business-intro2:not(.slide-in2) .right-container2,
.business-intro2:not(.slide-in2) .curve-content-button2,
.business-intro2:not(.slide-in2) .ul li {
  transform: translateX(0);
  /* 添加这个额外的关键帧，确保在非滑入状态下元素位置正确 */
}

// 热门产品样式----------------------------------------------------------------------------------------------------------------
.partners-container {
  display: flex;
  justify-content: space-between;
  height: 100vh;
}

.partner {
  position: relative;
  width: 16.666%;
  // 隐藏溢出的内容，以确保图片不会超出 .partner 元素的边界。
  overflow: hidden;
  // 将鼠标指针设置为手型，表示该元素是可点击的
  cursor: pointer;
  // 添加过渡效果，当元素的宽度或变换属性发生变化时，以 0.3 秒的时间进行平滑过渡。
  transition: width 0.8s, transform 0.8s ease;
  opacity: 0.5;
}

// .partner img.expanded.normal {
//   width: 100%; /* 让展开后的图片占满 .partner 容器 */
//   height: 100%;
//   display: block;
// } women xiang wigyh 100$ omb absolulte cober zhinengaixiangji  absolute 150px wi en

.expanded {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* 保持图片比例，填充整个区域，可能裁剪部分图片 */
  /* 其他样式... */
}

.partner-info {
  display: flex;
  position: absolute;
  bottom: 150px;
  transition: transform 0.8s ease;
  transform: translateY(50%);
  margin-left: 20px;
  flex-direction: column;
  /* 垂直方向上排列子元素 */
}

.row-1 {
  display: flex;
  justify-content: space-between;
  /* 第一行和第二行的子元素之间平均分布空间 */
  margin-bottom: 10px;
}

.row-2 {
  display: flex;
  align-items: center;
  /* 使第二行内容垂直居中对齐 */
}

.partner-left {
  font-size: 60px;
  /* 适当调整文字大小 */
  color: #fff;
  /* 适当调整文字颜色 */
  width: 140px;
}

.partner-top {
  // font-size: 16px;
  font-size: 20px;
  /* 适当调整文字大小 */
  color: #fff;
  /* 适当调整文字颜色 */
  align-self: flex-end;
  /* 使项目名称在底部对齐 */
  margin-bottom: 15px;
  margin-left: 15px;
}

.partner-text {
  // font-size: 16px;
  font-size: 20px;
  /* 适当调整文字大小 */
  color: #fff;
  /* 适当调整文字颜色 */
  margin-left: 10px;
  margin-bottom: 5px;
}

.partner:hover {
  width: 33.333%;
  opacity: 1;
}

.partner:hover img.expanded {
  display: block;
  transform: scale(1.2);
  /* 鼠标悬停时放大1.2倍 */
  transition: transform 0.6s ease;
  
}

.partner:hover .partner-info {
  transform: translateY(-120%);
  

  .partner-left{
    font-size: 80px;
  }
}

// 合作伙伴样式 -------------------------------------------------------------------------------------------------------------------------------------
.partner-image img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

// 信息录入样式---------------------------------------------------------------------------------------------------------------------

.section-title {
  font-size: 32px;
  margin-top: 110px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 56px;
  text-align: center;
  font-style: normal;
  text-transform: none;
}

.blue-bar {
  width: 100px;
  height: 5px;
  background-color: #0087ff;
  margin: 0 auto;
  margin-top: 20px;
  text-align: center;
}

.input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 60px;
  color: #ffffff;
}

.input-row2 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

input {
  margin-right: 50px;
  width: 410px;
  height: 68px;
  border-radius: 10px 10px 10px 10px;
  border: 1px solid #ffffff;
  padding: 20px;
  margin-bottom: 20px;
  background-color: #292f43;
  color: #fff;
}

textarea {
  margin-right: 50px;
  width: 1330px;
  height: 200px;
  border-radius: 10px 10px 10px 10px;
  border: 1px solid #ffffff;
  padding: 20px;
  margin-bottom: 20px;
  background-color: #292f43;
  color: #fff;
}

input::placeholder,
textarea::placeholder {
  color: #fff;
  /* 设置提示文字颜色为白色 */
}

.submit-button {
  font-size: 26px;
  color: #fff;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  width: 410px;
  height: 82px;
  background: #0087ff;
  border-radius: 107px 107px 107px 107px;
}

.contact-info {
  margin-top: 30px;
  font-size: 16px;
  color: #fff;
}

</style>