<template>
  <!-- 强震动加速度计 -->
  <div>
    <div v-for="(product, index) in products" :key="index">
      <div v-if="product.id === productId" class="product-details">
        <div class="product-top">
          <h2>{{ product.name }}</h2>
          <p class="product-description">{{ product.description }}</p>
        </div>
        <div class="carousel-container" v-if="product.carouselImage">
          <img :src="product.carouselImage" style="width: 400px;"/>
        </div>
        <div class="carousel-container" v-else>
        <!-- 轮播图展示区域 -->
          <el-carousel :interval="3000" autoplay style="width: 400px;">
            <!-- 轮播图内容 -->
            <el-carousel-item v-for="(item, index) in product.carouselItems" :key="index">
              <img :src="item.src" alt="轮播图" />
            </el-carousel-item>
          </el-carousel>
      </div>
      </div>
      <div v-if="product.id === productId" class="parameter-vbom">
        <div class="parameter-title">
          <h3>产品参数</h3>
          <div class="separator"></div>
        </div>
        <p v-if="product.galleryText" class="product-description">{{ product.galleryText }}</p>
        <div v-if="product.galleryImage" class="image-gallery">
          <img :src="product.galleryImage" />
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
export default {
props: ["productId"],
  data() {
    return {
      products: [
        {
          id: "tc_10007_0001",
          name: "Güralp FORTIS 强震动加速度计",
          description: "Güralp公司研制的 Minimus(4通道)和 Minimus+(8通道)的智能化地震数字采集器，拥有大量独特优点，为快速部署和多尺度网络阵列提供即插即用的理想解决方案。",
          carouselImage: null,
          carouselItems:[
            { src: require("@/assets/images/ESci/StrongMotionAccelerometer/p3c1.jpg") },
            { src: require("@/assets/images/ESci/StrongMotionAccelerometer/p3c2.jpg") },
            { src: require("@/assets/images/ESci/StrongMotionAccelerometer/p3c3.png") },
            { src: require("@/assets/images/ESci/StrongMotionAccelerometer/p3c4.jpg") },
          ],
          galleryImage: require("@/assets/images/ESci/StrongMotionAccelerometer/p3.png")
        },
        
      ]
    };
  }
};
</script>
<style lang="less" scoped>
@import "../../../assets/styles/GeoSciProduct.less";
</style>
<style scoped>
</style>
