import Vue from 'vue'
import App from './App.vue'
import iView from 'iview'
import router from './router'
import 'iview/dist/styles/iview.css'
import './assets/styles/base.less'
// 视频插件
import VideoPlayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

document.addEventListener("DOMContentLoaded", function() {
  // 显示预加载容器
  const preloadContainer = document.getElementById("preload-container");
  preloadContainer.style.display = "block";

  // 模拟延迟，例如 5 秒后隐藏预加载容器
  setTimeout(() => {
    preloadContainer.style.display = "none";
    // 显示官网首页容器
    document.getElementById("homepage-container").style.display = "block";
  }, 3800); // 假设延迟 3.8 秒

  // 每隔 1 秒检查一次是否播放完毕
  const checkPreloadInterval = setInterval(() => {
    const preloadPlayed = sessionStorage.getItem("preloadPlayed");
    if (preloadPlayed === "true") {
      clearInterval(checkPreloadInterval); // 停止检查
    }
  }, 500); // 每隔 1 秒检查一次
});

Vue.use(iView)
Vue.use(VideoPlayer)
Vue.use(ElementUI)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
  // 添加mounted，不然不会执行预编译
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
