var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"factory-wrap"},[_c('div',{staticClass:"page-banner",style:({background: '#1D212E url('+require('@/assets/images/factory/banner.jpg')+') no-repeat', backgroundSize: 'cover'})},[_c('div',{staticClass:"banner-mask"}),_c('div',{staticClass:"valid-area"},[_c('div',{staticClass:"align-center"},[_c('p',{staticClass:"page-banner-title"},[_vm._v("智慧工厂解决方案")]),(_vm.widOver768)?_c('p',{staticClass:"page-banner-description"},[_vm._v("解决实时监控难题，发现异常后，实时推送告警，")]):_vm._e(),(_vm.widOver768)?_c('p',{staticClass:"page-banner-description"},[_vm._v("提升安全管理水平")]):_vm._e(),(!_vm.widOver768)?_c('p',{staticClass:"page-banner-description"},[_vm._v("解决实时监控难题，发现异常后，")]):_vm._e(),(!_vm.widOver768)?_c('p',{staticClass:"page-banner-description"},[_vm._v("实时推送告警，提升安全管理水平")]):_vm._e()])])]),_c('div',{staticClass:"part part-light-bg letter-space"},[_c('div',{staticClass:"valid-area"},[_c('p',{staticClass:"part-title"},[_vm._v("智慧工厂")]),_c('p',{staticClass:"decoration-line"}),(_vm.widOver768)?_c('div',{staticClass:"main-content layout-flex reslove-case-main"},[_vm._m(0),_vm._m(1),_vm._m(2)]):_vm._e(),(!_vm.widOver768)?_c('div',{staticClass:"main-content reslove-case-main-768"},[_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)]):_vm._e()])]),_vm._m(7),_c('div',{staticClass:"part letter-space",style:({background:'#2B2E46 url('+require('@/assets/images/home/advantage-bg.png')+') no-repeat 90px 90px / 90% 90%'})},[_vm._m(8)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resolve-case align-right"},[_c('div',{staticClass:"resolve-case-item"},[_c('p',{staticClass:"resolve-case-title"},[_vm._v("统一")]),_c('p',{staticClass:"resolve-case-description"},[_vm._v("统一通信协议，通过图像数字化实现各类仪器仪表数据的综合采集")])]),_c('div',{staticClass:"resolve-case-item"},[_c('p',{staticClass:"resolve-case-title"},[_vm._v("安全")]),_c('p',{staticClass:"resolve-case-description"},[_vm._v("根据用能趋势分析生产加工等设备的运行健康程度，及时定位安全问题，实现预测性维护")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resolve-case-pic"},[_c('img',{attrs:{"src":require("@/assets/images/factory/factory.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resolve-case align-left"},[_c('div',{staticClass:"resolve-case-item",staticStyle:{"margin-top":"364px"}},[_c('p',{staticClass:"resolve-case-title"},[_vm._v("高效")]),_c('p',{staticClass:"resolve-case-description"},[_vm._v("解决人工巡检效率低不可靠的问题，避免因意外停机导致的生产延误、良品率低下等问题")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"reslove-img"},[_c('img',{attrs:{"src":require("@/assets/images/factory/factory-res.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resolve-case-768-item"},[_c('p',{staticClass:"resolve-case-768-title"},[_vm._v("统一")]),_c('p',{staticClass:"resolve-case-768-description"},[_vm._v("统一通信协议，通过图像数字化")]),_c('p',{staticClass:"resolve-case-768-description"},[_vm._v("实现各类仪器仪表数据的综合采集")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resolve-case-768-item"},[_c('p',{staticClass:"resolve-case-768-title"},[_vm._v("安全")]),_c('p',{staticClass:"resolve-case-768-description"},[_vm._v("根据用能趋势分析生产加工等设备的运行")]),_c('p',{staticClass:"resolve-case-768-description"},[_vm._v("健康程度，及时定位安全问题，实现预测性维护")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resolve-case-768-item"},[_c('p',{staticClass:"resolve-case-768-title"},[_vm._v("高效")]),_c('p',{staticClass:"resolve-case-768-description"},[_vm._v("解决人工巡检效率低不可靠的问题，避免因")]),_c('p',{staticClass:"resolve-case-768-description"},[_vm._v("意外停机导致的生产延误、良品率低下等问题")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"part part-gray-bg letter-space"},[_c('div',{staticClass:"valid-area"},[_c('p',{staticClass:"part-title"},[_vm._v("应用案例")]),_c('p',{staticClass:"decoration-line"}),_c('div',{staticClass:"main-content"},[_c('div',{staticClass:"case"},[_c('div',{staticClass:"case-pic"},[_c('img',{attrs:{"src":require("@/assets/images/factory/case-img.png")}})]),_c('div',{staticClass:"case-info"},[_c('p',[_c('span',[_vm._v("客户  —  ")]),_vm._v("河北某工厂")]),_c('p',[_c('span',[_vm._v("时间  —  ")]),_vm._v("2019年4月")]),_c('p',[_c('span',[_vm._v("表具  —  ")]),_vm._v("压力表、流量计、电表、水表")]),_c('p',[_c('span',[_vm._v("效果  —  ")]),_vm._v("实现高频拍照监控，示数超限后，在平台实时声光报警，在地图中动态显示异常仪表坐标位置。替代人工2小时巡检一次，及时采取安全措施")])])]),_c('div',{staticClass:"case-app"},[_c('div',{staticClass:"case-app-box"},[_c('div',{staticClass:"case-data"},[_c('p',{staticStyle:{"width":"40%"}},[_vm._v("（仪表数据）")]),_c('p',{staticStyle:{"width":"40%"}},[_vm._v("（仪表超限告警）")]),_c('p',{staticStyle:{"width":"33%"}},[_vm._v("（仪表告警阈值设置）")])]),_c('img',{staticClass:"case-data-pic",attrs:{"src":require("@/assets/images/factory/case.png")}})])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"valid-area"},[_c('p',{staticClass:"part-title dark-bg-text-white"},[_vm._v("安装照片")]),_c('p',{staticClass:"decoration-line"}),_c('div',{staticClass:"main-content"},[_c('img',{staticStyle:{"width":"100%","display":"block","margin":"0 auto"},attrs:{"src":require("@/assets/images/factory/install.png")}})])])
}]

export { render, staticRenderFns }