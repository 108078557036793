import Vue from 'vue'
import Router from 'vue-router'
import routes from './routes'
// import VueAwesomeSwiper from 'vue-awesome-swiper'

import 'swiper/swiper-bundle.css'

Vue.use(Router)
// Vue.use(VueAwesomeSwiper)

const originalPush = Router.prototype.push
// Router.prototype.push = function push(location){
//   return originalPush.call(this,location).catch(err=>err)
// }
Router.prototype.push = function push(location) {
  const result = originalPush.call(this, location);
  // 如果 result 是 Promise，则添加错误处理  
  if (result instanceof Promise) {
    return result.catch(err => err);
  }
  // 如果 result 不是 Promise，则直接返回 result  
  return result;
};

const router = new Router({
  // scrollBehavior: () => ({ y: 0 }),
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      const elementId = to.hash.substring(1);
      const targetElement = document.getElementById(elementId);
      // console.log('targetElement',targetElement)
      if (targetElement) {
        return { selector: `#${elementId}` };
      }
    } else {
      return { x: 0, y: 0 };
    }
  },
  mode: 'history',
  routes
})
router.beforeEach((to, from, next) => {
  const currentFullUrl = window.location.href;
  const domainWithProtocol = window.location.origin;

  const urlWithoutDomain = currentFullUrl.replace(domainWithProtocol, '');

  // console.log(urlWithoutDomain);
  console.log(to);

  const matched = router.getMatchedComponents(to);
  // console.log('matched', matched);

  if (!matched[0]) {
    window.location.href = 'http://lanwair.evavision.cn/' + urlWithoutDomain;
  } else {
    next();
  }
});
export default router
