<template>
  <div class="factory-wrap">
    <!-- banner 开始 -->
    <div class="page-banner" :style="{background: '#1D212E url('+require('@/assets/images/factory/banner.jpg')+') no-repeat', backgroundSize: 'cover'}">
      <div class="banner-mask"></div>
      <div class="valid-area">
        <div class="align-center">
          <p class="page-banner-title">智慧工厂解决方案</p>
          <p class="page-banner-description" v-if="widOver768">解决实时监控难题，发现异常后，实时推送告警，</p>
          <p class="page-banner-description" v-if="widOver768">提升安全管理水平</p>
          <p class="page-banner-description" v-if="!widOver768">解决实时监控难题，发现异常后，</p>
          <p class="page-banner-description" v-if="!widOver768">实时推送告警，提升安全管理水平</p>
        </div>
      </div>
    </div>
    <!-- banner 结束 -->
    <!-- 智慧水务 开始 -->
    <div class="part part-light-bg letter-space">
      <div class="valid-area">
        <!-- 标题 -->
        <p class="part-title">智慧工厂</p>
        <!-- 装饰线 -->
        <p class="decoration-line"></p>
        <!-- 主内容区 -->
        <div class="main-content layout-flex reslove-case-main" v-if="widOver768">
          <div class="resolve-case align-right">
            <div class="resolve-case-item">
              <p class="resolve-case-title">统一</p>
              <p class="resolve-case-description">统一通信协议，通过图像数字化实现各类仪器仪表数据的综合采集</p>
            </div>
            <div class="resolve-case-item">
              <p class="resolve-case-title">安全</p>
              <p class="resolve-case-description">根据用能趋势分析生产加工等设备的运行健康程度，及时定位安全问题，实现预测性维护</p>
            </div>
          </div>
          <div class="resolve-case-pic">
            <img src="@/assets/images/factory/factory.png">
          </div>
          <div class="resolve-case align-left">
            <div class="resolve-case-item" style="margin-top:364px;">
              <p class="resolve-case-title">高效</p>
              <p class="resolve-case-description">解决人工巡检效率低不可靠的问题，避免因意外停机导致的生产延误、良品率低下等问题</p>
            </div>
          </div>
        </div>
        <div class="main-content reslove-case-main-768" v-if="!widOver768">
          <div class="reslove-img">
            <img src="@/assets/images/factory/factory-res.png">
          </div>
          <div class="resolve-case-768-item">
            <p class="resolve-case-768-title">统一</p>
            <p class="resolve-case-768-description">统一通信协议，通过图像数字化</p>
            <p class="resolve-case-768-description">实现各类仪器仪表数据的综合采集</p>
          </div>
          <div class="resolve-case-768-item">
            <p class="resolve-case-768-title">安全</p>
            <p class="resolve-case-768-description">根据用能趋势分析生产加工等设备的运行</p>
            <p class="resolve-case-768-description">健康程度，及时定位安全问题，实现预测性维护</p>
          </div>
          <div class="resolve-case-768-item">
            <p class="resolve-case-768-title">高效</p>
            <p class="resolve-case-768-description">解决人工巡检效率低不可靠的问题，避免因</p>
            <p class="resolve-case-768-description">意外停机导致的生产延误、良品率低下等问题</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 智慧水务 结束 -->
    <!-- 应用案例 开始 -->
    <div class="part part-gray-bg letter-space">
      <div class="valid-area">
        <!-- 标题 -->
        <p class="part-title">应用案例</p>
        <!-- 装饰线 -->
        <p class="decoration-line"></p>
        <!-- 主内容区 -->
        <div class="main-content">
          <div class="case">
            <div class="case-pic">
              <img src="@/assets/images/factory/case-img.png">
            </div>
            <div class="case-info">
              <p><span>客户&nbsp;&nbsp;—&nbsp;&nbsp;</span>河北某工厂</p>
              <p><span>时间&nbsp;&nbsp;—&nbsp;&nbsp;</span>2019年4月</p>
              <p><span>表具&nbsp;&nbsp;—&nbsp;&nbsp;</span>压力表、流量计、电表、水表</p>
              <p><span>效果&nbsp;&nbsp;—&nbsp;&nbsp;</span>实现高频拍照监控，示数超限后，在平台实时声光报警，在地图中动态显示异常仪表坐标位置。替代人工2小时巡检一次，及时采取安全措施</p>
            </div>
          </div>
          <div class="case-app">
            <div class="case-app-box">
              <div class="case-data">
                <p style="width: 40%">（仪表数据）</p>
                <p style="width: 40%">（仪表超限告警）</p>
                <p style="width: 33%">（仪表告警阈值设置）</p>
              </div>
              <img class="case-data-pic" src="@/assets/images/factory/case.png">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 应用案例 结束 -->
    <!-- 安装照片 开始 -->
    <div class="part letter-space" :style="{background:'#2B2E46 url('+require('@/assets/images/home/advantage-bg.png')+') no-repeat 90px 90px / 90% 90%'}">
      <div class="valid-area">
        <!-- 标题 -->
        <p class="part-title dark-bg-text-white">安装照片</p>
        <!-- 装饰线 -->
        <p class="decoration-line"></p>
        <div class="main-content">
          <img src="@/assets/images/factory/install.png" style="width: 100%;display: block;margin:0 auto;">
        </div>
      </div>
    </div>
    <!-- 安装照片 结束 -->
  </div>
</template>

<script>
  import $ from 'jquery'
  export default {
    name: 'factory',
    data(){
      return {
        widOver768: false
      }
    },
    methods: {
      appear(){
        if(this.widOver768){
          $('.resolve-case-item').eq(0).delay(200).animate({"right":0,"opacity":1},600);
          $('.resolve-case-item').eq(1).delay(400).animate({"right":0,"opacity":1},600);
          $('.resolve-case-item').eq(2).delay(600).animate({"left":0,"opacity":1},600);
        }else{
          $('.resolve-case-768-item').eq(0).delay(200).animate({"right":0,"opacity":1},600);
          $('.resolve-case-768-item').eq(1).delay(400).animate({"right":0,"opacity":1},600);
          $('.resolve-case-768-item').eq(2).delay(600).animate({"left":0,"opacity":1},600);
        }
      },
      handleScroll(){
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        let offsetTop = document.querySelector('.case-app').parentElement.parentElement.offsetTop - 300
        if(scrollTop >= offsetTop){
          $('.case-app').animate({'top':0,'opacity':1},300)
        }
      },
      getWid(){
        let wid = document.documentElement.clientWidth || document.body.clientWidth
        this.widOver768 = wid <= 768 ? false : true;
      },
    },
    mounted(){
      this.getWid();
      this.appear();
      window.addEventListener('scroll',()=>{
        if(this.$route.name=='factory'){
          this.handleScroll()
        }
      });
      window.addEventListener('resize',this.getWid);
    },
    updated(){
      this.appear();
    },
  }
</script>

<style lang="less" scoped>
  .page-banner{
    .valid-area{
      height: 100%;
    }
    .align-center{
      width: 100%;
      left: 0;
      transform: translate(0,-50%);
      @media screen and (max-width:768px){
        left: 50%;
        transform: translate(-50%,-50%);
      }
    }
  }
  .reslove-case-main .resolve-case .resolve-case-item:nth-child(2n){
    margin-top: 246px;
  }
</style>
