<template>
  <!-- 电法勘探设备 -->
  <div>
    <div v-for="(product, index) in products" :key="index">
      <div v-if="product.id === productId" class="product-details">
        <div class="product-top">
          <h2>{{ product.name }}</h2>
          <p class="product-description">{{ product.description }}</p>
        </div>
        <div class="carousel-container" v-if="product.carouselImage">
          <img :src="product.carouselImage" style="width: 400px;"/>
        </div>
        <div class="carousel-container" v-else>
        <!-- 轮播图展示区域 -->
          <el-carousel :interval="3000" autoplay style="width: 400px;">
            <!-- 轮播图内容 -->
            <el-carousel-item v-for="(item, index) in product.carouselItems" :key="index">
              <img :src="item.src" alt="轮播图" />
            </el-carousel-item>
          </el-carousel>
      </div>
      </div>
      <div v-if="product.id === productId" class="parameter-vbom">
        <div class="parameter-title">
          <h3>产品参数</h3>
          <div class="separator"></div>
        </div>
        <p v-if="product.galleryText" class="product-description">{{ product.galleryText }}</p>
        <div v-if="product.galleryImage" class="image-gallery">
          <img :src="product.galleryImage" />
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>

export default {
props: ["productId"],
  data() {
    return {
      products: [
        {
          id: "tc_10005_0001",
          name: "GEM-2UAV 多频电磁探测仪",
          description: "GEM-2UAV 多频电磁探测仪是GEM-2 多频电磁探测仪的定制型号，它专门用于无人机搭载使用。 GEM-2UAV探测深度与GEM-2基本类似，其飞行离地高度需要保持在 2m以内，并尽量保持平稳飞行。 2018年6月，GEM-2UAV与国产大疆的DJI-MATRICE-600无人机搭载匹配性试验结果十分成功。通过对其的技术性调整避开消除了无人机对设备的干扰。",
          carouselImage: require("@/assets/images/ESci/ElectricalExplorationEquipment/GEM-2UAV.png"),
          carouselItems:null,
          galleryImage: require("@/assets/images/ESci/ElectricalExplorationEquipment/p1.png")
        },
        {
          id: "tc_10005_0002",
          name: "GEM-2多频电磁探测仪",
          description: "GEM-2多频电磁探测仪是通过一个发射线圈发射单频或者多频的电磁波一次场信号，同时采用一个接收线圈接收感应的二次场信号。依靠取得的数据分析地下目标体的电导率或磁化率的电磁属 性。它的便携性和多功能的特点使它能够应用在许多项目之中。 根据用户的需求最高可以同时进行10组用户指定频率的数据采集。这使得它能找到合适的探测频 率来区分目标体和周围环境。从25Hz到96kHz这种超宽的频带给了GEM-2独特的探测能力。",
          carouselImage: require("@/assets/images/ESci/ElectricalExplorationEquipment/g1.png"),
          carouselItems:null,
          galleryImage: require("@/assets/images/ESci/ElectricalExplorationEquipment/p2.png")
        },
        
      ]
    };
  }
};
</script>
<style lang="less" scoped>
@import "../../../assets/styles/GeoSciProduct.less";
</style>
<style scoped>
</style>
