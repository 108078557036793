<template>
  <div>
    <div>
      <div class="header-wrap" :class="{ 'fixed-header-wrap': fixedHeader || isFixed, 'hidden': !widOver768 }"
        v-if="widOver768">
        <div class="valid-area clearfix">
          <div class="fl clearfix">
            <div class="logo-box fl" @click="navTo">
              <img src="@/assets/images/header/logo.svg" />
            </div>
            
          </div>
          <div class="layout-flex fr">
            <div class="menu fl">
              <div class="menu-item fl">
                <router-link to="/">首页</router-link>
              </div>
              <Dropdown class="menu-item fl">
                <a href="#">公司介绍</a>
                <DropdownMenu slot="list">
                  <DropdownItem>
                    <router-link to="/company">公司简介</router-link>
                  </DropdownItem>
                  <DropdownItem >
                    <router-link :to="{ path: '/company', hash: '#culture' }">企业文化</router-link>
                  </DropdownItem>
                  <DropdownItem >
                    <router-link to="/company#qualification-list">荣誉资质</router-link>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
              <Dropdown class="menu-item fl">
                <a href="javascript:void(0)">业务板块</a>
                <DropdownMenu slot="list">
                  <DropdownItem>
                    <router-link to="/IIot">智慧工业</router-link>
                  </DropdownItem>
                  <DropdownItem >
                    <router-link to="/geoscience">地球科学</router-link>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
              <Dropdown class="product menu-item fl" style="top: 65px">
                <a href="javascript:void(0)">产品中心</a>
                <DropdownMenu slot="list" class="layout-flex">
                  <div class="products-menu">
                    <div v-for="(product, index) in productsMenu" :key="product.id" class="product-menu-item"
                      @mouseover="hoverItem(product.categoriesId)" @mouseout="leaveItem(product.categoriesId)"
                      :class="{ 'selected': selectedIndex === product.categoriesId }">
                      <div class="product-menu-name">{{ product.name }}</div>
                    </div>
                  </div>
                  <div class="selected-list-info">
                    <div v-for="item in selectedList" :key="item.id" class="list-item" @click="handleClick(item)">
                      <div class="item-img">
                        <img :src="require('@/assets' + item.image)" alt="Product Image" class="list-item-image" />
                      </div>
                      
                      <div class="list-item-name">{{ item.name }}</div>
                    </div>
                  </div>

                </DropdownMenu>


              </Dropdown>
              <!-- <div class="menu-item fl">
              <router-link to="/">新闻动态</router-link>
              </div> -->
              <Dropdown class="menu-item fr">
                <a href="javascript:void(0)">行业案例</a>
                <DropdownMenu slot="list">
                  <!-- <Dropdown placement="right-start"> -->
                    <!-- <DropdownItem> -->
                      <!-- 智慧工业案例 -->
                      <!-- <Icon type="ios-arrow-right"></Icon> -->
                    <!-- </DropdownItem> -->
                    <!-- <DropdownMenu slot="list"> -->
                      <DropdownItem>
                        <router-link to="/fuelGas">智慧燃气</router-link>
                      </DropdownItem>
                      <DropdownItem>
                        <router-link to="/oilGas">智慧油气</router-link>
                      </DropdownItem>
                      <DropdownItem>
                        <router-link to="/substations">智慧变电站</router-link>
                      </DropdownItem>
                      <DropdownItem>
                        <router-link to="/water">智慧水务</router-link>
                      </DropdownItem>
                      <DropdownItem>
                        <router-link to="/property">智慧物业</router-link>
                      </DropdownItem>
                      <DropdownItem>
                        <router-link to="/factory">智慧工厂</router-link>
                      </DropdownItem>
                    <!-- </DropdownMenu> -->
                  <!-- </Dropdown> -->
                  <!-- <Dropdown placement="right-start">
                  <DropdownItem>
                    地球科学案例
                    <Icon type="ios-arrow-right"></Icon>
                  </DropdownItem>
                  <DropdownMenu slot="list">

                  </DropdownMenu>
                </Dropdown> -->

                </DropdownMenu>
              </Dropdown>

            </div>
            <!-- <div class="menu-item-phone fr">
              <img src="@/assets/images/footer/phone.png" />
              <p>咨询电话：010-82860760</p>
            </div> -->
            <div class="menu-item-phone fr">
              <!-- <img src="@/assets/images/footer/phone.png" />
              <p>咨询电话：010-82860760</p> -->
              <a v-if="isHome" href="https://lanwair.evavision.cn" target="_blank" style="color: #fff;">前往三合探测  ></a>
            </div>
            <!-- <a class="back-old" href="https://lanwair.evavision.cn" target="_blank">前往三合探测  ></a> -->
            <div class="menu-item-phone fr">
              <a v-if="isIot" href="http://meterlook.lanwair.com" target="_blank" style="color: #fff;">English ></a>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
import productsList from "../../libs/productsList.js";
export default {
  name: "theader",
  props: ["isFixed"],
  data() {
    return {
      fixedHeader: false,
      widOver768: true,
      showMenu: true,
      productsMenu: productsList.hotList,
      selectedIndex: 1,// 默认选中第一个项
      productRoute: '',

      // 其他数据...
      showHotProductsDropdown: false,
      isIot: false,
      isHome: false,
    };
  },
  mounted() {
    // this.getWid();
    window.addEventListener("scroll", this.handleScroll);
    // window.addEventListener("resize", this.getWid);
  },
  methods: {
    handleScroll() {
      if (this.widOver768) {
        let scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop;
        let offsetTop = document.querySelector(".header-wrap").offsetTop;
        this.fixedHeader = scrollTop > offsetTop ? true : false;
      }
    },
    navTo() {
      this.$router.push("/");
    },
    hoverItem(index) {
      this.selectedIndex = index;
    },
    leaveItem(index) {
      // 不做任何操作，保持之前的选中状态
    },
    handleClick(product) {
      const clickedElement = document.querySelector('.product');
      const dropdownMenu = clickedElement.querySelector('.ivu-select-dropdown');

      if (dropdownMenu) {
        dropdownMenu.style.display = 'none';
      }

      const route = product.route ? product.route : this.productRoute;
      this.$router.push({
        name: route,
        params: {
          subcategoryId: product.id,
        },
        query: route === 'geoscience' ? {
          id: product.id,
        } : null,
        hash:product.hash?'#'+product.hash:''
      })
    }
  },
  computed: {
    selectedList() {
      const selectedMenu = this.productsMenu[this.selectedIndex];
      this.productRoute = this.productsMenu[this.selectedIndex].route;

      const list = selectedMenu ? selectedMenu.list : [];

      return list;

    },
  },
  watch: {
    $route(to, from) {
      if (to.path === '/IIot') {
        this.isIot = true;
      } else {
        this.isIot = false;
      }
      if (to.path === '/home') {
        this.isHome = true;
      } else {
        this.isHome = false;
      }
    },
  },
  created() {
    if (this.$route.path === '/IIot') {
        this.isIot = true;
    }  else {
        this.isIot = false;
      }
    if (this.$route.path === '/home') {
        this.isHome = true;
    } else {
        this.isHome = false;
      }
  }
};
</script>


<style lang="less" scoped>
@bg-color: #1d212e;
@base-color: #3e86f7;

@select-bg-color: #0087FF;

.hot-products-dropdown {
  position: absolute;
  top: 60px;
  /* 与顶部导航栏底部对齐 */
  left: 0;
  width: 100%;
  height: 459px;
  background-color: #fff;
  border: 1px solid #ccc;
  z-index: 100;
  display: flex;
}

.dropdown-column {
  flex: 1;
  padding: 20px;
  width: 100%;
  height: 456px;
}

.dropdown-column h3 {
  font-size: 16px;
  margin-bottom: 10px;
}

.product-display {
  flex: 2;
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
}

.product-item {
  width: 30%;
  /* 调整产品展示区域的宽度 */
  margin-bottom: 20px;
}

.product-item img {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.product-item span {
  display: block;
  font-size: 14px;
  text-align: center;
}

.header-wrap {
  width: 100%;
  height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.1);
  // border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  z-index: 99999;

  &:hover {
    background: @bg-color;
    // border-bottom-color: @bg-color;
  }
}

.mini-header,
.mini-menu {
  position: absolute;
  top: 0;
  left: 0;
}

.mini-header {
  width: 7.5rem;
  height: 0.72rem;
  padding: 0 0.32rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: @bg-color;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  z-index: 2;

  img {
    height: 0.35rem;
    cursor: pointer;
  }

  .right-part {
    align-items: center;
    width: 90px;

    
  }

  .right-part span {
      color: #cccccc;
      margin-right: 10px;
      font-size: 12px;
    }

    .right-part img {
      position: relative;
      top: 3px;
      height: 0.35rem;
      cursor: pointer;
    }
}

.mini-menu {
  width: 7.5rem;
  height: 12.62rem;
  top: 0.72rem;
  padding: 0 0.45rem;
  background: @bg-color;
  font-size: 0.28rem;
  color: #fff;
  z-index: 2;
  overflow: auto;

  li {
    line-height: 0.9rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);

    a {
      display: block;
      color: #fff;
    }
  }
}

.fixed-header-wrap {
  background: @bg-color;
  border-bottom-color: @bg-color;
}

.logo-box {
  height: 30px;
  margin: 17px 40px 0 10px;
  cursor: pointer;
}

.menu-item-phone {
  display: flex;
  align-items: center;
  line-height: 60px;
  color: #fff;
  padding-left: 30px;
  // img{
  //   align-items: center;
  // }
  img {
    margin-right: 10px;
    // top: 4px;
  }

  p {
    display: inline;
    vertical-align: middle;
  }
}

.menu-item {
  line-height: 60px;
  // margin: 0 30px;
  width: 100px;
  color: #fff;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    color: #fff;
    // color: @base-color;
    background: @select-bg-color
  }

  a {
    color: #fff;

    // &:hover {
    //   color: @base-color;
    // }
  }

  .item-icon {
    position: relative;
    top: 2px;
    margin-right: 6px;
  }
}

.micro-program {
  width: 420px;
  display: flex;
  justify-content: space-around;
  padding: 15px;

  .micro-item {
    width: 102px;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 100%;
    }

    .micro-title {
      margin: 10px 0 5px;
      color: #485064;
    }

    .micro-text {
      margin: 10px 0 5px;
      color: #485064;
      font-size: 14px;
    }

    .micro-description {
      color: #c1c5d1;
    }
  }
}

.micro-program2 {
  width: 150px;
  display: flex;
  justify-content: space-around;
  padding: 15px;

  .micro-item {
    width: 102px;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 100%;
    }

    .micro-title {
      margin: 10px 0 5px;
      color: #485064;
    }

    .micro-text {
      margin: 10px 0 5px;
      color: #485064;
      font-size: 14px;
    }

    .micro-description {
      color: #c1c5d1;
    }
  }
}

.micro-text {
  margin: 10px 0 5px;
  color: #485064;
  align-content: normal;
}
</style>
<style lang="less">
@bg-color: #1d212e;
@base-color: #3e86f7;

// 下拉框样式
.header-wrap .ivu-dropdown .ivu-select-dropdown {
  // padding: 16px;
  margin: 0;
  // background: rgba(255, 255, 255, 0.35);
  // background: rgba(0, 0, 0, 1);
  background:#1d212e;
  width: 120px;
  color: #fff;
  // text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5); /* 添加文字阴影 */
  transform-origin: center top;
  position: absolute;
  will-change: top, left;
  // top: 65px !important;
  left: 320px;

  .ivu-dropdown-item {
    font-size: 16px !important;
    text-align: center;
    // color: @bg-color;
    color: #fff;
    width: 120px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      // background: rgba(255, 255, 255, 0.5);
      background: transparent;
      border-left: 3px solid #0087FF;
      // height: 100%;
      // height: 40px;
    }

    a {
      color: #fff;
    }

    // a:hover {
    //   color: @bg-color;
    //   color: #fff;
    // }
  }

}

.ivu-dropdown-item-divided {
  // height: 50px !important;
  // display: flex!important; 
  // align-items: center!important;
  // justify-content: center!important;
  // width: 160px;
  margin-top: 0 !important;
  // height: 1px !important;
  // background: rgba(255,255,255,0.3) !important;
  // border-radius: 0px 0px 0px 0px;
}

.ivu-dropdown-item-divided:before {
  // width: 160px;
  height: 50px !important;
  height: 1px !important;
  background: rgba(255, 255, 255, 0.3) !important;
  // border-radius: 0px 0px 0px 0px;
  height: 1px !important;
  display: block;
  // margin: 0 -16px;
  margin: 0 !important;
  background-color: #fff;
  position: relative;
  top: -7px;
}

.header-wrap .ivu-tooltip-popper[x-placement^="bottom"] {
  padding: 0;
}

.header-wrap .ivu-tooltip-popper[x-placement^="bottom"] .ivu-tooltip-arrow {
  top: -6px;
  border-width: 0 6px 6px;
  border-bottom-color: #fff;
}

.header-wrap .ivu-tooltip-inner {
  max-width: 420px;
  padding: 0;
  background: #fff;
}

// 菜单样式
.header-wrap .ivu-menu-horizontal {
  height: 50px;
  line-height: 50px;
  margin-left: 20px;
  background: transparent;
}

.header-wrap .ivu-menu-light.ivu-menu-horizontal>.ivu-menu-item,
.header-wrap .ivu-menu-light.ivu-menu-horizontal .ivu-menu-submenu {
  padding: 0 30px;
  border: 0;
  color: #fff;
}

.header-wrap .ivu-menu-submenu-title span>i,
.header-wrap .ivu-menu-submenu-title>i {
  display: none;
}

.header-wrap .ivu-menu-light.ivu-menu-horizontal .ivu-menu-item-active,
.header-wrap .ivu-menu-light.ivu-menu-horizontal .ivu-menu-item:hover,
.header-wrap .ivu-menu-light.ivu-menu-horizontal .ivu-menu-submenu-active,
.header-wrap .ivu-menu-light.ivu-menu-horizontal .ivu-menu-submenu:hover {
  color: @base-color;
  border: 0;
}

.header-wrap .ivu-menu-horizontal .ivu-menu-submenu .ivu-select-dropdown {
  // padding: 16px;
  margin: 0;
  // border-radius: 2px;
  // background: rgba(255,255,255,0.5);
  border-radius: 0px 0px 0px 0px;
  overflow: visible;

  .ivu-menu-item {
    color: @bg-color;

    &:hover {
      background: rgba(60, 128, 235, 0.1);
    }
  }
}

.header-wrap .ivu-menu-horizontal .ivu-menu-submenu .ivu-select-dropdown .ivu-menu-item-selected,
.header-wrap .ivu-menu-horizontal .ivu-menu-submenu .ivu-select-dropdown .ivu-menu-item-selected:hover {
  color: @base-color;
}

.header-wrap .ivu-menu-horizontal .ivu-menu-submenu .ivu-select-dropdown::before {
  content: "";
  border: 6px solid transparent;
  border-bottom-color: #fff;
  position: absolute;
  top: -12px;
  left: 50%;
  transform: translateX(-50%);
}

.header-wrap .ivu-menu-horizontal.ivu-menu-light:after {
  background: transparent;
}

.product {
  .products-menu {
    display: flex;
    flex-direction: column;
    width: 260px;
    height: 496px;

    .product-menu-item {
      transition: background-color 0.3s ease-in-out;
      height: 68px;
      display: flex;
      position: relative;

      .product-menu-name {
        text-align: center;
        align-self: center;
        width: 100%;
      }
    }

    .product-menu-item.selected {
      background: rgba(255, 255, 255, 0.5);
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 20px;
      color: #0087FF;
      font-style: normal;
      text-transform: none;
      // border-left: 4px solid #0087FF;
    }

    .product-menu-item.selected::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 4px;
      background-color: #0087FF;
    }

    .product-menu-item:not(.selected) .product-menu-name {
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 20px;
      color: #333333;
      font-style: normal;
      text-transform: none;
    }
  }

  .selected-list-info {
    flex: 1;
    // padding: 20px;
    border-left: 1px solid #ccc;
    overflow-y: auto;
    max-height: 496px;
    display: flex;
    flex-wrap: wrap;
    background-color: #fff;
  }

  .list-item {
    width: 33.33%;
    /* Each list item takes up 30% of the container width, leaving space for margins */
    // margin: 1.5%; /* Add margin between list items */
    text-align: center;
    height: 248px;
    border: 1px solid #ccc;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:hover{
      background-color: #fff;
      cursor: pointer; 
    }

    .item-img{
      display: flex;
      justify-content: center; 
      align-items: center; 
      height: 180px;
    }
  }

  .list-item-name {
    font-size: 16px;
    margin: 10px 0 10px 0;
    // width: 200px;
    height: 22px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #333333;
    line-height: 19px;
    text-align: center;
    font-style: normal;
    text-transform: none;
  }

  .list-item-image {
    max-width: 200px;
    max-height: 180px;
    text-align: center;
  }

  .ivu-select-dropdown {
    width: 100% !important;
    height: 496px;
    border-radius: 0px 0px 0px 0px;
    left: 0 !important;
    padding: 0 !important;
    // top: 0 !important;

    .ivu-dropdown-menu {
      background-color: rgba(255, 255, 255, 0.85) !important;
      height: 496px;
    }
  }
}

.back-old {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    color: #FFF;
  }
</style>


