<template>
  <div>
    <el-carousel :interval="3000" autoplay height="500px">
      <!-- 轮播图内容 -->
      <el-carousel-item v-for="(item, index) in carouselItems" :key="index">
        <img :src="item.src" alt="轮播图" class="carousel-img"/>
      </el-carousel-item>
    </el-carousel>

    <!-- 页面布局 -->
    <div class="page-layout">
      <!-- 左侧菜单栏 -->
      <el-menu class="menu" text-color="#D4D4D4">
        <el-menu-item class="menu-item" v-for="(item, index) in productsMenu" :key="item.subcategoryId"
          @click="handleMenuItemClick(item, index)"
          :class="{ 'active-menu-item': curSubcategoryId === item.subcategoryId }" style="">
          <img v-if="curSubcategoryId === item.subcategoryId" src="@/assets/images/geoscience/jiantou.svg"
            class="menu-icon" />
          <div class="menu-text">
            <span>{{ item.name }}</span>
          </div>
        </el-menu-item>
      </el-menu>

      <div class="right-com">
        <!-- <router-view :is="component" :subcategoryId=curSubcategoryId></router-view> -->
        <div class="product-container" v-if="!showChildComponent">
          <div v-for="(product, index) in curProductsList" :key="product.subcategoryId" class="product"
            @click="showProductDetail(product, index)">
            <div class="product-name">
              <p>{{ product.name }}</p>
            </div>
            <div class="product-img">
              <img :src="require('@/assets' + product.image)" alt="产品图片" />
            </div>
          </div>
          
        </div>
        <!-- <router-view v-if="showChildComponent" name="childRoute"></router-view> -->
        <router-view v-if="showChildComponent" :is="component" :productId=curProductId
          @backToParent="handleBack"></router-view>
      </div>

    </div>
  </div>
</template>

<script>
import productsList from "../../libs/productsList.js";
export default {
  data() {
    return {
      activeMenuItem: null,
      curSubcategoryId: 1,
      curProductsList: null,
      component: null,
      showChildComponent: false,
      childRoute: null,
      curProductId: 1,
      // 轮播图
      carouselItems: [
        { src: require("@/assets/images/geoscience/banner1.jpg") },
        { src: require("@/assets/images/geoscience/banner2.jpg") },
        { src: require("@/assets/images/geoscience/banner3.jpg") }
      ],
      // 地球科学数组
      productsMenu: productsList.productsList.find(
        item => item.categoriesId === 2
      ).list,
      // curSubcategoryId:productsMenu[0].subcategoryId
    };
  },
  methods: {
    handleMenuItemClick(item, index) {
      this.curSubcategoryId = item.subcategoryId;

      this.activeMenuItem = index;
      // 菜单栏切换
      // this.component = () => import('@/components/views/product/' + item.route);
      this.curProductsList = this.productsMenu.find(item => item.subcategoryId === this.curSubcategoryId).list;
      this.showChildComponent = false;
    },
    showProductDetail(item, index) {
      this.curProductId = item.productId;
      this.childRoute = this.productsMenu.find(item => item.subcategoryId === this.curSubcategoryId).route;
      this.component = () => import('@/components/views/product/' + this.childRoute);
      // this.$router.push({
      //   name: this.childRoute,
      //   params: {
      //     productId: item.productId,
      //   },
      // })
      this.showChildComponent = true;

    },
    handleBack() {

      this.showChildComponent = false;

    },
  },
  mounted() {
    if (this.$route.params.subcategoryId) {
      this.curSubcategoryId = this.$route.params.subcategoryId;
      
    } else if(this.$route.query.id){
      this.curSubcategoryId = this.$route.query.id;
    } else {
      this.curSubcategoryId = this.productsMenu[0].subcategoryId;
    }
    if(!this.productsMenu.find(item => item.subcategoryId === this.curSubcategoryId)){
      this.curSubcategoryId = this.productsMenu[0].subcategoryId;
    } 
    this.curProductsList = this.productsMenu.find(item => item.subcategoryId === this.curSubcategoryId).list;
    
  },
  // 路由监控跳转
  watch: {
    '$route'(to, from) {
      this.curSubcategoryId = to.params.subcategoryId;
      this.curProductsList = this.productsMenu.find(item => item.subcategoryId === this.curSubcategoryId).list;
      this.showChildComponent = false;
    }
  }
};
</script>

<style scoped>
.carousel-img{
  width: 100%;
  height: 100%;
}
.page-layout {
  display: flex;
  background-color: #1D212E;
  width: 100%;
  height: auto;
  margin-top: 0px;
}

.menu {
  padding-top: 50px;
  width: 650px;
  background-color: #1D212E;
  border-right: solid 0px #e6e6e6;
}

/* 选中时icon的样式 */
.menu-icon {
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.menu-item {
  display: flex;
  height: 60px;
  width: 100%;
  transition: background 0.3s;
  justify-content: center;
}

/* 选中菜单时的背景色 */
.active-menu-item {
  background: linear-gradient(270deg, #0087FF 0%, rgba(0, 135, 255, 0) 100%);
}
.active-menu-item span {
    color: #fff;
  }

.right-com {
  width: 100%;
  height: auto;
  /* border: 1px solid red; */
}

.menu-text {
  width: 100px;
  align-items: center;
  justify-content: center;
  text-align: left;
}

.menu-text span {
  line-height: 23px;
  font-style: normal;
  text-transform: none;
  margin-right: auto;
  font-weight: 500;
  font-size: 20px;
  /* color: #FFFFFF; */
  color: #D4D4D4;
  display: inline-block;
}

.el-menu-item:focus,
.el-menu-item:hover {
  outline: 0;
  background-color: transparent !important;
}

.product-container {
  padding: 50px;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
}

/*  */
.product {
  position: relative;
  border: 2px solid transparent;
  border-bottom-color: #0087FF;
  margin-right: 48px;
  margin-bottom: 48px;
  cursor: pointer;
  width: 300px;
  height: 300px;

  text-align: center;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* clip-path: polygon(20% 0%, 100% 0, 100% 80%, 80% 100%, 0% 100%, 0% 20%); */
  clip-path: polygon(60px 0%, 100% 0, 100% 240px, 240px 100%, 0% 100%, 0% 60px);
}

.product::after {
  content: "";
  position: absolute;
  background-color: #0087ff;
  z-index: 1;
  transform: rotate(45deg);
  width: 2px;
  height: 120px;
  bottom: 0;
  right: 57px;
  transform-origin: left bottom;
}

/* .product::after {
  content: "";
  position: absolute;
  height: 120px;
  top: 0;
  right: 0;
  width: 2px;
  height: 100%;
  background-color: #0087FF;
  transform: translate(12800%, 0%) rotate(47deg);
  transform: translate(239px, 0%) rotate(45deg);
} */
/*  */
 .product-name{
  height: 96px;
 }

.product-name p {
  margin: 20px 0 13px 5px;
  width: 240px;
  height: 60px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  font-size: 20px;
  color: #FFFFFF;
  line-height: 28px;
  text-align: center;
  font-style: normal;
  text-transform: none;
}

.product-img{
  height: 200px;
 }
.product-img img {
  max-width: 280px;
  max-height: 200px;
  object-fit: cover;
}

.product:hover {
  border: 1px dashed #8C8E95;
  padding: 1px;
}

.product:hover::after {
  background-color: transparent;
}</style>
