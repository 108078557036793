<template>
  <div class="device-wrap">
    <!-- banner 开始 -->
    <div class="page-banner" :style="{background: !widOver768 ? '#1D212E url('+require('@/assets/images/device/banner.jpg')+') no-repeat' : '#131313', backgroundSize: 'cover'}">
      <div class="banner-mask" style="background:rgba(0,0,0,.2)" v-if="!widOver768"></div>
      <div id="dev-banner" v-if="widOver768"></div>
      <div class="valid-area">
        <div class="align-center">
          <p class="page-banner-title">羿读智能AI相机</p>
          <p class="page-banner-description">安装简单,工作稳定,降低智能化成本</p>
        </div>
        <div class="device-img" v-if="widOver768">
            <swiper :options="deviceOption" ref="deviceSwiper">
              <swiper-slide>
                <img src="@/assets/images/device/argus2.png">
              </swiper-slide>
              <swiper-slide>
                <img src="@/assets/images/device/doris2.png">
              </swiper-slide>
              <swiper-slide>
                <img src="@/assets/images/device/horus2.png">
              </swiper-slide>
              <div class="device-pagination" slot="pagination"></div>
            </swiper>
          </div>
      </div>
    </div>
    <!-- banner 结束 -->
    <!-- 产品特点 开始 -->
    <div class="part part-light-bg letter-space">
      <div class="valid-area"  :style="{backgroundImage:'url('+require('../../assets/images/device/advantage-bg.png')+')',backgroundSize:'100% 100%'}">
        <!-- 标题 -->
        <p class="part-title">产品特点</p>
        <!-- 装饰线 -->
        <p class="decoration-line"></p>
        <!-- 描述 -->
        <div class="part-description">
          <p>防水、防潮、防冻、防爆、防油污、防踩踏、防拆除</p>
        </div>
        <!-- 主内容区 -->
        <div class="main-content layout-flex charctor">
          <div class="charctor-item flex-col-align" v-for="(item,ind) in charctorList" :key="ind">
            <div class="item-pic-box">
              <img class="align-center" :src="require('@/assets/images/device/charctor-'+item.img)">
            </div>
            <p class="main-content-title">{{item.title}}</p>
            <p class="main-content-description">{{item.desc1}}</p>
            <p class="main-content-description">{{item.desc2}}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 产品特点 结束 -->
    <!-- argus 开始 -->
    <div id="argus" class="part part-gray-bg letter-space">
      <div class="valid-area layout-flex product argus">
        <div class="product-pic" v-if="widOver768">
          <div class="pic pic-big">
            <img src="@/assets/images/device/argus1.png" v-if="curArgus==0">
            <img src="@/assets/images/device/argus2.png" v-if="curArgus==1">
            <img src="@/assets/images/device/argus3.png" v-if="curArgus==2">
            <img src="@/assets/images/device/argus4.png" v-if="curArgus==3">
          </div>
          <div class="pic pic-small layout-flex">
            <div class="pic-small-item" :class="{'cur-pic-small':curArgus==0}" @click="curArgus=0">
              <img class="align-center" src="@/assets/images/device/argus1.png">
            </div>
            <div class="pic-small-item" :class="{'cur-pic-small':curArgus==1}" @click="curArgus=1">
              <img class="align-center" src="@/assets/images/device/argus2.png" style="width:78px;height:auto;">
            </div>
            <div class="pic-small-item" :class="{'cur-pic-small':curArgus==2}" @click="curArgus=2">
              <img class="align-center" src="@/assets/images/device/argus3.png">
            </div>
            <div class="pic-small-item" :class="{'cur-pic-small':curArgus==3}" @click="curArgus=3">
              <img class="align-center" src="@/assets/images/device/argus4.png">
            </div>
          </div>
        </div>
        <div class="product-profile argus-profile">
          <p class="part-title" v-if="widOver768">羿读智能AI相机 A系<span class="point-text">- Argus</span></p>
          <p class="part-title" v-if="widOver768"><span class="title-desc">适用于电表、气表、压力表、温度表、LED或LCD屏等</span></p>
          <p class="part-title" v-if="!widOver768">羿读智能AI相机 A系<span class="point-text-phone">- Argus</span></p>
          <p class="decoration-line"></p>
          <p class="title-desc-768" v-if="!widOver768">适用于电表、气表、压力表、<br/>温度表、LED或LCD屏等</p>
          <div class="pic-768" v-if="!widOver768">
            <img src="@/assets/images/device/argus.png">
          </div>
          <div class="product-outter-box">
            <div class="product-inner-box layout-flex">
              <div class="product-param">
                <p>防护等级：IP68</p>
                <p>工作温度：-30℃～65℃</p>
                <p>供电方式：内置锂电池，可更换</p>
                <p>电池容量：8000mAh</p>
                <p>待机功耗：小于3μA</p>
                <p>通信方式：NB-IoT/Wi-Fi/Lte-Cat1</p>
                <p>防盗装置：隐形式拆卸实时报警</p>
                <p>启动方式：支持手动磁吸启动/自动定时启动</p>
                <p>红外抄表：与智能电表通信（选配）</p>
              </div>
              <div class="product-install">
                <div class="install-item">
                  <img src="@/assets/images/device/argus-1.jpg">
                  <!-- <p>竖井双层铁皮门内</p> -->
                </div>
                <div class="install-item">
                  <img src="@/assets/images/device/argus-2.jpg">
                  <!-- <p>车间压力表</p> -->
                </div>
                <div class="install-item">
                  <img src="@/assets/images/device/argus-3.jpg">
                  <!-- <p>配电室</p>0 -->
                </div>
                <div class="install-item">
                  <img src="@/assets/images/device/argus-4.jpg">
                  <!-- <p>配电室机柜</p> -->
                </div>
                <div class="install-item">
                  <img src="@/assets/images/device/argus-5.jpg">
                  <!-- <p>工厂车间外接电源</p> -->
                </div>
                <div class="install-item">
                  <img src="@/assets/images/device/argus-7.jpg">
                  <!-- <p>燃气压力表</p> -->
                </div>
              </div>
            </div>
          </div>
          <div class="product-btn clearfix">
            <div class="see-detail fl" v-if="curArgusBtn==0" @click="changeBtn('curArgusBtn',0,'.argus','0')">
              <img class="align-center" src="@/assets/images/btn.png">
              <p>终端参数</p>
            </div>
            <div class="uncheck-btn fl" v-if="curArgusBtn==1" @click="changeBtn('curArgusBtn',0,'.argus','0')">终端参数</div>
            <div class="uncheck-btn fl" v-if="curArgusBtn==0" @click="changeBtn('curArgusBtn',1,'.argus','-100%')">安装场景</div>
            <div class="see-detail fl" v-if="curArgusBtn==1" @click="changeBtn('curArgusBtn',1,'.argus','-100%')">
              <img class="align-center" src="@/assets/images/btn.png">
              <p>安装场景</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- argus 结束 -->
    <!-- doris 开始 -->
    <div id="doris" class="part part-light-bg letter-space">
      <div class="valid-area layout-flex product doris">
        <div class="product-profile doris-profile">
          <p class="part-title" v-if="widOver768">羿读智能AI相机 D系<span class="point-text">- Doris</span></p>
          <p class="part-title" v-if="widOver768"><span class="title-desc">适用各类水表（可浸泡）</span></p>
          <p class="part-title" v-if="!widOver768">羿读智能AI相机 D系<span class="point-text-phone">- Doris</span></p>
          <p class="decoration-line"></p>
          <p class="title-desc-768" v-if="!widOver768">适用于各类水表（可浸泡）</p>
          <div class="pic-768" v-if="!widOver768">
            <img src="@/assets/images/device/doris.png">
          </div>
          <div class="product-outter-box">
            <div class="product-inner-box layout-flex">
              <div class="product-install">
                <div class="install-item">
                  <img src="@/assets/images/device/doris-1.jpg">
                  <!-- <p>暗水地下，湿冷水汽</p> -->
                </div>
                <div class="install-item">
                  <img src="@/assets/images/device/doris-2.jpg">
                  <!-- <p>大理石井盖</p> -->
                </div>
                <div class="install-item">
                  <img src="@/assets/images/device/doris-3.jpg">
                  <!-- <p>地下室，隧道内</p> -->
                </div>
                <div class="install-item">
                  <img src="@/assets/images/device/doris-4.jpg">
                  <!-- <p>井下积水，常年浸泡</p> -->
                </div>
                <div class="install-item">
                  <img src="@/assets/images/device/doris-5.jpg">
                  <!-- <p style="letter-spacing:2px">井底厚积土，环境潮湿</p> -->
                </div>
                <div class="install-item">
                  <img src="@/assets/images/device/doris-6.jpg">
                  <!-- <p>双层铁皮井盖</p> -->
                </div>
              </div>
              <div class="product-param">
                <p>防护等级：IP68</p>
                <p>工作温度：-30℃～65℃</p>
                <p>供电方式：内置锂电池，可更换</p>
                <p>电池容量：38000mAh</p>
                <p>待机功耗：小于3μA</p>
                <p>通信方式：支持NB-IoT/Lte-Cat1</p>
                <p>防盗装置：隐形式拆卸实时报警</p>
                <p>启动方式：支持手动磁吸启动/自动定时启动</p>
                <p>安装支架：防踩踏保护架（选配）</p>
                <p>外接天线：支持</p>
              </div>
            </div>
          </div>
          <div class="product-btn clearfix">
            <div class="see-detail fl" v-if="curDorisBtn==0" @click="changeBtn('curDorisBtn',0,'.doris','-100%')">
              <img class="align-center" src="@/assets/images/btn.png">
              <p>终端参数</p>
            </div>
            <div class="uncheck-btn fl" v-if="curDorisBtn==1" @click="changeBtn('curDorisBtn',0,'.doris','-100%')">终端参数</div>
            <div class="uncheck-btn fl" v-if="curDorisBtn==0" @click="changeBtn('curDorisBtn',1,'.doris','0')">安装场景</div>
            <div class="see-detail fl" v-if="curDorisBtn==1" @click="changeBtn('curDorisBtn',1,'.doris','0')">
              <img class="align-center" src="@/assets/images/btn.png">
              <p>安装场景</p>
            </div>
          </div>
        </div>
        <div class="product-pic" v-if="widOver768">
          <div class="pic pic-big">
            <img src="@/assets/images/device/doris.png" v-if="curDoris==0">
            <img src="@/assets/images/device/doris1.png" v-if="curDoris==1">
            <img src="@/assets/images/device/doris2.png" v-if="curDoris==2">
          </div>
          <div class="pic pic-small layout-flex">
            <div class="pic-small-item" :class="{'cur-pic-small':curDoris==0}" @click="curDoris=0">
              <img class="align-center" src="@/assets/images/device/doris.png" style="width:78px;height:auto;">
            </div>
            <div class="pic-small-item" :class="{'cur-pic-small':curDoris==1}" @click="curDoris=1">
              <img class="align-center" src="@/assets/images/device/doris1.png" style="width:78px;height:auto;">
            </div>
            <div class="pic-small-item" :class="{'cur-pic-small':curDoris==2}" @click="curDoris=2">
              <img class="align-center" src="@/assets/images/device/doris2.png">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- doris 结束 -->
    <!-- horus 开始 -->
    <div id="horus" class="part part-gray-bg letter-space" >
      <div class="valid-area layout-flex product horus">
        <div class="product-pic" v-if="widOver768">
          <div class="pic pic-big">
            <img src="@/assets/images/device/horus.png" v-if="curHorus==0">
            <img src="@/assets/images/device/horus1.png" v-if="curHorus==1">
            <img src="@/assets/images/device/horus2.png" v-if="curHorus==2">
          </div>
          <div class="pic pic-small layout-flex">
            <div class="pic-small-item" :class="{'cur-pic-small':curHorus==0}" @click="curHorus=0">
              <img class="align-center" src="@/assets/images/device/horus.png" style="width:78px;height:auto;">
            </div>
            <div class="pic-small-item" :class="{'cur-pic-small':curHorus==1}" @click="curHorus=1">
              <img class="align-center" src="@/assets/images/device/horus1.png" style="width:78px;height:auto;">
            </div>
            <div class="pic-small-item" :class="{'cur-pic-small':curHorus==2}" @click="curHorus=2">
              <img class="align-center" src="@/assets/images/device/horus2.png">
            </div>
          </div>
        </div>
        <div class="product-profile horus-profile">
          <p class="part-title" v-if="widOver768">羿读智能AI相机 H系<span class="point-text">- Horus</span></p>
          <p class="part-title" v-if="widOver768"><span class="title-desc">适用于小口径水表（非浸泡环境）</span></p>
          <p class="part-title" v-if="!widOver768">羿读智能AI相机 H系<span class="point-text-phone">- Horus</span></p>
          <p class="decoration-line"></p>
          <p class="title-desc-768" v-if="!widOver768">适用于小口径水表（非浸泡环境）</p>
          <div class="pic-768" v-if="!widOver768">
            <img src="@/assets/images/device/horus.png">
          </div>
          <div class="product-outter-box">
            <div class="product-inner-box layout-flex">
              <div class="product-param">
                <p>防护等级：IP68</p>
                <p>工作温度：-30℃～65℃</p>
                <p>供电方式：内置锂电池，可更换</p>
                <p>电池容量：8000mAh</p>
                <p>待机功耗：小于3μA</p>
                <p>通信方式：NB-IoT</p>
                <p>启动方式：支持手动磁吸启动/自动定时启动</p>
                <p>防盗装置：隐形式拆卸实时报警</p>
              </div>
              <div class="product-install">
                <div class="install-item">
                  <img src="@/assets/images/device/horus-1.jpg">
                  <!-- <p>大厦管道井</p> -->
                </div>
                <div class="install-item">
                  <img src="@/assets/images/device/horus-2.jpg">
                  <!-- <p>餐厅后厨</p> -->
                </div>
                <div class="install-item">
                  <img src="@/assets/images/device/horus-3.jpg">
                  <!-- <p>管道井内</p> -->
                </div>
                <div class="install-item">
                  <img src="@/assets/images/device/horus-4.jpg">
                  <!-- <p>水表干涉贴墙</p> -->
                </div>
                <div class="install-item">
                  <img src="@/assets/images/device/horus-5.jpg">
                  <!-- <p>狭小封闭空间</p> -->
                </div>
                <div class="install-item">
                  <img src="@/assets/images/device/horus-6.jpg">
                  <!-- <p>洗手池下</p> -->
                </div>
              </div>
            </div>
          </div>
          <div class="product-btn clearfix">
            <div class="see-detail fl" v-if="curHorusBtn==0" @click="changeBtn('curHorusBtn',0,'.horus','0')">
              <img class="align-center" src="@/assets/images/btn.png">
              <p>终端参数</p>
            </div>
            <div class="uncheck-btn fl" v-if="curHorusBtn==1" @click="changeBtn('curHorusBtn',0,'.horus','0')">终端参数</div>
            <div class="uncheck-btn fl" v-if="curHorusBtn==0" @click="changeBtn('curHorusBtn',1,'.horus','-100%')">安装场景</div>
            <div class="see-detail fl" v-if="curHorusBtn==1" @click="changeBtn('curHorusBtn',1,'.horus','-100%')">
              <img class="align-center" src="@/assets/images/btn.png">
              <p>安装场景</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- horus 结束 -->
  </div>
</template>

<script>
  import {swiper,swiperSlide} from 'vue-awesome-swiper'
  import $ from 'jquery'
  import {Waves} from '@/libs/deviceCanvas.js'

  export default {
    name: 'device',
    components: {
      swiper,
      swiperSlide,
    },
    data(){
      return {
        waves: null,
        widOver768: false,
        deviceOption:{
          autoplay: true,
          loop: true,
          effect: 'fade',
          fadeEffect: {
            crossFade: true,
          }
        },
        // 产品特点
        charctorList:[
          {
            index: 0,
            img: 'meter.png',
            title: '赋能机械表',
            desc1: '发挥机械表工作稳定可靠',
            desc2: '寿命长等优势'
          },
          {
            index: 1,
            img: 'charge.png',
            title: '超长待机 稳定工作',
            desc1: '每日一拍',
            desc2: '六年无需更换电池'
          },
          {
            index: 2,
            img: 'tool.png',
            title: '施工简单 便于维护',
            desc1: '在表具上加装智能AI相机',
            desc2: '无需布线和改动旧表'
          },
          {
            index: 3,
            img: 'lock.png',
            title: '防盗设计 异常告警',
            desc1: '防盗防破坏设计',
            desc2: '拆卸实时报警'
          },
        ],
        curArgus: 0,
        curArgusBtn: 0,
        curDoris: 0,
        curDorisBtn: 0,
        curHorus: 0,
        curHorusBtn: 0,
        offsetTop:[],
      }
    },
    methods:{
      slideToUp(itemName){
        $.each(itemName,function(ind,item){
          $(item).delay(100*(ind+1)).animate({"top":0,"opacity":1},300);
        })
      },
      slideToRight(dom){
        $(dom).animate({"right":0,"opacity":1},300);
      },
      slideToLeft(dom){
        $(dom).animate({"left":0,"opacity":1},300);
      },
      changeBtn(btn,btnFlag,dom,distance){
        this[btn] = btnFlag;
        $(dom+' .product-inner-box').animate({left:distance},200)
      },
      handleScroll(){
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        let screenHei = window.screen.height
        if(scrollTop + screenHei - 350 >= this.offsetTop[3]){
          this.slideToLeft('.part .horus-profile')
        }
        if(scrollTop + screenHei - 350 >= this.offsetTop[2]){
          this.slideToRight('.part .doris-profile')
        }
        if(scrollTop + screenHei - 400 >= this.offsetTop[1]){
          this.slideToLeft('.part .argus-profile')
        }
        // this.offsetTop.forEach((item,ind)=>{
        //   if(ind > 0 && scrollTop + 500 >= item){
        //     console.log(ind)
        //     ind % 2 ? (ind == 1 ? this.slideToLeft('.part .argus-profile') : this.slideToLeft('.part .horus-profile')) : this.slideToRight('.part .doris-profile')
        //   }
        // })
      },
      getWid(){
        this.waves = null;
        let wid = document.documentElement.clientWidth || document.body.clientWidth
        this.widOver768 = wid <= 768 ? false : true;
        if(this.widOver768){
          setTimeout(() => {
            this.waves = new Waves('#dev-banner',{
              waves: 3,
              width: 200,
              rotation: this.widOver768 ? 45 : 30
            },wid,$('.page-banner').height());
            this.waves.animate();
          }, 0);
        }else{
          $('#dev-banner').empty();
        }
      },
    },
    mounted(){
      this.slideToUp($('.charctor-item'));
      this.getWid();
      document.querySelectorAll('.part').forEach(item => {
        this.offsetTop.push(item.offsetTop)
      });
      window.addEventListener('scroll',()=>{
        if(this.$route.name=='device'){
          this.handleScroll()
        }
      });
      window.addEventListener('resize',this.getWid);
    },
    destroyed(){
      // this.waves.clear();
      this.waves = null;
    }
  }
</script>

<style lang="less" scoped>

.page-banner{
  width: 100%;
  height: 480px;
  position: relative;
  color: #fff;
  letter-spacing: 8px;
  text-align: left;
  .page-banner-title{
    margin-bottom: 20px;
    font-size: 48px;
  }
  .page-banner-description{
    font-size:24px;
  }
  @media screen and (max-width:768px){
    height: 4.8rem;
    letter-spacing: 0.08rem;
    text-align: center;
    .page-banner-title{
      margin-bottom: 0.2rem;
      font-size: 0.48rem;
    }
    .page-banner-description{
      font-size: 15px;
    }
  }
}

.banner-mask{
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0,0,0,.5);
}

  .page-banner{
    .valid-area{
      height: 100%;
    }
    #dev-banner{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
    .align-center{
      left: 0;
      transform: translate(0,-50%);
      @media screen and (max-width:768px){
        width: 100%;
        left: 50%;
        transform: translate(-50%,-50%);
      }
    }
    .device-img{
      width: 40%;
      position: absolute;
      right: 0;
      top: 18%;
      text-align: right;
      img{
        height: 360px;
      }
    }
  }
  // 产品特点 
  .charctor{
    justify-content: space-between;
    .charctor-item{
      width: 22%;
      position: relative;
      top: 100px;
      opacity: 0;
      >div{
        margin-bottom: 40px;
      }
    }
    @media screen and (max-width: 768px){
      flex-wrap: wrap;
      .charctor-item{
        width: 46%;
        margin-bottom: 0.4rem;
        >div{
          margin-bottom: 0.4rem;
        }
      }
    }
  }
  // 产品参数/安装环境
  .product{
    justify-content: space-between;
    .product-pic{
      width: 40%;
      .pic{
        width: 360px;
        margin: 0 auto;
      }
      .pic-big{
        height: 360px;
        margin-bottom: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .pic-small{
        .pic-small-item{
          width: 88px;
          height: 88px;
          padding: 5px;
          margin-right: 30px;
          position: relative;
          border: 2px solid transparent;
          cursor: pointer;
          img{
            height: 78px;
          }
        }
        .cur-pic-small{
          border-color: #D7DBE6;
        }
      }
    }
    .pic-768{
      margin: 0.3rem 0;
      text-align: center;
      img{
        width: 50%;
      }
    }
    .product-profile{
      width: 55%;
      position: relative;
      text-align: left;
      opacity: 0;
      .part-title{
        .point-box{
          margin: 0 12px;
        }
        .point-text{
          margin: 0 12px;
          font-size: 20px;
        }
        .point-text-phone{
          margin: 0 12px;
          font-size: 16px;
        }
        .title-desc{
          margin-left: 10px;
          color: #485064;
          font-size: 16px;
        }
      }
      .decoration-line{
        margin: 30px 0;
      }
      @media screen and (max-width:768px){
        width: 100%;
        text-align: center;
        .part-title{
          .point-box{
            margin: 0 0.12rem;
          }
        }
        .title-desc-768{
          color: #485064;
          font-size: 14px;
        }
        .decoration-line{
          margin: 0.5rem auto;
        }
      }
    }
    .argus-profile,.horus-profile{
      left: 100px;
    }
    .doris-profile{
      right: 100px;
    }
    .product-outter-box{
      width: 100%;
      overflow: hidden;
      .product-inner-box{
        width: 200%;
        position: relative;
        left:0;
        >div{
          width: 100%;
          height: 380px;
        }
      }
      @media screen and (max-width: 768px){
        width: 72%;
        margin: 0.4rem auto 0;
        text-align: left;
        .product-inner-box{
          >div{
            height: 310px;
          }
        }
      }
    }
    .product-param{
      p{
        line-height: 2;
      }
      @media screen and (max-width: 768px){
        font-size: 14px;
      }
    }
    .product-install{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .install-item{
        width: 30%;
        img{
          display: block;
          width: 100%;
        }
        p{
          line-height: 48px;
          font-size: 16px;
          color: #1D212E;
          text-align: center;
        }
      }
      @media screen and (max-width: 768px){
        .install-item{
          width: 45%;
          p{
            line-height: 0.4rem;
            font-size: 14px;
            letter-spacing: 0!important;
            text-align: left;
          }
        }
      }
    }
    .product-btn{
      text-align: center;
      .see-detail{
        margin-right: 20px;
      }
      .uncheck-btn{
        width: 167px;
        height: 52px;
        line-height: 46px;
        margin: 10px 20px 0 0;
        color: #3E86F7;
        border: 2px solid #3E86F7;
        border-radius: 52px;
        font-size: 24px;
        cursor: pointer;
      }
      @media screen and (max-width: 768px){
        margin-top: 0.3rem;
        margin-left: calc((100% - 3.82rem)/2);
        .see-detail{
          margin-right: 0.2rem;
        }
        .uncheck-btn{
          width: 1.67rem;
          height: 0.52rem;
          line-height: 0.46rem;
          margin: 0.1rem 0.2rem 0 0;
          font-size: 0.24rem;
        }
      }
    }
  }
  .doris .product-outter-box .product-inner-box{
    left: -100%;
  }
</style>
