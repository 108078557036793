<template>
<!-- 数据采集器 -->
<div>
    <div v-for="(product, index) in products" :key="index">
      <div v-if="product.id === productId" class="product-details">
        <div class="product-top">
          <h2>{{ product.name }}</h2>
          <p class="product-description">{{ product.description }}</p>
        </div>
        <div class="carousel-container" v-if="product.carouselImage">
          <img :src="product.carouselImage" style="width: 400px;"/>
        </div>
        <div class="carousel-container" v-else>
        <!-- 轮播图展示区域 -->
          <el-carousel :interval="3000" autoplay style="width: 400px;">
            <!-- 轮播图内容 -->
            <el-carousel-item v-for="(item, index) in product.carouselItems" :key="index">
              <img :src="item.src" alt="轮播图" />
            </el-carousel-item>
          </el-carousel>
      </div>
      </div>
      <div v-if="product.id === productId" class="parameter-vbom">
        <div class="parameter-title">
          <h3>产品参数</h3>
          <div class="separator"></div>
        </div>
        <p v-if="product.galleryText" class="product-description">{{ product.galleryText }}</p>
        <div v-if="product.galleryImage" class="image-gallery">
          <img :src="product.galleryImage" />
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
export default {
props: ["productId"],
  data() {
    return {
      products: [
        {
          id: "tc_10008_0001",
          name: "DM24数据采集器",
          description: "全24位分辨率的高质量地震数字化仪\nDM24数字化提供高质量的全24位分辨率，为数据质量和耐用性而设计。除了可单独使用，DM24数字化模块也可与模拟信号仪器集成为数字仪器。\nDM24SxEAM - 可适应的数字采集系统，不锈钢外壳带提升支架\nDM24SxEAMU - 数字采集系统，坚固的共聚物聚丙烯外壳，可移动USB存储，用于现场部署\n生产厂家：Güralp Systems Ltd.\n应用：配套3T系列地震仪、3ESPC、40T、6T、Fortis等模拟地震计使用",
          carouselImage: null,
          carouselItems:[
            { src: require("@/assets/images/ESci/StrongMotionAccelerometer/p1c1.jpg") },
            { src: require("@/assets/images/ESci/StrongMotionAccelerometer/p1c2.jpg") },
            { src: require("@/assets/images/ESci/StrongMotionAccelerometer/p1c3.jpg") },
            { src: require("@/assets/images/ESci/StrongMotionAccelerometer/p1c4.jpg") },
          ],
          galleryImage: null,
          galleryText:"· 24位,四通道（Minimus）/八通道（Minimus+）\n· 3或6通道24位ADC(主)\n· 1个辅助24位ADC(用于辅助输入和校准信号)\n· 8条环境通道(低采样率)\n· 集成Güralp增强采集模块(EAM)\n· STA / LTA触发\n· TCP / IP以太网端口\n· Linux操作系统与web服务器\n· 在GCF和MiniSEED中记录数据\n· 数据流协议:GCF(Scream!)、SEEDlink、CD1.1和GDI-link"
        },
        {
          id: "tc_10008_0002",
          name: "Affinity数据采集器",
          description: "精密、适应性强、高保真地震数字化仪。\nGüralp Affinity是一款高保真的集成数字化和网络通信单元，提供了一种方便和可扩展的方式，连接模拟和数字仪器到网络。\nAffinity提供31位分辨率的高质量数字化。Affinity是一款稳固的linux驱动设备，具有板载存储和网络设施，专为数据质量和持久性设计。可通过模块直接控制外设的电源供应，并通过健康通信状态报告其电压。\n对于某些需要数据验证的应用，Affinity还支持数字签名算法(DSA)方案的数据验证。\n生产厂家：Güralp Systems Ltd.\n应用：配套3T系列地震仪、3ESPC、40T、6T、Fortis等模拟地震计使用",
          carouselImage: require("@/assets/images/ESci/StrongMotionAccelerometer/p2c1.jpg"),
          carouselItems:null,
          galleryImage: null,
          galleryText:"· 31位模数转换\n· 提供4或8个ADC频道\n· 超低噪声:>138 dB动态范围@ 100 sps\n· 8或16个环境多路ADC通道\n· 触发/事件子系统功能包括STA/LTA，级别(阈值)，软件触发，每通道投票和点对点网络投票\n· 4个(或8个)并发采样率高达每秒4000个样本\n· utc时间戳数据使用低功率GPS接收机和计时协议，精确时间协议(PTP)和网络时间协议(NTP)\n· 支持全网络的多用户Linux操作系统\n· 机载Web服务器(HTTP和HTTPS)允许数字化参数和宽带传感器的完整远程配置，包括远程锁定，解锁和中心\n· 固定64gb板载存储，额外连接外部USB存储\n· GCF和miniSEED中的记录\n· 支持DSA方案数据认证\n· 支持多种数据格式，包括GCF、GDI、miniSEED、CD1.1、SEEDlink等\n· 内置校准信号发生器:步进，正弦或宽带\n· 完全集成广泛的多方法传感器"
        },
        
      ]
    };
  }
};
</script>
<style lang="less" scoped>
@import "../../../assets/styles/GeoSciProduct.less";
</style>
<style scoped>
</style>
