<template>
<!-- 重力测量仪 -->
  <div>
    <div v-for="(product, index) in products" :key="index">
      <div v-if="product.id === productId" class="product-details">
        <div class="product-top">
          <h2>{{ product.name }}</h2>
          <p class="product-description">{{ product.description }}</p>
        </div>
        <div class="carousel-container">
          <img :src="product.carouselImage" style="width: 400px;"/>
        </div>
      </div>
      <div v-if="product.id === productId" class="parameter-vbom">
        <div class="parameter-title">
          <h3>产品参数</h3>
          <div class="separator"></div>
        </div>
        <div v-if="product.id === productId" class="image-gallery">
          <img :src="product.galleryImage" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
props: ["productId"],
  data() {
    return {
      products: [
        {
          id: "tc_10011_0001",
          name: "量子绝对重力仪",
          description: "量子绝对重力仪AQG是一款基于激光冷却原子的商用重力仪。这种解决方案是我们的学术合作伙伴 （LP2N和 LNE-SYRTE）进行超过15年研究的结果。 仪器基于SYRTE开发的冷原子重力仪，该冷原子重力仪自2009年以来已成功参与了多项绝对重力仪的国际重要比对测试。 AQG测量真空中自由落体测试质量的加速度：精确监测激光冷却原子云的弹道自由落体，从而计算出重力加速度。 该技术是BIPM（Bureau International des Poids et Mesures）公布的弹道自由落体方法之一，是测量重力公认的主要方法。",
          carouselImage: require("@/assets/images/ESci/Gravimeter/G1.png"),
          galleryImage: require("@/assets/images/ESci/Gravimeter/GS1.png")
        },
        {
          id: "tc_10011_0002",
          name: "LG-1 重力仪",
          description: "LG-1重力仪GF公司研发的能够满足目前野外重力测量需求的产品。它的抗震性由基于温度平衡石英芯的轻 型传感器支持。信号处理技术可实现稳定的测量功能。可拆卸的控制单元允许调整水平度并在测量过程中观察数据质量。",
          carouselImage: require("@/assets/images/ESci/Gravimeter/G2.jpg"),
          galleryImage: require("@/assets/images/ESci/Gravimeter/GS2.png")
        },
        // 这里添加更多产品
      ]
    };
  }
};
</script>
<style lang="less" scoped>
@import "../../../assets/styles/GeoSciProduct.less";
</style>
<style scoped>
</style>
