<template>
  <!-- 光纤传感器 -->
  <div>
    <div v-for="(product, index) in products" :key="index">
      <div v-if="product.id === productId" class="product-details">
        <div class="product-top">
          <h2>{{ product.name }}</h2>
          <p class="product-description">{{ product.description }}</p>
        </div>
        <div class="carousel-container">
          <img :src="product.carouselImage" style="width: 400px;"/>
        </div>
      </div>
      <div v-if="product.id === productId" class="parameter-vbom">
        <div class="parameter-title">
          <h3>产品参数</h3>
          <div class="separator"></div>
        </div>
        <div v-if="product.id === productId" class="image-gallery">
          <img :src="product.galleryImage" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import productsList from "@/libs/productsList.js"; // 假设这是您的产品信息列表

export default {
props: ["productId"],
  data() {
    return {
      products: [
        {
          id: "tc_10001_0001",
          name: "热脉冲系统",
          description: "主动分布式温度测量系统提供了沿光缆所有位置的热传导信息。 这种测量能力对于在自然热梯度有限的环境下监测流体的存在和流动，或在原位表征热特性，都是至关重要的。\n Silixa的热脉冲系统(HPS)通过沿着复合光纤电缆以每米恒定功率输出随时间施加热脉冲，实现主动分布式温度传感(A-DTS)。它通过电力供应和内置加热元件的光纤电缆运行。HPS可以与任何Silixa分布式温度传感器相结合，通过共享接口控制这两个装置，提供一个完整的监测解决方案。可设定加热循环的总测量时间、频率和强度，以满足安装的测量要求。",
          carouselImage: require("@/assets/images/ESci/AcousticSensor/HeatPulseSystem.png"),
          galleryImage: require("@/assets/images/ESci/AcousticSensor/p1.png")
        },
        {
          id: "tc_10001_0002",
          name: "XT-DTS™",
          description: "Silixa的坚固分布式温度传感器XTDTS™是目前市场上用于远程和恶劣环境中性能非常高的DTS。\n XT-DTS具有卓越的精度和可靠性，具有一流的工作温度范围和低功耗，可以使用太阳能或风能运行。它可以通过无线或卫星链路进行异地配置和控制，实现远程数据收集，实现有效的资产优化和环境风险管理，即使在以前无法到达的位置也是如此。该系统可以配置为单端测量和双端测量。",
          carouselImage: require("@/assets/images/ESci/AcousticSensor/XT-DTS.png"),
          galleryImage: require("@/assets/images/ESci/AcousticSensor/p2.png")
        },
        {
          id: "tc_10001_0003",
          name: "ULTIMA™ DTS",
          description: "ULTIMA DTS系列是世界上性能最高的分布式温度传感器系列，现在无风扇、低功耗及超大的内部存储空间。 ULTIMA提供了极佳的温度分辨率及采样分辨率，即0.01°C 、25cm,以及很宽的工作温度范围。 \n ULTIMA为独立系统，包含板载PC及内置用户友好的操作界面，它提供4或8通道选择。此设备对以下范围内的空间和温度分辨率进行了优化，型号M：2/5/10Km可选，型号L：10/20/35Km可选。另外此系统可配置为单端测量或双端测量，最小测量时间为1秒。",
          carouselImage: require("@/assets/images/ESci/AcousticSensor/ULTIMADTS.png"),
          galleryImage: require("@/assets/images/ESci/AcousticSensor/p3.png")
        },
        {
          id: "tc_10001_0004",
          name: "Carina® 传感系统",
          description: "为您带来两全其美的效果：工程光纤传感系统将分布式传感器的广泛、高密度覆盖与超越点传感器的灵敏度相结合。\n Carina传感系统是一种多功能光纤传感系统，包括先进的光电询问器和传感电缆，它们配备了新型工程 Constellation™ 光纤系列；与标准光纤相比，本底噪声降低了两个数量级（提高了100倍或20dB ）。",
          carouselImage: require("@/assets/images/ESci/AcousticSensor/pc4.png"),
          galleryImage: require("@/assets/images/ESci/AcousticSensor/p4.png")
        },
        {
          id: "tc_10001_0005",
          name: "iDAS™ 智能分布式声学传感器",
          description: "世界上最先进的分布式声学传感器-iDAS，具有新颖的光电结构，可在标准光纤上的每个位置对声场进行数字记录，其幅度、频率和相位的保真度可用于许多高级应用。",
          carouselImage: require("@/assets/images/ESci/AcousticSensor/iDAS.png"),
          galleryImage: require("@/assets/images/ESci/AcousticSensor/p5.png")
        },
        {
          id: "tc_10001_0006",
          name: "iDAS-MG™ 多标距智能分布式声学传感器",
          description: "iDAS-MG具有更大的灵活性，用户可选择3、5、10或30米的标距长度，可在各种应用和传感范围内提供最佳性能。 \n 这允许用户更好地调整iDAS的空间分辨率、动态范围和噪声下限，以最大限度地提高特定信号的测量质量，而不牺牲整体系统噪声性能。",
          carouselImage: require("@/assets/images/ESci/AcousticSensor/iDAS-MG.png"),
          galleryImage: require("@/assets/images/ESci/AcousticSensor/p6.png")
        },
        {
          id: "tc_10001_0007",
          name: "ULTIMATM™-S高分辨率温度传感器",
          description: "ULTIMA-S的连续监测范围为每个通道5000米，采样分辨率为12.5厘米，最小测量时间为1秒。ULTIMA DTS的绝对温度精度是通过每次测量值与内部高精度参考传感器的自动自校准来实现的。Ultima-S具有最佳的空间分辨率，可对光纤长度进行精确测量。",
          carouselImage: require("@/assets/images/ESci/AcousticSensor/ULTIMATM-S.png"),
          galleryImage: require("@/assets/images/ESci/AcousticSensor/p7.png")
        },
        {
          id: "tc_10001_0008",
          name: "iDSS™智能分布式应变传感器",
          description: "通过提供最高分辨率的分布式应变测量，结构监测能力发生了重大变化。\n 智能分布式应变传感器(iDSS™)是Silixa最新的尖端创新产品，可沿单模或多模光纤电缆测量绝对静态应变，具有最佳应变和空间分辨率，并具有最快的采集速率。",
          carouselImage: require("@/assets/images/ESci/AcousticSensor/iDSS.png"),
          galleryImage: require("@/assets/images/ESci/AcousticSensor/p8.png")
        },
        // 这里添加更多产品
      ]
    };
  }
};
</script>
<style lang="less" scoped>
@import "../../../assets/styles/GeoSciProduct.less";
</style>
<style >

</style>
