<template>
  <div class="platform-wrap">
    <!-- banner 开始 -->
    <div class="page-banner" :style="{background: '#1D212E url('+require('@/assets/images/platform/banner.jpg')+') no-repeat', backgroundSize: 'cover'}">
      <div class="banner-mask"></div>
      <div class="align-center">
        <p class="page-banner-title">羿读云平台</p>
        <p class="page-banner-description">提供稳定可靠安全的软件服务</p>
      </div>
    </div>
    <!-- banner 结束 -->
    <!-- 企业应用管理系统 开始 -->
    <div class="part part-gray-bg letter-space">
      <div class="valid-area">
        <!-- 标题 -->
        <p class="part-title">企业应用管理系统</p>
        <!-- 装饰线 -->
        <p class="decoration-line"></p>
        <!-- 卡片 -->
        <div class="app-card layout-flex">
          <div class="card-item" v-for="(item,ind) in cardList" :key="ind">
            <img :src="require('@/assets/images/platform/app-'+item.img)">
            <div class="app-text">
              <div class="app-title">{{item.title}}</div>
              <div class="app-description">{{item.desc}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 企业应用管理系统 结束 -->
    <!-- 标准API 开始 -->
    <div class="part part-light-bg letter-space">
      <div class="valid-area">
        <!-- 标题 -->
        <p class="part-title">标准API</p>
        <!-- 装饰线 -->
        <p class="decoration-line"></p>
        <!-- 图示 -->
        <div class="main-content">
          <div class="graphic" v-if="widOver768">
            <div class="graphic-item item-double">
              <div class="graphic-pic">
                <img class="align-center" src="@/assets/images/platform/api-eva-server.png">
              </div>
              <div class="light-bg-text">三合服务器</div>
            </div>
            <div class="graphic-item">
              <div class="graphic-pic">
                <lottie :options="photoOptions" :height="200" :width="240" v-on:animCreated="photoAnimation" />
              </div>
              <div class="light-bg-text"></div>
            </div>
            <div class="graphic-item">
              <div class="graphic-pic">
                <img class="align-center" src="@/assets/images/platform/api-thi-server.png">
              </div>
              <div class="light-bg-text">第三方服务器</div>
            </div>
            <div class="graphic-item">
              <div class="graphic-pic">
                <lottie :options="readingOptions" :height="200" :width="200" v-on:animCreated="readingAnimation" />
              </div>
              <div class="light-bg-text"></div>
            </div>
            <div class="graphic-item">
              <div class="graphic-pic">
                <img class="align-center" src="@/assets/images/platform/api-thi-app.png">
              </div>
              <div class="light-bg-text">第三方应用</div>
            </div>
          </div>
          <div class="api-img" v-if="!widOver768">
            <img src="@/assets/images/platform/api.png">
          </div>
          <div class="api-way layout-flex">
            <div class="way-item">
              <div class="item-pic-box">
                <img class="align-center" src="@/assets/images/platform/api-fetch.png">
              </div>
              <div>
                <div class="way-title">主动推送方式</div>
                <div class="way-description">羿读云平台向客户服务器主动推送数据</div>
              </div>
            </div>
            <div class="way-item">
              <div class="item-pic-box">
                <img class="align-center" src="@/assets/images/platform/api-push.png">
              </div>
              <div>
                <div class="way-title">调用获取方式</div>
                <div class="way-description">客户服务器定期向羿读云平台批量获取数据</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 标准API 结束 -->
    <!-- 随羿查 开始 -->
    <div class="eva-search letter-space">
      <div class="valid-area layout-flex" :style="{'flex-wrap':!widOver768 ? 'wrap' : 'nowrap',
      'background': widOver768 ? 'url('+require('@/assets/images/platform/eva-search-bg.png')+') no-repeat right top / cover' : 'url('+require('@/assets/images/platform/eva-search-bg.png')+') no-repeat center top / contain'}">
        <div class="eva-search-pic" v-if="widOver768">
          <img src="@/assets/images/platform/eva-search.png">
        </div>
        <div class="eva-search-content">
          <p class="part-title">[ 随羿查 ] 用户仪表查询助手</p>
          <p class="decoration-line"></p>
          <p class="eva-search-description"><span></span>为用户打通仪表数据获取通道，随时查看用量情况</p>
          <p class="eva-search-description"><span></span>自定义用量超限告警，及时发现异常</p>
          <img src="@/assets/images/header/micro_search.jpg">
          <p class="scan">微信扫一扫</p>
        </div>
        <div class="eva-search-pic-768" v-if="!widOver768">
          <img src="@/assets/images/platform/eva-search.png">
        </div>
      </div>
    </div>
    <!-- 随羿查 结束 -->
  </div>
</template>
<script>
  import lottie from 'vue-lottie'
  import * as photoSvg from '@/assets/images/photo-reading.json'
  import * as readingSvg from '@/assets/images/reading.json'
  import $ from 'jquery'

  export default {
    name: 'platform',
    components: {
      lottie
    },
    data(){
      return {
        widOver768: false,
        // 企业应用管理系统
        cardList:[
          {
            index: 0,
            img: 'photo.jpg',
            title: '照片备份 有据可查',
            desc: '仪表照片云端可见，方便人工核查审计和追溯，避免用量纠纷'
          },
          {
            index: 1,
            img: 'warn.jpg',
            title: '异常告警 实时推送',
            desc: '异常告警信息电脑/手机/邮箱推送，提升用能安全，降低经济损失'
          },
          {
            index: 2,
            img: 'permission.jpg',
            title: '权限控制 数据安全',
            desc: '不同用户角色拥有不同功能及数据访问权限，清晰界定工作范围，保障数据安全'
          },
          {
            index: 3,
            img: 'history.jpg',
            title: '历史数据 随时可查',
            desc: '表读数趋势一目了然，用量报表一键导出'
          },
          {
            index: 4,
            img: 'ralation.jpg',
            title: '仪表关系 一目了然',
            desc: '构建仪表总分关系图，分析产销差和用量分布，及时发现偷漏损问题'
          },
          {
            index: 5,
            img: 'check.jpg',
            title: '识别结果 智能稽查',
            desc: '一键稽查无效图片、低清模糊图片、可疑识别结果，提升人工稽查效率，确保计费准确'
          },
        ],
        photoOptions: {
          animationData: photoSvg.default
        },
        anim: {},
        readingOptions: {
          animationData: readingSvg.default
        },
        anim1: {},
      }
    },
    methods: {
      handleScroll(dom,itemName,direction){
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        let offsetTop = document.querySelector(dom).parentElement.parentElement.offsetTop - window.screen.availHeight + 400
        if(scrollTop >= offsetTop){
          switch (direction) {
            case 'up':
              this.slideToUp(itemName)
              if(dom=='.graphic'|| dom=='.api-img'){
                setTimeout(() => {
                  this.slideToUp($('.way-item'))
                }, 1000);
              }
              break;
            case 'left':
              this.slideToLeft(itemName)
              break;
            default:
              break;
          }
        }
      },
      slideToUp(itemName){
        $.each(itemName,function(ind,item){
          $(item).delay(100*(ind+1)).animate({"top":0,"opacity":1},300);
        })
      },
      slideToLeft(dom){
        $(dom).animate({"left":0,"opacity":1},600);
      },
      photoAnimation: function(anim){
        this.anim = anim
      },
      readingAnimation: function(anim){
        this.anim1 = anim
      },
      getWid(){
        let wid = document.documentElement.clientWidth || document.body.clientWidth
        this.widOver768 = wid <= 768 ? false : true;
      },
    },
    mounted(){
      this.slideToUp($('.card-item'));
      this.getWid();
      window.addEventListener('scroll',()=>{
        if(this.$route.name=='platform'){
          this.widOver768 ? this.handleScroll('.graphic',$('.graphic-item'),'up') : this.handleScroll('.api-img',$('.api-img img'),'up');
          this.handleScroll('.eva-search-content',$('.eva-search-content'),'left');
        }
      });
      window.addEventListener('resize',this.getWid)
    },
  }
</script>
<style lang="less" scoped>
  .page-banner{
    text-align: center;
    @media screen and (max-width:768px){
      .align-center{
        width: 100%;
        left: 50%;
        transform: translate(-50%,-50%);
      }
    }
  }
  .app-card{
    margin-top: 28px;
    justify-content: space-between;
    flex-wrap: wrap;
    .card-item{
      // width: 350px;
      // height: 380px;
      width: 450px;
      height: 480px;
      margin-top: 50px;
      position: relative;
      top: 100px;
      opacity: 0;
      background: #fff;
      box-shadow: 0 1px 12px rgba(0,0,0,.1);
      // cursor: pointer;
      &:hover{
        box-shadow: 0 2px 36px rgba(0,0,0,.1); 
      }
      img{
        display: block;
        width: 100%;
        // height: 220px;
      }
      .app-text{
        margin-top: 30px;
        padding: 24px;
        .app-title{
          margin-bottom: 12px;
          font-size: 20px;
          color: #1D212E;
        }
        .app-description{
          font-size: 16px;
          color: #485064;
          text-align: left;
        }
      }
    }
    @media screen and (max-width: 768px){
      margin-top: 0.28rem;
      .card-item{
        width: 48%;
        height: auto;
        margin-top: 0.5rem;
        top: 1rem;
        img{
          width: 100%;
          height: auto
        }
        .app-text{
          padding: 0.24rem;
          .app-title{
            margin-bottom: 0.12rem;
            font-size: 14px;
            font-weight: 600;
          }
          .app-description{
            font-size: 13px;
            letter-spacing: 3px;
          }
        }
      }
    }
  }
  // api
  .graphic{
    display: flex;
    justify-content: space-around;
    font-size: 22px;
    text-align: center;
    .graphic-item{
      width: 18%;
      position: relative;
      top: 100px;
      opacity: 0;
    }
    .item-double{
      width: 28%;
    }
    .graphic-pic{
      height: 200px;
      margin-bottom: 40px;
      position: relative;
      img{
        height: 100%;
        display: block;
      }
    }
  }
  .api-img{
    img{
      width: 95%;
      position: relative;
      top: 100px;
      opacity: 0;
    }
  }
  .api-way{
    margin-top: 90px;
    justify-content: space-between;
    .way-item{
      width: 550px;
      height: 160px;
      display: flex;
      padding: 46px 0 0 26px;
      position: relative;
      top: 100px;
      opacity: 0;
      background: #f8f9fe;
      color: #1D212E;
      text-align: left;
      .item-pic-box{
        width: 60px;
        height: 60px;
        margin-right: 26px;
      }
      .way-title{
        line-height: 24px;
        margin-bottom: 20px;
        font-size: 24px;
      }
      .way-description{
        font-size: 18px;
      }
    }
    @media screen and (max-width: 768px){
      margin-top: 0.9rem;
      flex-wrap: wrap;
      .way-item{
        width: 100%;
        height: 1.6rem;
        padding: 0.46rem 0 0 0.26rem;
        margin: 0.2rem auto;
        top: 1rem;
        .item-pic-box{
          width: 0.6rem;
          height: 0.6rem;
          margin-right: 0.26rem;
        }
        .way-title{
          line-height: 0.24rem;
          margin-bottom: 0.2rem;
          font-size: 14px;
        }
        .way-description{
          font-size: 12px;
        }
      }
    }
  }
  // 随羿查
  .eva-search{
    padding-top: 90px;
    background-image: linear-gradient(to left,#3E86F7,#24D5FF);
    .eva-search-pic{
      width: 540px;
      height: 420px;
      margin-right: 50px;
      overflow: hidden;
      img{
        width: 100%;
      }
    }
    .eva-search-pic-768{
      width: 85%;
      margin: 0.4rem auto -0.08rem;
      img{
        width: 100%;
      }
    }
    .eva-search-content{
      position: relative;
      left: 400px;
      opacity: 0;
      text-align: left;
      color: #fff;
      .part-title{
        color: #fff;
      }
      .decoration-line{
        margin: 30px 0 40px;
        background-image: linear-gradient(to right,#fff,#fff);
      }
      .eva-search-description{
        line-height: 20px;
        margin-bottom: 20px;
        font-size: 20px;
        span{
          display: inline-block;
          width: 8px;
          height: 8px;
          margin-right: 10px;
          position: relative;
          top: -3px; 
          border-radius: 50%;
          background: #fff;
        }
      }
      img{
        display: block;
        width: 100px;
        margin: 40px 0 10px;
      }
    }
    @media screen and (max-width: 768px){
      padding-top: 0.9rem;
      margin: 0 auto;
      .eva-search-content{
        width: 85%;
        left: 0;
        margin: 0 auto;
        .decoration-line{
          margin: 0.3rem auto 0.4rem;
        }
        .eva-search-description{
          line-height: 20px;
          margin-bottom: 0.2rem;
          font-size: 14px;
          span{
            width: 0.08rem;
            height: 0.08rem;
            margin-right: 0.1rem;
            top: -0.03rem;
          }
        }
        img{
          width: 1.2rem;
          margin: 0.4rem auto 0;
        }
        .scan{
          margin: 0.1rem 0;
          text-align: center;
        }
      }
    }
  }
</style>

