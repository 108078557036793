<template>
  <div id="app">
    <t-header :is-fixed="isFixed"></t-header>
    <div class="main-content-wrap">
      <router-view></router-view>
    </div>
    <t-footer v-if="$route.name !== 'home'"></t-footer>
    <aside-stay></aside-stay>
  </div>
</template>

<script>
import {getSize} from '@/libs/getSize.js'
import THeader from '@/components/layout/THeader'
import TFooter from '@/components/layout/TFooter'
import AsideStay from '@/components/layout/AsideStay'

export default {
  name: 'App',
  components: {
    THeader,
    TFooter,
    AsideStay,
  },
  mounted(){
    // console.log(this.$router.currentRoute);  
  },
  data() {
    return {
      isFixed: false
    }
  },
    computed:{
      getFixedHeader(){
        this.isFixed = this.$route.name == 'recruit' ? true : false;
        return this.isFixed
      }
    },
    watch:{
      getFixedHeader(val){}
    },
    mounted(){
      // getSize();
      // window.addEventListener('resize',()=>{
      //   getSize()
      // })
    }
}
</script>

<style>
#app {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: #383E4A;
  width: 100%;
  height: 100%;
}

@media screen and (max-width:768px) {
  #app {
    font-size: 0.14rem;
  }
}

html,
body {
  width: 100%;
  height: 100%;
}

.main-content-wrap {
  min-width: 1140px;
}

@media screen and (max-width: 768px) {
  .main-content-wrap {
    min-width: auto;
  }
}</style>
