var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"property-wrap"},[_c('div',{staticClass:"page-banner",style:({background: '#1D212E url('+require('@/assets/images/substations/banner.png')+') no-repeat', backgroundSize: 'cover'})},[_c('div',{staticClass:"banner-mask"}),_vm._m(0)]),_c('div',{staticClass:"part part-light-bg letter-space"},[_c('div',{staticClass:"valid-area"},[_c('p',{staticClass:"part-title"},[_vm._v("智慧变电站")]),_c('p',{staticClass:"decoration-line"}),(_vm.widOver768)?_c('div',{staticClass:"main-content layout-flex reslove-case-main"},[_vm._m(1),_vm._m(2),_vm._m(3)]):_vm._e(),(!_vm.widOver768)?_c('div',{staticClass:"main-content reslove-case-main-768"},[_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8)]):_vm._e()])]),_vm._m(9),_c('div',{staticClass:"part letter-space",style:({background:'#2B2E46 url('+require('@/assets/images/home/advantage-bg.png')+') no-repeat 90px 90px / 90% 90%'})},[_vm._m(10)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"valid-area"},[_c('div',{staticClass:"align-center"},[_c('p',{staticClass:"page-banner-title"},[_vm._v("智慧变电站解决方案")]),_c('p',{staticClass:"page-banner-description"},[_vm._v("减少人工巡检频次，解决手动抄录、带电作业、高位拍摄、人眼巡视等痛点")]),_c('p',{staticClass:"page-banner-description"},[_vm._v("保障巡检“到位率”，仪器仪表用能数据及时安全的接入管理信息系统")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resolve-case align-right"},[_c('div',{staticClass:"resolve-case-item"},[_c('p',{staticClass:"resolve-case-title"},[_vm._v("安全")]),_c('p',{staticClass:"resolve-case-description"},[_vm._v("避免人工带电作业，及时统抄所有点位")]),_c('p',{staticClass:"resolve-case-description"},[_vm._v("发现故障仪表，排查安全隐患")])]),_c('div',{staticClass:"resolve-case-item"},[_c('p',{staticClass:"resolve-case-title"},[_vm._v("省力")]),_c('p',{staticClass:"resolve-case-description"},[_vm._v("解决人工现场抄表强度大、误差大、不实时、效率低等问题")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resolve-case-pic"},[_c('img',{attrs:{"src":require("@/assets/images/property/property.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resolve-case align-left"},[_c('div',{staticClass:"resolve-case-item"},[_c('p',{staticClass:"resolve-case-title"},[_vm._v("降本")]),_c('p',{staticClass:"resolve-case-description"},[_vm._v("快速实现现场六氟化硫表，避雷计数器等仪器仪表的远程数据采集")]),_c('p',{staticClass:"resolve-case-description"},[_vm._v("实现用能精细化管理，降低改造成本")])]),_c('div',{staticClass:"resolve-case-item"},[_c('p',{staticClass:"resolve-case-title"},[_vm._v("省心")]),_c('p',{staticClass:"resolve-case-description"},[_vm._v("各类仪器仪表用量一目了然，仪表照片留底，减少人工巡检频次，做到有图可依")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"reslove-img"},[_c('img',{attrs:{"src":require("@/assets/images/property/property-res.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resolve-case-768-item"},[_c('p',{staticClass:"resolve-case-768-title"},[_vm._v("安全")]),_c('p',{staticClass:"resolve-case-768-description"},[_vm._v("避免人工带点作业，及时统抄所有点位")]),_c('p',{staticClass:"resolve-case-768-description"},[_vm._v("掌握设备用能趋势，合理调度控制")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resolve-case-768-item"},[_c('p',{staticClass:"resolve-case-768-title"},[_vm._v("降本")]),_c('p',{staticClass:"resolve-case-768-description"},[_vm._v("快速实现机械表远程数据采集")]),_c('p',{staticClass:"resolve-case-768-description"},[_vm._v("实现用能精细化管理，降低经济损失")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resolve-case-768-item"},[_c('p',{staticClass:"resolve-case-768-title"},[_vm._v("省力")]),_c('p',{staticClass:"resolve-case-768-description"},[_vm._v("解决人工现场抄表强度大、")]),_c('p',{staticClass:"resolve-case-768-description"},[_vm._v("误差大、不实时、效率低等问题")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resolve-case-768-item"},[_c('p',{staticClass:"resolve-case-768-title"},[_vm._v("省心")]),_c('p',{staticClass:"resolve-case-768-description"},[_vm._v("改变租赁双方抄表模式，建立客户信任关系")]),_c('p',{staticClass:"resolve-case-768-description"},[_vm._v("水电气用量一目了然，仪表照片留底，减少账单纠纷")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"part part-gray-bg letter-space"},[_c('div',{staticClass:"valid-area"},[_c('p',{staticClass:"part-title"},[_vm._v("应用案例")]),_c('p',{staticClass:"decoration-line"}),_c('div',{staticClass:"main-content"},[_c('div',{staticClass:"case"},[_c('div',{staticClass:"case-info"},[_c('p',[_c('span',[_vm._v("客户  —  ")]),_vm._v("某省220KV升压变电站 ")]),_c('p',[_c('span',[_vm._v("时间  —  ")]),_vm._v("2022年05月 ")]),_c('p',[_c('span',[_vm._v("表具  —  ")]),_vm._v("油位计、油温计、避雷计数器以及SF6压力表 ")]),_c('p',[_c('span',[_vm._v("效果  —  ")]),_vm._v("仪表改造周期由三个月缩短至一周内 "),_c('br'),_c('span',[_vm._v("       —  ")]),_vm._v("大幅减少路途时间和表计现场巡检的工作时间 "),_c('br'),_c('span',[_vm._v("       —  ")]),_vm._v("实时通知上下限阈值超限，异常发现率提升90%以上 "),_c('br'),_c('span',[_vm._v("       —  ")]),_vm._v("单点表计综合改造成本就现有智能手段改造成本节约83% "),_c('br'),_c('span',[_vm._v("       —  ")]),_vm._v("每小时回传一次图像，实现远程稽查，巡视频次提升50%以上 ")])]),_c('div',{staticClass:"case-pic"},[_c('img',{attrs:{"src":require("@/assets/images/substations/case-img.png")}})])]),_c('div',{staticClass:"case-app"},[_c('div',{staticClass:"case-app-box"},[_c('div',{staticClass:"case-data"},[_c('p',{staticStyle:{"width":"34%"}},[_vm._v("（仪表抄录详细数据）")]),_c('p',{staticStyle:{"width":"40%"}},[_vm._v("（仪表读数变化趋势）")]),_c('p',{staticStyle:{"width":"33%"}},[_vm._v("（仪表用量变化趋势）")])]),_c('img',{staticClass:"case-data-pic",attrs:{"src":require("@/assets/images/fuelGas/case.png")}})])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"valid-area"},[_c('p',{staticClass:"part-title dark-bg-text-white"},[_vm._v("安装照片")]),_c('p',{staticClass:"decoration-line"}),_c('div',{staticClass:"main-content"},[_c('img',{staticStyle:{"width":"100%","display":"block","margin":"0 auto"},attrs:{"src":require("@/assets/images/substations/aztp.png")}})])])
}]

export { render, staticRenderFns }